// Local storage and session storage variables
export const STORED_CASEWORK_FILTERS = 'caseworkFilters'
export const STORED_ARCHIVED_CASEWORK_FILTERS = 'archivedCaseworkFilters'
export const STORED_CASEWORK_TAB = 'caseworkTab'

export const STORED_CONSTITUENT_FILTERS = 'constituentFilters'
export const STORED_GROUPS_FILTERS = 'groupsFilters'
export const STORED_STAFF_FILTERS = 'staffFilters'
export const STORED_DIRECTORY_TAB = 'directoryTab'
export const STORED_TOOLS_TAB = 'toolsTab'
