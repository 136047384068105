import React from 'react'

import {
  Heading,
  Spacer,
  VStack,
  useToast,
  FormControl,
  FormErrorMessage,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import PasswordCriteria from './PasswordCriteria'
import { passwordValidationSchema } from '../../constants'
import { changePassword } from '../../redux/features/authSlice'
import { calculateMS } from '../../utils'
import { PasswordInput } from '../ui'

const ChangePasswordModal = ({ isOpen, onClose }) => {
  const { id } = useParams()
  const toast = useToast()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const initialValues = {
    id: id,
    newPassword: '',
    confirmPassword: '',
    oldPassword: '',
  }

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={passwordValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const callbackSuccess = () => {
          navigate('/')
          toast({
            title: 'Password successfully changed',
            status: 'success',
            description:
              'Your password was successfully changed. Please Login again.',
            duration: calculateMS(2.5),
            isClosable: true,
            position: 'top',
          })
          setSubmitting(false)
        }

        const callbackFailure = error => {
          let message
          if (error?.response?.data?.old_password) {
            message = error?.response?.data?.old_password[0]
          } else if (error?.response?.data?.non_field_errors) {
            message = error?.response?.data?.non_field_errors[0]
          } else message = error
          toast({
            title: 'Error changing password',
            status: 'error',
            description: `${message}`,
            duration: calculateMS(2.5),
            isClosable: true,
            position: 'top',
          })
          setSubmitting(false)
        }

        setSubmitting(true)
        dispatch(
          changePassword({
            values,
            callbackSuccess,
            callbackFailure,
          })
        )
      }}
    >
      {({ handleSubmit, resetForm, values, errors, touched, isSubmitting }) => (
        <Modal
          isOpen={isOpen}
          onClose={() => {
            onClose()
            resetForm()
          }}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody p={4}>
              <Heading fontSize='2xl' mb={4}>
                Change Password
              </Heading>
              <>
                <PasswordCriteria
                  password={values.newPassword}
                  confirmPassword={values.confirmPassword}
                />
                <Spacer height='16px' />

                <Form onSubmit={handleSubmit} autoComplete='off'>
                  <VStack align='left' spacing={4}>
                    <FormControl
                      id='oldPassword'
                      isInvalid={errors.oldPassword && touched.oldPassword}
                    >
                      <PasswordInput
                        id='oldPassword'
                        name='oldPassword'
                        placeholder='Enter current password'
                      />
                      <FormErrorMessage>{errors.oldPassword}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id='newPassword'
                      isInvalid={errors.newPassword && touched.newPassword}
                    >
                      <PasswordInput
                        id='newPassword'
                        name='newPassword'
                        placeholder='Enter new password'
                      />
                      <FormErrorMessage>{errors.newPassword}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id='confirmPassword'
                      isInvalid={
                        errors.confirmPassword && touched.confirmPassword
                      }
                    >
                      <PasswordInput
                        id='confirmPassword'
                        name='confirmPassword'
                        placeholder='Confirm password'
                      />
                      <FormErrorMessage>
                        {errors.confirmPassword}
                      </FormErrorMessage>
                    </FormControl>
                    <Spacer height='16px' />

                    <Button
                      width='100%'
                      borderRadius='full'
                      type='submit'
                      size={{ base: 'md', md: 'lg' }}
                      isLoading={isSubmitting}
                    >
                      Change Password
                    </Button>
                  </VStack>
                </Form>
              </>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Formik>
  )
}

export default ChangePasswordModal
