import React from 'react'

import { Box, Stack, Text, Tooltip } from '@chakra-ui/react'

const Section = ({
  heading = '',
  children,
  align = 'start',
  direction = 'column',
  showTooltip = false,
  tooltipText,
  w = 'lg',
  mr = 3,
  m,
}) => (
  <Box w={w} mr={mr} m={m}>
    <Tooltip
      label={tooltipText || heading}
      hasArrow
      isDisabled={!showTooltip && !tooltipText}
    >
      <Stack direction={direction} align={align}>
        <Text
          style={{
            fontWeight: 'bold',
          }}
        >
          {heading.toUpperCase()}
        </Text>
        {children}
      </Stack>
    </Tooltip>
  </Box>
)

export default Section
