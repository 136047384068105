import { createSlice } from '@reduxjs/toolkit'

import { CONSTITUENTS_TAB, GROUPS_TAB, STAFF_TAB } from '../../constants'
import {
  STORED_CONSTITUENT_FILTERS,
  STORED_DIRECTORY_TAB,
  STORED_GROUPS_FILTERS,
  STORED_STAFF_FILTERS,
} from '../../constants/tempStorage'

const previousTab =
  JSON.parse(sessionStorage.getItem(STORED_DIRECTORY_TAB)) || CONSTITUENTS_TAB

const getStoredFiltersKey = tab => {
  if (tab === CONSTITUENTS_TAB) return STORED_CONSTITUENT_FILTERS
  if (tab === GROUPS_TAB) return STORED_GROUPS_FILTERS
  if (tab === STAFF_TAB) return STORED_STAFF_FILTERS
}

const storedFilters =
  JSON.parse(sessionStorage.getItem(getStoredFiltersKey(previousTab))) || {}
const nonPersistentFilterKeys = ['search']
nonPersistentFilterKeys.forEach(key => delete storedFilters[key])

const initialState = {
  tab_index: previousTab, // constituents, groups, agencies
  errors: null,
  filters: storedFilters,
  filters_changed: false,
  address_status: '',
  validated_address: null,
}

export const directorySlice = createSlice({
  initialState,
  name: 'directory',
  reducers: {
    setErrors(state, action) {
      state.errors = action.payload
    },
    setAddressStatus(state, action) {
      state.address_status = action.payload
    },
    storeValidatedAddress(state, action) {
      state.errors = null
      state.validated_address = action.payload
    },
    clearValidatedAddress(state) {
      state.validated_address = null
    },

    validateAddress() {},

    setDirectoryOrderBy(state, action) {
      const current = state.filters.order_by
      if (current?.charAt(0) === '-' && current?.slice(1) === action.payload) {
        state.filters.order_by = action.payload
      } else {
        state.filters.order_by = '-' + action.payload
      }
      sessionStorage.setItem(
        getStoredFiltersKey(state.tab_index),
        JSON.stringify(state.filters)
      )
      state.filters_changed = true
    },
    setDirectorySearch(state, action) {
      state.filters_changed = true
      state.filters.search = action.payload
    },
    clearDirectorySearch(state) {
      state.filters_changed = true
      delete state.filters.search
    },
    applyDirectoryFilters(state, action) {
      state.filters_changed =
        action.payload?.filters_changed || state.filters_changed
    },
    setDirectoryFilters(state, action) {
      let updatedFilters = action.payload
      state.filters_changed = true
      Object.keys(updatedFilters).forEach(key => {
        if (
          updatedFilters[key] === '' ||
          (Array.isArray(updatedFilters[key]) &&
            updatedFilters[key].length === 0)
        )
          delete updatedFilters[key]
      })
      state.filters = updatedFilters
      sessionStorage.setItem(
        getStoredFiltersKey(state.tab_index),
        JSON.stringify(updatedFilters)
      )
    },
    clearDirectoryFilters(state) {
      const { order_by, search } = state.filters

      const filteredObj = {}

      if (order_by !== undefined) filteredObj.order_by = order_by

      if (search !== undefined) filteredObj.search = search

      state.filters = filteredObj
      sessionStorage.setItem(
        getStoredFiltersKey(state.tab_index),
        JSON.stringify(state.filters)
      )
      state.filters_changed = true
    },
    paginate(state) {
      state.loading = true
    },

    setTabIndex(state, action) {
      state.tab_index = action.payload
      state.filters =
        JSON.parse(
          sessionStorage.getItem(getStoredFiltersKey(state.tab_index))
        ) || {}
      state.filters_changed = false
      sessionStorage.setItem(STORED_DIRECTORY_TAB, action.payload)
    },

    addConstituentsToGroup() {},
    removeConstituentsFromGroup() {},
  },
})

export default directorySlice.reducer

export const {
  setTabIndex,
  setErrors,
  paginate,

  validateAddress,
  setAddressStatus,
  storeValidatedAddress,
  clearValidatedAddress,

  setDirectoryOrderBy,
  setDirectorySearch,
  clearDirectorySearch,

  applyDirectoryFilters,
  setDirectoryFilters,
  clearDirectoryFilters,

  addConstituentsToGroup,
  removeConstituentsFromGroup,
} = directorySlice.actions
