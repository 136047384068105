import React from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'

const PasswordExpiredModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Password Expired</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Your password has expired, and you have been sent a password reset
          link. This email may take a few minutes to arrive. Thank you for your
          patience.
        </ModalBody>

        <ModalFooter>Council Connect</ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default PasswordExpiredModal
