import React from 'react'

import { useSelector } from 'react-redux'

import { districtOptions } from '../../../constants'
import { FilterInput, FilterMenuBase } from '../../ui'

const StaffFilterMenu = ({ isOpen, onClose, filterButtonRef }) => {
  const filters = useSelector(state => state.directory.filters)
  return (
    <FilterMenuBase
      heading='Staff Filters'
      store='directory'
      buttonRef={filterButtonRef}
      isOpen={isOpen}
      onClose={onClose}
      activeFilters={
        Object.keys(filters).filter(
          key => !['order_by', 'search'].includes(key)
        ).length
      }
    >
      <FilterInput
        label='Role'
        filterKey='role__in'
        multiSelect
        store='directory'
        options={[
          'Constituent Liaison',
          'Intern',
          'Director of Constituent Services',
          'Chief of Staff',
          'Council Member',
        ]}
      />
      <FilterInput
        label='Council District'
        filterKey='district__in'
        multiSelect
        store='directory'
        placeholder='Filter by district'
        options={districtOptions}
      />
    </FilterMenuBase>
  )
}

export default StaffFilterMenu
