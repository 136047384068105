import React from 'react'

import { Text, useToast } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { deleteCaseworkInstance } from '../../../../redux/features/caseworkSlice'
import { ConfirmActionDialog } from '../../../ui'

const DeleteDialog = ({ case_num, ...props }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const toast = useToast()

  const handleConfirm = () => {
    dispatch(
      deleteCaseworkInstance({
        case_num,
        callbackSuccess: () => {
          navigate(`/casework`)
          toast({
            title: `Successfully deleted casework`,
            status: 'success',
          })
        },
        callbackFailure: () => {
          toast({
            title: `An error occurred attempting to delete casework`,
            status: 'error',
          })
        },
      })
    )
  }

  return (
    <ConfirmActionDialog {...props} onConfirm={handleConfirm} scheme='delete'>
      <Text fontWeight='bold'>
        This case will be permanently deleted and cannot be recovered.
      </Text>
      <Text> Are you sure you want to proceed?</Text>
    </ConfirmActionDialog>
  )
}

export default DeleteDialog
