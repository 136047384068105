import React from 'react'

import { Stack, Text } from '@chakra-ui/react'

import NoValue from './NoValue'
import { dayAgeString, formatDate } from '../utils'

const FormattedDate = ({ date, hideAge, direction = 'row' }) => {
  if (!date) {
    return <NoValue />
  }

  let ageString = dayAgeString(date)

  return (
    <Stack direction={direction}>
      <Text> {formatDate(date)} </Text>
      {!hideAge && (
        <Text as='i' color='gray.400'>
          {ageString}
        </Text>
      )}
    </Stack>
  )
}

export default FormattedDate
