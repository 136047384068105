import React, { cloneElement, useEffect, useRef, useState } from 'react'

import { Box, useColorModeValue } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'

import {
  AGENCIES_TAB,
  CONSTITUENTS_TAB,
  GROUPS_TAB,
  STAFF_TAB,
} from '../../constants'
import {
  clearDirectorySearch,
  setDirectorySearch,
  setTabIndex,
} from '../../redux/features/directorySlice'
import {
  AgencyTable,
  ConstituentActionMenu,
  ConstituentFilterMenu,
  ConstituentsTable,
  CreateConstituentModal,
  CreateOrEditGroupModal,
  GroupFilterMenu,
  GroupsTable,
  StaffActionMenu,
  StaffFilterMenu,
  StaffTable,
} from '../directory'
import { NotFound, TabsSearchFilter } from '../ui'
import { AgencySelect } from '../ui/select'

const Directory = () => {
  const dispatch = useDispatch()
  const tabIndex = useSelector(state => state.directory.tab_index)
  const filters = useSelector(state => state.directory.filters)
  const [selectedAgency, setSelectedAgency] = useState('')
  const tabNames = ['Constituents', 'Groups', 'Staff', 'Agencies']
  document.title = `Directory | ${tabNames[tabIndex]} | Council Connect`

  const tableRef = useRef(null)
  useEffect(() => {
    if (tabIndex !== AGENCIES_TAB) {
      setSelectedAgency('')
    }
  }, [tabIndex])

  const selectTab = index => {
    dispatch(setTabIndex(index))
  }

  const updateSearch = searchInput => {
    dispatch(setDirectorySearch(searchInput))
  }

  const clearSearch = () => {
    dispatch(clearDirectorySearch())
  }
  const getSearchBar = () => {
    if (tabIndex === AGENCIES_TAB)
      return (
        <Box w='100%'>
          <AgencySelect
            onSelect={option => setSelectedAgency(option)}
            initialSelected={[{ label: selectedAgency, value: selectedAgency }]}
            inputProps={{
              variant: 'filled',
              h: 12,
              borderColor: agencySelectBorder,
              placeholder: 'Search for an agency to view',
              id: 'agency-select-directory',
            }}
            hideTags
          />
        </Box>
      )
    return null
  }

  const getFilterMenu = () => {
    if (tabIndex === CONSTITUENTS_TAB) return <ConstituentFilterMenu />
    else if (tabIndex === GROUPS_TAB) return <GroupFilterMenu />
    else if (tabIndex === STAFF_TAB) return <StaffFilterMenu />
    return null
  }

  const getCreateModal = () => {
    if (tabIndex === CONSTITUENTS_TAB) return <CreateConstituentModal />
    else if (tabIndex === GROUPS_TAB)
      return <CreateOrEditGroupModal mode='create' />
    return null
  }

  const getActionMenu = () => {
    if (tabIndex === CONSTITUENTS_TAB) return <ConstituentActionMenu />
    else if (tabIndex === STAFF_TAB) return <StaffActionMenu />
    return null
  }

  const getTable = () => {
    switch (tabIndex) {
      case 0:
        return <ConstituentsTable />
      case 1:
        return <GroupsTable />
      case 2:
        return <StaffTable />
      case 3:
        return <AgencyTable selectedAgency={selectedAgency} />
      default:
        return <NotFound />
    }
  }

  const getFilterCount = () => {
    let count = 0
    const exclude_keys = ['order_by', 'search']
    const true_only = ['hide_cstat']

    for (let key in filters) {
      if (true_only.includes(key)) {
        if (filters[key] === 'true') {
          count++
        }
      } else if (!exclude_keys.includes(key)) {
        count++
      }
    }

    return count
  }

  const agencySelectBorder = useColorModeValue('gray.100', 'transparent')
  return (
    <>
      <TabsSearchFilter
        tabs={tabNames}
        tabIndex={tabIndex}
        selectTab={selectTab}
        updateSearch={updateSearch}
        clearSearch={clearSearch}
        filterMenu={getFilterMenu()}
        createModal={getCreateModal()}
        actionMenu={getActionMenu()}
        searchBar={getSearchBar()}
        activeFilters={getFilterCount()}
      />
      <Box
        height={{ base: 'calc(100vh - 240px)', lg: 'calc(100vh - 175px)' }}
        px={{ base: 2, lg: 0 }}
        overflowY='auto'
        ref={tableRef}
      >
        {cloneElement(getTable(), {
          tableRef,
        })}
      </Box>
    </>
  )
}

export default Directory
