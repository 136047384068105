import React, { useState } from 'react'

import { ChevronRightIcon } from '@chakra-ui/icons'
import { Button, Center, useToast } from '@chakra-ui/react'
import { Formik } from 'formik'
import { useDispatch } from 'react-redux'

import { initialStaffValues, staffValidationSchema } from '../../constants'
import { createStaff } from '../../redux/features/staffSlice'
import { StaffForm } from '../directory'

const CreateNewStaff = () => {
  const dispatch = useDispatch()
  const toast = useToast()

  const [createdStaff, setCreatedStaff] = useState(null)
  return (
    <>
      <Formik
        initialValues={initialStaffValues}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
          /** Callbacks defined here as formik props are needed */
          const onCreateSuccess = staff => {
            setCreatedStaff(staff)
            setSubmitting(false)
            resetForm()
            toast({
              title: 'Successfully created new staff!',
              status: 'success',
            })
          }

          const onCreateFailure = errors => {
            setSubmitting(false)
            setErrors(errors)
          }

          /** On submit: set submitting to true and dispatch saga */
          setSubmitting(true)

          dispatch(
            createStaff({
              values,
              callbackSuccess: onCreateSuccess,
              callbackFailure: onCreateFailure,
            })
          )
        }}
        validationSchema={staffValidationSchema}
      >
        {({ isSubmitting, handleSubmit }) => (
          <>
            <StaffForm />
            <Button
              onClick={handleSubmit}
              isLoading={isSubmitting}
              w='100%'
              mt={5}
              minH={10}
            >
              Create
            </Button>
          </>
        )}
      </Formik>
      {createdStaff && (
        <Center mt={3}>
          <Button
            variant='link'
            rightIcon={<ChevronRightIcon boxSize={5} />}
            onClick={() =>
              window.open(`/directory/staff/${createdStaff.user.id}`, '_blank')
            }
            minH={10}
          >
            View {createdStaff.user.first_name}&apos;s Staff Profile
          </Button>
        </Center>
      )}
    </>
  )
}

export default CreateNewStaff
