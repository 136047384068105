import React from 'react'

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Box,
  Badge,
} from '@chakra-ui/react'

const ConfirmActionDialog = ({
  isOpen,
  onClose,
  onConfirm,
  leastDestructiveRef,
  scheme,
  children,
}) => {
  let colorScheme = scheme === 'delete' ? 'red' : 'yellow'

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={leastDestructiveRef}
      onClose={onClose}
      motionPreset='slideInRight'
      isCentered
      size='xl'
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader
            fontSize='lg'
            fontWeight='bold'
            as={Badge}
            variant='solid'
            colorScheme={colorScheme}
          >
            Confirm Action
          </AlertDialogHeader>

          <AlertDialogBody>
            <Box pt={2}>{children}</Box>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={leastDestructiveRef} onClick={onClose} variant='ghost'>
              Cancel
            </Button>
            <Button colorScheme={colorScheme} onClick={onConfirm} ml={3}>
              Proceed
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default ConfirmActionDialog
