import React, { useCallback, useRef } from 'react'

import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  PopoverContent,
  PopoverHeader,
  Spacer,
  Stack,
  Text,
  useOutsideClick,
  useToast,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'

import {
  applyCaseworkFilters,
  clearCaseworkFilters,
} from '../../../redux/features/caseworkSlice'
import {
  applyDirectoryFilters,
  clearDirectoryFilters,
} from '../../../redux/features/directorySlice'
import { calculateMS } from '../../../utils'

const FilterMenuBase = ({
  children,
  store,
  heading = 'Filters',
  buttonRef,
  isOpen,
  onClose,
  sortMenu,
}) => {
  const filterPopoverRef = useRef(null)
  const dispatch = useDispatch()
  const toast = useToast()

  const applyFilterSuccess = () => {
    toast({
      title: 'Filters applied',
      status: 'info',
      duration: calculateMS(1.5),
    })
  }

  const clearFilterSuccess = () => {
    toast({
      title: 'Filters cleared',
      status: 'info',
      duration: calculateMS(1.5),
    })
  }

  const applyFilters = () => {
    if (store === 'directory')
      dispatch(
        applyDirectoryFilters({
          callback: applyFilterSuccess,
          filters_changed: true,
        })
      )
    else if (store === 'casework')
      dispatch(
        applyCaseworkFilters({
          callback: applyFilterSuccess,
          filters_changed: true,
        })
      )
    onClose()
  }

  const clearFilters = () => {
    if (store === 'directory')
      dispatch(clearDirectoryFilters({ callback: clearFilterSuccess }))
    else if (store === 'casework')
      dispatch(clearCaseworkFilters({ callback: clearFilterSuccess }))
  }

  const handleOutsideClick = useCallback(
    e => {
      if (!isOpen) return

      if (buttonRef.current.contains(e.target)) return // if the filter button was clicked, do nothing

      applyFilters()
    },
    [isOpen, buttonRef.current, applyFilters]
  )
  useOutsideClick({
    ref: filterPopoverRef,
    handler: handleOutsideClick,
  })

  return (
    <Box ref={filterPopoverRef}>
      <PopoverContent
        w={{ base: '98vw', sm: 500 }}
        mx={{ base: 1, sm: 0 }}
        mb={{ base: 1, sm: 0 }}
        autoFocus={false}
        borderRadius='lg'
      >
        <PopoverHeader borderTopRadius='lg' p={4}>
          <Flex align='center'>
            <Text fontSize='xl' fontWeight='bold' casing='uppercase'>
              {heading}
            </Text>
            {sortMenu && (
              <>
                <Spacer />
                {sortMenu}
              </>
            )}
          </Flex>
        </PopoverHeader>
        <Box p={3} maxH='50vh' overflowY='auto'>
          <Stack>{children}</Stack>
        </Box>
        <Box align='end' p={4} borderBottomRadius='lg'>
          <ButtonGroup>
            <Button variant='outline' onClick={clearFilters}>
              Clear
            </Button>
            <Button colorScheme='green' onClick={applyFilters}>
              Apply Filters
            </Button>
          </ButtonGroup>
        </Box>
      </PopoverContent>
    </Box>
  )
}

export default FilterMenuBase
