import React from 'react'

import { Button, Select, Stack, Textarea } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { useDispatch } from 'react-redux'

import {
  activityCategoryOptions,
  activitySchema,
  initialActivityValues,
} from '../../../constants/casework'
import {
  createCaseworkActivity,
  loadCaseworkActivity,
  updateCaseworkActivity,
} from '../../../redux/features/caseworkSlice'
import { FormField } from '../../ui'

const ActivityForm = ({
  isEditing,
  handleFormSubmit,
  initialValues = initialActivityValues,
}) => {
  const dispatch = useDispatch()
  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={activitySchema}
      onSubmit={(values, { setSubmitting }) => {
        const callback = () => {
          dispatch(loadCaseworkActivity())
          handleFormSubmit()
          setSubmitting(false)
        }

        setSubmitting(true)
        if (isEditing) {
          dispatch(updateCaseworkActivity({ values, callback }))
        } else {
          dispatch(createCaseworkActivity({ values, callback }))
        }
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <FormField field='description' inputType={Textarea} isRequired />
            <Stack direction='row' mt={2}>
              <FormField
                field='category'
                options={activityCategoryOptions}
                inputType={Select}
              />
              <Button onClick={handleFormSubmit} colorScheme='red'>
                Cancel
              </Button>
              <Button type='submit' w='30%' isLoading={isSubmitting}>
                Save
              </Button>
            </Stack>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ActivityForm
