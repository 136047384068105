import { call, put, select, takeLatest } from 'redux-saga/effects'

import { downloadFile, getFilterQueryString } from '../../utils'
import backendAPI from '../axiosConfig'
import {
  cancelLoading,
  completeExportStaff,
  createStaff,
  deactivateStaff,
  loadStaff,
  loadStaffInstance,
  loadStaffOptions,
  paginate,
  paginateStaffOptions,
  setErrors,
  startExportStaff,
  storeStaff,
  storeStaffInstance,
  storeStaffOptions,
  updateStaff,
} from '../features/staffSlice'

const STAFF_ENDPOINT = '/api/staff/'

function* loadStaffSaga() {
  try {
    const filters = yield select(state => state.directory.filters)

    const response = yield call(() => {
      return backendAPI.get(
        STAFF_ENDPOINT + `?${getFilterQueryString(filters)}`
      )
    })

    const { count, next, results } = response.data
    yield put(storeStaff({ count, next, results }))
  } catch (error) {
    console.error('Loading staff failed', error)
    yield put(setErrors('Loading staff failed', error))
  }
}

function* paginateSaga() {
  try {
    const paginateUrl = yield select(state => state.staff.next)
    if (!paginateUrl) {
      yield put(cancelLoading())
      return
    }

    const response = yield call(() => {
      return backendAPI.get(paginateUrl)
    })
    const { count, next, results } = response.data

    yield put(storeStaff({ count, next, results }))
  } catch (error) {
    console.error(error)
    yield put(setErrors('Loading more failed'))
  }
}

function* loadStaffInstanceSaga(action) {
  try {
    const { id } = action.payload
    const response = yield call(() => {
      return backendAPI.get(STAFF_ENDPOINT + `${id}/`)
    })
    yield put(storeStaffInstance(response.data))
  } catch (error) {
    console.error('Loading staff instance failed', error)
    yield put(setErrors('Loading staff instance failed', error))
  }
}

function* createStaffSaga(action) {
  const { values, callbackSuccess, callbackFailure } = action.payload
  try {
    const response = yield call(() => {
      return backendAPI.post(STAFF_ENDPOINT, values)
    })
    yield call(callbackSuccess, response.data)
  } catch (error) {
    console.error('Create staff failed', error)
    yield put(setErrors('Create staff failed', error))
    yield call(callbackFailure, error?.response?.data)
  }
}

function* updateStaffSaga(action) {
  const { id, values, callbackSuccess, callbackFailure } = action.payload
  try {
    yield call(() => backendAPI.patch(STAFF_ENDPOINT + `${id}/`, values))

    if (callbackSuccess) yield call(callbackSuccess)
  } catch (error) {
    console.error('Update staff failed', error)
    yield put(setErrors('Update staff failed', error))
    if (callbackFailure) yield call(callbackFailure)
  }
}

function* paginateStaffOptionsSaga() {
  try {
    const nextUrl = yield select(state => state.staff.options_next)
    if (!nextUrl) return

    const response = yield call(() => {
      return backendAPI.get(nextUrl)
    })
    const { results, next } = response.data
    yield put(storeStaffOptions({ results, next }))
  } catch (error) {
    console.error(error)
  }
}

function* loadStaffOptionsSaga(action) {
  try {
    const { value: query, districtOnly, district } = action.payload
    const user = yield select(state => state.auth.user)
    let endpoint = STAFF_ENDPOINT + `select/?name=${query}`
    if (districtOnly) endpoint += '&district=' + user.district
    if (district && !districtOnly) endpoint += '&district=' + district

    const response = yield call(() => {
      return backendAPI.get(endpoint)
    })
    const { results, next } = response.data
    yield put(storeStaffOptions({ results, next }))
  } catch (error) {
    console.error('Loading staff options failed', error)
    yield put(setErrors('Loading staff options failed', error))
  }
}

function* deactivateStaffSaga(action) {
  try {
    const { callbackSuccess } = action.payload
    const staffInstance = yield select(state => state.staff.instance)
    yield call(() => {
      return backendAPI.delete(STAFF_ENDPOINT + `${staffInstance?.user?.id}/`)
    })

    if (callbackSuccess) yield call(callbackSuccess)
  } catch (error) {
    console.error('Deactivate staff failed', error)
    yield put(setErrors('Deactivate staff failed', error))
  }
}

function* startExportStaffSaga(action) {
  try {
    const { useFilters } = action.payload
    let url = STAFF_ENDPOINT + 'export/'
    if (useFilters) {
      const filters = yield select(state => state.directory.filters)
      url += `?${getFilterQueryString(filters)}`
    }

    const response = yield call(() => {
      return backendAPI.get(url, { responseType: 'arraybuffer' })
    })

    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    yield call(downloadFile, blob, 'exported_staff.xlsx')
  } catch (error) {
    console.error(error)
  }
  yield put(completeExportStaff())
}

function* staffAPI() {
  yield takeLatest(loadStaff, loadStaffSaga)
  yield takeLatest(paginate, paginateSaga)
  yield takeLatest(loadStaffInstance, loadStaffInstanceSaga)
  yield takeLatest(createStaff, createStaffSaga)
  yield takeLatest(updateStaff, updateStaffSaga)
  yield takeLatest(paginateStaffOptions, paginateStaffOptionsSaga)
  yield takeLatest(loadStaffOptions, loadStaffOptionsSaga)
  yield takeLatest(deactivateStaff, deactivateStaffSaga)
  yield takeLatest(startExportStaff, startExportStaffSaga)
}

export default staffAPI
