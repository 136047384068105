import React, { useEffect } from 'react'

import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  Portal,
  useDisclosure,
} from '@chakra-ui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  deselectConstituent,
  selectConstituent,
} from '../../../redux/features/constituentsSlice'

const ConstituentOptionsMenu = ({
  url,
  constituent,
  constituentIndex,
  onClose,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose: closeMenu } = useDisclosure()
  const {
    isOpen: isTooltipOpen,
    onOpen: openTooltip,
    onClose: closeTooltip,
  } = useDisclosure()
  const selectedConstituents =
    useSelector(state => state.constituents.selected) || []

  useEffect(() => {
    if (onClose) {
      closeMenu()
      closeTooltip()
    }
    return () => {}
  }, [onClose, closeMenu, closeTooltip])

  const handleMenuItemClick = (e, action) => {
    e.stopPropagation()
    action()
  }

  return (
    <Menu isOpen={isOpen} onClose={closeMenu}>
      <Tooltip
        hasArrow
        label='Options'
        aria-label='Options'
        isOpen={isTooltipOpen}
        onClose={closeTooltip}
      >
        <MenuButton
          as={IconButton}
          aria-label='See options menu for constituent'
          variant='ghost'
          colorScheme='gray'
          onClick={() => {
            onOpen()
            closeTooltip()
          }}
          onMouseEnter={openTooltip}
          onMouseLeave={closeTooltip}
          icon={<EllipsisVerticalIcon height={25} />}
        ></MenuButton>
      </Tooltip>
      <Portal>
        <MenuList>
          <MenuItem
            as='a'
            href={url}
            target='_blank'
            onClick={e => handleMenuItemClick(e, () => {})}
          >
            Open in a new tab
          </MenuItem>
          <MenuItem
            onClick={e =>
              handleMenuItemClick(e, () =>
                navigate('/casework/create', {
                  state: { constituent: constituent },
                })
              )
            }
          >
            Create new casework
          </MenuItem>
          {selectedConstituents.includes(constituentIndex) ? (
            <MenuItem
              onClick={e =>
                handleMenuItemClick(e, () =>
                  dispatch(deselectConstituent(constituentIndex))
                )
              }
            >
              Remove selection
            </MenuItem>
          ) : (
            <MenuItem
              onClick={e =>
                handleMenuItemClick(e, () =>
                  dispatch(selectConstituent(constituentIndex))
                )
              }
            >
              Select
            </MenuItem>
          )}
        </MenuList>
      </Portal>
    </Menu>
  )
}
export default ConstituentOptionsMenu
