import { React } from 'react'

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Heading,
  VStack,
  Spacer,
  Checkbox,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import ForgotPasswordModal from './ForgotPasswordModal'
import TwoFactorAuthModal from './TwoFactorAuthModal'
import { login } from '../../redux/features/authSlice'
import { PasswordInput } from '../ui'

const LoginForm = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.auth.loading)
  const loginErrors = useSelector(state => state.auth.errors)
  const pendingOTP = useSelector(state => state.auth.otp)
  const loginFormContainerBg = useColorModeValue('white', '#14395c')
  const {
    isOpen: forgotPasswordOpen,
    onOpen: openForgotPassword,
    onClose: closeForgotPassword,
  } = useDisclosure()

  const initialValues = {
    username: localStorage.getItem('loginEmail') || '',
    password: '',
    rememberMe: !!localStorage.getItem('loginEmail'),
    otp: '',
  }

  return (
    <Box
      p={{ base: '1.5rem', md: '2rem' }}
      borderRadius={10}
      width={{ base: '85vw', md: '450px' }}
      minWidth='250px'
      boxShadow='lg'
      bg={loginFormContainerBg}
      zIndex={1}
    >
      <Heading as='h2' size='lg' textAlign='center' mb={10}>
        Login
      </Heading>
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, { setFieldValue }) => {
          if (values['rememberMe']) {
            localStorage.setItem('loginEmail', values['username'])
          } else {
            localStorage.removeItem('loginEmail')
          }

          const formattedValues = { ...values }
          formattedValues.username = formattedValues.username.toLowerCase()
          formattedValues.otp = formattedValues.otp.trim()
          dispatch(login({ values: formattedValues }))
          setFieldValue('otp', '')
        }}
      >
        {({ handleSubmit, handleChange, values, errors, touched }) => (
          <Form onSubmit={handleSubmit}>
            <VStack spacing={4}>
              <FormControl
                id='login-email'
                isInvalid={loginErrors && !pendingOTP}
              >
                <FormLabel htmlFor='username'>Email address</FormLabel>
                <Input
                  id='username'
                  name='username'
                  type='email'
                  size={{ base: 'md', md: 'lg' }}
                  variant='filled'
                  placeholder='Enter email address'
                  onChange={handleChange}
                  value={values.username}
                  autoComplete='on'
                />
              </FormControl>
              <FormControl
                id='login-password'
                isInvalid={
                  (!!errors.password && touched.password) ||
                  (loginErrors && !pendingOTP)
                }
              >
                <FormLabel htmlFor='password'>Password</FormLabel>
                <PasswordInput />
                <FormErrorMessage>
                  {loginErrors || errors.password}
                </FormErrorMessage>
              </FormControl>
              <Checkbox
                id='rememberMe'
                name='rememberMe'
                defaultChecked={values.rememberMe}
                onChange={handleChange}
                value={values.rememberMe}
              >
                Remember email
              </Checkbox>
              <TwoFactorAuthModal />
              <ForgotPasswordModal
                isOpen={forgotPasswordOpen}
                onClose={closeForgotPassword}
              />
              <Spacer height='16px' />
              <VStack
                justifyContent='space-between'
                width='100%'
                spacing='12px'
              >
                <Button
                  width='100%'
                  borderRadius='full'
                  type='submit'
                  size={{ base: 'md', md: 'lg' }}
                  isLoading={isLoading}
                >
                  Login
                </Button>
                <Button
                  fontSize='md'
                  variant='link'
                  onClick={openForgotPassword}
                >
                  Forgot Password?
                </Button>
              </VStack>
            </VStack>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default LoginForm
