import React, { useEffect } from 'react'

import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useToast,
} from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import ConstituentForm from './form/ConstituentForm'
import { loadConstituentInstance } from '../../../redux/features/constituentsSlice'
import {
  loadGroupOptions,
  setErrors,
} from '../../../redux/features/groupsSlice'

const EditConstituentDrawer = ({ isOpen, onClose }) => {
  const { id } = useParams()
  const constituent = useSelector(state => state.constituents.instance)
  const dispatch = useDispatch()
  const toast = useToast()

  useEffect(() => {
    dispatch(loadGroupOptions({ value: '' }))
  }, [dispatch])

  const handleUpdate = () => {
    onClose()
    toast({
      title: 'Successfully updated constituent',
      status: 'success',
    })
    dispatch(loadConstituentInstance({ id }))
  }

  const closeDrawer = () => {
    onClose()
    dispatch(setErrors(null))
  }
  return (
    <Drawer isOpen={isOpen} placement='right' onClose={closeDrawer} size='xl'>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Edit Constituent</DrawerHeader>

        <DrawerBody>
          <ConstituentForm
            isEditing
            constituentValues={constituent}
            handleFormSubmit={handleUpdate}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default EditConstituentDrawer
