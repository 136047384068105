import { React, useRef } from 'react'

import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import { cancelOTP } from '../../redux/features/authSlice'

const TwoFactorAuthModal = () => {
  const dispatch = useDispatch()
  const { values, handleSubmit, setFieldValue, handleChange } =
    useFormikContext()
  const pendingOTP = useSelector(state => state.auth.otp)
  const errors = useSelector(state => state.auth.otp_err)
  const isLoading = useSelector(state => state.auth.loading)

  const initialRef = useRef(null)
  function handleKeyPress(e) {
    if (e.key === 'Enter' && values.otp !== '') {
      handleSubmit()
    }
  }

  function handleCancel() {
    setFieldValue('otp', '')
    dispatch(cancelOTP())
  }

  return (
    <Modal isOpen={pendingOTP} isCentered initialFocusRef={initialRef}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader> Two Factor Authentication </ModalHeader>
        <ModalBody>
          <h2> A one time password was sent to your email. </h2>
          <FormControl
            ref={initialRef}
            id='login-otp'
            isInvalid={errors}
            isRequired
          >
            <Input
              id='otp'
              name='otp'
              type='text'
              size={{ base: 'md', md: 'lg' }}
              variant='filled'
              placeholder='Enter one time password'
              onKeyDown={handleKeyPress}
              onChange={handleChange}
              value={values.otp}
            />
            <FormErrorMessage>{errors}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={() => handleCancel()}>
            Cancel
          </Button>
          <Button
            type='submit'
            onClick={() => handleSubmit()}
            isDisabled={values.otp === ''}
            isLoading={isLoading}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default TwoFactorAuthModal
