import React from 'react'

import { Button, useDisclosure } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'

import AgencyModal from './AgencyModal'
import { loadAgencyInstanceData } from '../../../redux/features/agenciesSlice'

const AgencyButton = ({ agencyName }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const dispatch = useDispatch()
  const handleClick = () => {
    onOpen()
    dispatch(loadAgencyInstanceData({ agency_name: agencyName }))
  }

  return (
    <>
      <Button onClick={handleClick}>{agencyName}</Button>
      <AgencyModal isOpen={isOpen} onClose={onClose} />
    </>
  )
}

export default AgencyButton
