import React from 'react'

import { Divider, Tooltip } from '@chakra-ui/react'

import { priorityIndicatorColors } from '../../constants'

const CaseworkPriorityIndicator = ({
  priority,
  height = 16,
  width = 1.5,
  expandedWidth = 5,
}) => {
  return (
    <Tooltip label={`${priority} Priority`}>
      <Divider
        orientation='vertical'
        h={height}
        width={width}
        _hover={{
          width: expandedWidth,
        }}
        borderRadius='2xl'
        transition='width 0.3s ease-in-out'
        opacity='0.8'
        alignItems='center'
        overflow='hidden'
        display='flex'
        {...priorityIndicatorColors[priority]}
      />
    </Tooltip>
  )
}

export default CaseworkPriorityIndicator
