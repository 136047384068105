import React from 'react'

import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useToast,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { useDispatch } from 'react-redux'

import GroupForm from './GroupForm'
import { groupValidationSchema } from '../../../../constants'
import {
  addConstituentsToGroup,
  removeConstituentsFromGroup,
} from '../../../../redux/features/directorySlice'
import {
  createGroup,
  loadGroups,
  updateGroup,
} from '../../../../redux/features/groupsSlice'
import { calculateMS } from '../../../../utils'

const CreateOrEditGroupModal = ({ initialValues, mode, isOpen, onClose }) => {
  const dispatch = useDispatch()
  const toast = useToast()

  const handleClose = () => {
    onClose()
  }

  const toastSuccess = message => {
    toast({
      title: message,
      status: 'success',
      duration: calculateMS(3),
      isClosable: true,
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size={{ base: 'full', sm: '3xl' }}
    >
      <ModalOverlay />
      <Formik
        initialValues={
          initialValues || {
            name: '',
            alias: '',
            description: '',
            constituents: [],
          }
        }
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true)

          const callbackSuccess = () => {
            handleClose()
            dispatch(loadGroups())
            if (mode === 'create') {
              toastSuccess('Constituent group created')
            } else if (mode === 'edit') {
              toastSuccess('Constituent group edited')
            }
            setSubmitting(false)
          }

          if (mode === 'create') {
            dispatch(createGroup({ values, callbackSuccess }))
          } else if (mode === 'edit') {
            dispatch(
              addConstituentsToGroup({
                id: initialValues.id,
                constituents: values.constituents.filter(
                  c => c.status === 'new'
                ),
              })
            )

            dispatch(
              removeConstituentsFromGroup({
                id: initialValues.id,
                constituents: values.constituents.filter(
                  c => c.status === 'removed'
                ),
              })
            )

            dispatch(
              updateGroup({
                values,
                id: initialValues.id,
                callbackSuccess,
              })
            )
          }
        }}
        validationSchema={groupValidationSchema}
      >
        {({ handleSubmit, isSubmitting }) => (
          <ModalContent>
            <ModalHeader>
              {mode === 'create'
                ? 'Create New Group'
                : mode === 'edit' && 'Edit Group'}
            </ModalHeader>
            <ModalBody>
              <GroupForm />
            </ModalBody>
            <ModalFooter
              position='sticky'
              bottom={0}
              bg='var(--chakra-colors-chakra-body-bg)'
              borderBottomRadius='full'
            >
              <ButtonGroup w='100%'>
                <Button onClick={handleClose} variant='ghost' colorScheme='red'>
                  Cancel
                </Button>
                <Spacer />
                <Button isLoading={isSubmitting} onClick={handleSubmit}>
                  {mode === 'create'
                    ? 'Create Group'
                    : mode === 'edit' && 'Save Changes'}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        )}
      </Formik>
    </Modal>
  )
}

export default CreateOrEditGroupModal
