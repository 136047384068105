import React, { useCallback, useMemo } from 'react'

import { Box, Checkbox, Input, Stack, Text } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'

import { setCaseworkFilters } from '../../../redux/features/caseworkSlice'
import { setDirectoryFilters } from '../../../redux/features/directorySlice'
import {
  NoSearchResults,
  SearchOption,
  SearchOptions,
  SearchSelect,
  SearchSelectedTags,
} from '../select'

const FilterInput = ({
  options,
  label,
  placeholder,
  filterKey,
  store,
  inputType = 'text',
}) => {
  const dispatch = useDispatch()

  const filters = useSelector(state => state[store].filters)

  const selected = filters[filterKey] || []

  const updateFilters = useCallback(
    filter => {
      let filterObj = { ...filters, [filterKey]: filter }

      if (store === 'directory') dispatch(setDirectoryFilters(filterObj))
      if (store === 'casework') dispatch(setCaseworkFilters(filterObj))
    },
    [store, dispatch, filters, setDirectoryFilters, setCaseworkFilters]
  )

  const clearFilters = useCallback(
    () => updateFilters(undefined),
    [updateFilters]
  )

  const handleOptionSelect = useCallback(
    selectedValue => {
      let updatedFilters = ['checkbox', 'date'].includes(inputType)
        ? []
        : [...selected]
      if (selectedValue && !selected.includes(selectedValue)) {
        updatedFilters.push(selectedValue)
      }

      updateFilters(updatedFilters)
    },
    [updateFilters, selected]
  )

  const handleOptionRemove = useCallback(
    removeIndex => {
      updateFilters(selected.toSpliced(removeIndex, 1))
    },
    [updateFilters, selected]
  )

  const handleEnter = useCallback(
    (e, { searchTerm, handleSelectOption }) => {
      if (e.key === 'Enter') {
        if (options) {
          let foundOption = options.find(
            o => o.toLowerCase() === searchTerm.toLowerCase()
          )
          if (foundOption) {
            // context select
            handleSelectOption({ label: foundOption, value: foundOption })
            // filter input select
            handleOptionSelect(foundOption)
          }
        } else {
          handleSelectOption({ label: searchTerm, value: searchTerm })
          handleOptionSelect(searchTerm)
        }
      }
    },
    [handleOptionSelect]
  )

  const filterOptions = useCallback(
    (searchTerm, selectedOptions) => {
      let filteredOptions = options.filter(
        option => !selectedOptions.find(({ value }) => value === option)
      )

      if (searchTerm)
        filteredOptions = filteredOptions.filter(option =>
          option.toLowerCase().includes(searchTerm.toLowerCase())
        )

      if (filteredOptions.length === 0) return null
      return filteredOptions
    },
    [options]
  )

  const selectedFiltersToOptions = useMemo(
    () => selected?.map(option => ({ label: option, value: option })),
    [selected]
  )

  const handleDateChange = e => {
    if (inputType !== 'date') return
    handleOptionSelect(e.target.value)
  }
  return (
    <Box>
      <Text fontSize='lg'> {label} </Text>

      {inputType === 'checkbox' ? (
        <Stack direction='row' align='center'>
          <Checkbox
            id='filter-check'
            onChange={e => handleOptionSelect(e.target.checked)}
            isChecked={selected[0] === true}
          />
          <Text> {placeholder} </Text>
        </Stack>
      ) : inputType === 'date' ? (
        <Input
          type='date'
          onChange={handleDateChange}
          value={selected.length > 0 ? selected[0] : ''}
        />
      ) : (
        <SearchSelect>
          <SearchOptions
            onKeyDown={handleEnter}
            onClear={clearFilters}
            initialSelected={selectedFiltersToOptions}
            hideOptions={!options}
            inputProps={{
              id: `filter-input-${label?.toLowerCase()}`,
              placeholder: placeholder || 'Type or select option below...',
              type: inputType,
            }}
          >
            {({ searchTerm, selectedOptions }) =>
              filterOptions(searchTerm, selectedOptions)?.map(
                (option, index) => (
                  <SearchOption
                    key={index}
                    option={{ label: option, value: option }}
                    onSelect={handleOptionSelect}
                  >
                    {option}
                  </SearchOption>
                )
              ) || <NoSearchResults> No Results </NoSearchResults>
            }
          </SearchOptions>
          {inputType === 'text' && (
            <SearchSelectedTags onRemove={handleOptionRemove} />
          )}
        </SearchSelect>
      )}
    </Box>
  )
}

export default FilterInput
