import React from 'react'

export function throttle(func, delay) {
  let timeoutId
  return function (...args) {
    if (!timeoutId) {
      timeoutId = setTimeout(() => {
        func.apply(this, args)
        timeoutId = null
      }, delay)
    }
  }
}

export function formatPhoneNumber(number) {
  // Remove all non-digit characters from the input
  const cleaned = number.replace(/\D/g, '')

  // Check if the cleaned number does not have 10 digits
  if (cleaned.length !== 10) {
    return cleaned // Return the input as-is
  }

  // Apply the phone number format
  const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')

  return formatted
}

export function isAddressEmpty(address) {
  if (!address) return true

  const { label, line1, line2, po_box, city, state, zipcode } = address
  return (
    label === '' &&
    line1 === '' &&
    line2 === '' &&
    po_box === '' &&
    city === '' &&
    zipcode === '' &&
    (state === '' || state === 'NY')
  )
}

export function getAddressErrors(address) {
  if (!address) return null

  const { line1, city, state, zipcode } = address
  const emptyFields = {}

  if (line1 && city && state && zipcode) {
    return null // Complete object is valid
  }

  // Identify empty fields
  if (line1 === '') {
    emptyFields.line1 = 'Required'
  }
  if (city === '') {
    emptyFields.city = 'Required'
  }
  if (state === '') {
    emptyFields.state = 'Required'
  }
  if (zipcode === '') {
    emptyFields.zipcode = 'Required'
  }

  return emptyFields // Return object with empty field keys
}
export function formatDate(date, options) {
  const defaultOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'America/New_York',
  }
  const mergedOptions = { ...defaultOptions, ...options }
  const dateObj = new Date(date)
  // Check if it's midnight (00:00)
  if (dateObj.getHours() === 0 && dateObj.getMinutes() === 0) {
    return dateObj.toLocaleString('en-US', {
      ...mergedOptions,
      hour: undefined,
      minute: undefined,
    })
  }
  return dateObj.toLocaleString('en-US', mergedOptions)
}

export function formatDatetime(dt) {
  let datetime
  if (!dt) datetime = new Date()
  else datetime = new Date(dt)
  const year = datetime.getFullYear()
  const month = String(datetime.getMonth() + 1).padStart(2, '0')
  const day = String(datetime.getDate()).padStart(2, '0')
  const hours = String(datetime.getHours()).padStart(2, '0')
  const minutes = String(datetime.getMinutes()).padStart(2, '0')
  const currentDatetime = `${year}-${month}-${day}T${hours}:${minutes}`
  return currentDatetime
}

export function compareDates(lhs, rhs) {
  const date1 = new Date(lhs)
  const date2 = new Date(rhs)

  // Compare each component of the dates
  const components = [
    'getFullYear',
    'getMonth',
    'getDate',
    'getHours',
    'getMinutes',
    'getSeconds',
  ]
  for (let i = 0; i < components.length; i++) {
    if (date1[components[i]]() !== date2[components[i]]()) {
      return false
    }
  }

  return true
}

export function daysSinceDate(date) {
  if (!date) return 0

  const today = new Date()
  const inputDate = new Date(date)

  // Set both dates to midnight for accurate comparison
  today.setHours(0, 0, 0, 0)
  inputDate.setHours(0, 0, 0, 0)

  // Calculate the time difference in milliseconds
  const timeDifference = today.getTime() - inputDate.getTime()

  // Convert milliseconds to days
  const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

  return daysAgo
}

export function dayAgeString(date) {
  const ageInDays = daysSinceDate(date)
  if (ageInDays < 0) return `in ${-ageInDays} days`
  if (ageInDays === 1) return 'Yesterday'

  // Calculate years, months, weeks, and days
  const years = Math.floor(ageInDays / 365)
  const months = Math.floor((ageInDays % 365) / 30)
  const weeks = Math.floor((ageInDays % 30) / 7)
  const days = ageInDays % 7
  const ageString = []

  if (years > 0) {
    ageString.push(`${years} ${years === 1 ? 'year' : 'years'}`)
    if (months > 0) {
      ageString.push(`${months} ${months === 1 ? 'month' : 'months'}`)
    }
  } else if (months > 0) {
    ageString.push(`${months} ${months === 1 ? 'month' : 'months'}`)
    if (weeks > 0) {
      ageString.push(`${weeks} ${weeks === 1 ? 'week' : 'weeks'}`)
    }
    if (days > 0) {
      ageString.push(`${days}  ${days === 1 ? 'day' : 'days'}`)
    }
  } else if (weeks > 0) {
    ageString.push(`${weeks}  ${weeks === 1 ? 'week' : 'weeks'}`)
    if (days > 0) {
      ageString.push(`${days}  ${days === 1 ? 'day' : 'days'}`)
    }
  } else {
    ageString.push(`${ageInDays}  ${days === 1 ? 'day' : 'days'}`)
  }

  return ageString.join(' ') + ' ago'
}

export function snakeCasetoTitle(str) {
  // Split the string by underscore
  const words = str.split('_')

  // Capitalize each word and join them with a space
  let normalized = words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')

  return normalized || ''
}

export function extractField(field, value) {
  const lookupParts = field.split('.')

  let result = value

  for (const part of lookupParts) {
    if (result && result[part]) {
      result = result[part]
    } else {
      return ''
    }
  }
  return Array.isArray(result) ? result[0] : result
}

function addLeadingZeros(number, minDigits) {
  if (!number) return ''

  // Convert the number to a string
  let numString = number.toString()

  // Calculate the number of leading zeros needed
  let leadingZeros = minDigits - numString.length

  // Add the leading zeros
  for (let i = 0; i < leadingZeros; i++) {
    numString = '0' + numString
  }

  return numString
}

export function formatCaseNum(casework) {
  const { created_at, opened_at, district, case_num } = casework
  const date = new Date(opened_at || created_at)
  const year = date.getFullYear()

  const formattedDistrict =
    district === 'Central Staff' ? 'CS' : addLeadingZeros(district, 2)
  const formattedCaseNum = addLeadingZeros(case_num, 5)

  return `${year}-${formattedDistrict}-${formattedCaseNum}`
}

export function extractCaseNum(case_num) {
  return case_num.split('-')?.pop()
}

export function truncateFilename(str) {
  const fileNameWithoutExtension = str.substring(0, str.lastIndexOf('.')) || str

  if (fileNameWithoutExtension.length < 15) {
    return str
  }

  const truncatedFileName =
    fileNameWithoutExtension.substring(0, 8) +
    '...' +
    fileNameWithoutExtension.slice(-5)
  const fileExtension =
    str.lastIndexOf('.') !== -1 ? str.substring(str.lastIndexOf('.')) : ''
  return truncatedFileName + fileExtension
}

export function toAddressString(address) {
  if (!address) return ''
  let addressParts = []

  const keys = ['line1', 'line2', 'city', 'state', 'zipcode']
  keys.forEach(key => {
    if (address[key] !== undefined) {
      addressParts.push(address[key])
    }
  })

  return addressParts.join(' ')
}

export function renderTextLines(text) {
  const lines = text.split(/\r\n|\n/)
  return lines.map((line, index) => (
    <p
      key={index}
      style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}
    >
      {line || '\u00A0'}
    </p>
  ))
}

const randomNum = (min, max) =>
  Math.floor(Math.random() * (max - min + 1)) + min
class Color {
  constructor(colorMode = 'light') {
    this.minHue = 0
    this.maxHue = 360

    this.minSat = 75
    this.maxSat = 100

    this.minLight = 65
    this.maxLight = 80

    this.scaleLight = 15

    if (colorMode === 'light') {
      this.minLight = 40
      this.maxLight = 65
    }

    this.hue = randomNum(this.minHue, this.maxHue)
    this.sat = randomNum(this.minSat, this.maxSat)
    this.light = randomNum(this.minLight, this.maxLight)

    this.hsl = 'hsl(' + this.hue + ', ' + this.sat + '%, ' + this.light + '%)'
  }

  changeHue(hue, rotate) {
    return hue + rotate > this.maxHue
      ? hue + rotate - this.maxHue
      : hue + rotate
  }

  // Scale lightness while keeping within limits
  changeLight(light) {
    return light + this.scaleLight > this.maxLight
      ? this.maxLight
      : light + this.scaleLight
  }
}

export function generateShades(n, colorMode = 'light') {
  let rotation = 45
  let baseColor = new Color(colorMode)
  let colors = [baseColor]

  for (let i = 0; i < n; i++) {
    colors.push(new Color(baseColor.hue, rotation))
    rotation += rotation
  }

  return colors.map(color => color.hsl)
}

export function getQuarterDates(quarter, year) {
  // Determine the start and end months based on the quarter
  let startMonth, endMonth
  switch (quarter) {
    case 'Q1':
      startMonth = 0 // January
      endMonth = 2 // March
      break
    case 'Q2':
      startMonth = 3 // April
      endMonth = 5 // June
      break
    case 'Q3':
      startMonth = 6 // July
      endMonth = 8 // September
      break
    case 'Q4':
      startMonth = 9 // October
      endMonth = 11 // December
      break
    default:
      throw new Error('Invalid quarter specified')
  }

  // Calculate the start and end dates
  const start = new Date(year, startMonth, 1)
  const end = new Date(year, endMonth + 1, 0) // Last day of the end month

  return { start, end }
}
