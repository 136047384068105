import React from 'react'

import { MenuItem, useDisclosure } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'

import { MergeConstituentModal } from './merge'
import { startExportConstituents } from '../../../redux/features/constituentsSlice'
import { ExportLoading } from '../../ui'

const ConstituentActionMenu = () => {
  const dispatch = useDispatch()
  const isExporting = useSelector(state => state.constituents.exporting)
  const selectedConstituents =
    useSelector(state => state.constituents.selected) || []
  const user = useSelector(state => state.auth.user)

  const {
    isOpen: mergeIsOpen,
    onOpen: mergeOnOpen,
    onClose: mergeOnClose,
  } = useDisclosure()

  return (
    <>
      {user.is_admin && (
        <MenuItem
          onClick={() =>
            dispatch(startExportConstituents({ useFilters: true }))
          }
        >
          Export current view
        </MenuItem>
      )}
      <MenuItem
        isDisabled={selectedConstituents.length < 2}
        onClick={mergeOnOpen}
      >
        Merge selected constituents{' '}
        {selectedConstituents.length >= 2 && `(${selectedConstituents.length})`}
      </MenuItem>
      <ExportLoading isLoading={isExporting} />
      {mergeIsOpen && (
        <MergeConstituentModal isOpen={mergeIsOpen} onClose={mergeOnClose} />
      )}
    </>
  )
}

export default ConstituentActionMenu
