import { call, cancel, put, select, takeLatest } from 'redux-saga/effects'

import { CONSTITUENT_ENDPOINT } from './constituentsSaga'
import { handleAddressSaga } from './directorySaga'
import { formatDatetime, isAddressEmpty } from '../../components/utils'
import { ARCHIVED_CASEWORK_TAB, CASEWORK_TAB } from '../../constants'
import {
  isEqualObject,
  downloadExcelFile,
  getFilterQueryString,
} from '../../utils'
import backendAPI from '../axiosConfig'
import {
  applyCaseworkFilters,
  cancelLoading,
  clearCasework,
  clearCaseworkFilters,
  clearCaseworkSearch,
  createCaseworkActivity,
  createCaseworkInstance,
  createTopic,
  loadAssignedCasework,
  loadCaseworkActivity,
  loadCaseworkInstance,
  loadCasework,
  loadTopics,
  paginate,
  setCaseworkOrderBy,
  setCaseworkSearch,
  setErrors,
  setRemoveComplete,
  setRemoveWaiting,
  setSuccess,
  setTabIndex,
  setUploadComplete,
  setUploadWaiting,
  storeCasework,
  storeCaseworkActivity,
  storeCaseworkInstance,
  storeTopics,
  updateCaseworkActivity,
  updateCaseworkInstance,
  updateTopic,
  loadCaseworkTags,
  storeCaseworkTags,
  deleteCaseworkInstance,
  transferCaseworkInstance,
  deleteCaseworkActivity,
  startExportCasework,
  completeExportCasework,
  setCaseworkFilters,
  paginateCaseworkTags,
} from '../features/caseworkSlice'

const CASEWORK_ENDPOINT = '/api/casework/'

function* getCaseworkUrl() {
  const tabIndex = yield select(state => state.casework.tab_index)
  return (
    CASEWORK_ENDPOINT +
    `${tabIndex === ARCHIVED_CASEWORK_TAB ? 'archive/' : ''}`
  )
}

function* loadAssignedCaseworkSaga(action) {
  try {
    const { orderBy } = action.payload
    let url = CASEWORK_ENDPOINT + 'assigned/'
    if (orderBy) url += '?order_by=' + orderBy

    const response = yield call(() => {
      return backendAPI.get(url)
    })

    const { count, next, previous, results } = response.data

    yield put(storeCasework({ count, next, previous, results }))
  } catch (error) {
    console.error(error)
    yield put(setErrors('Error loading assigned casework'))
  }
}

function* loadCaseworkSaga() {
  try {
    const filters = yield select(state => state.casework.filters)
    const tabIndex = yield select(state => state.casework.tab_index)

    if (filters.order_by === undefined) {
      yield put(setCaseworkFilters({ ...filters, order_by: '-opened_at' }))
    }

    if (filters.status__in === undefined && tabIndex === CASEWORK_TAB) {
      yield put(
        setCaseworkFilters({
          ...(yield select(state => state.casework.filters)),
          status__in: ['In Progress', 'New Transfer', 'Pending'],
        })
      )
    }

    yield put(applyCaseworkFilters({ filters_changed: true }))
  } catch (error) {
    console.error(error)
  }
}

function* paginateSaga(action) {
  try {
    const direction = action.payload
    const url = yield select(state => state.casework[direction])
    const response = yield call(() => {
      return backendAPI.get(url)
    })
    const { count, next, previous, results } = response.data
    yield put(storeCasework({ count, next, previous, results }))
  } catch (error) {
    console.error(error)
  }
}

function* uploadAttachmentSaga(attachments, case_num) {
  try {
    let attachment
    for (let i = 0; i < attachments.length; i++) {
      attachment = attachments[i]
      yield put(setUploadWaiting(i))
      const formData = new FormData()

      let file = attachment.file
      let fileName = attachment.file.name
      if (fileName.length > 100) {
        const fileExtension =
          fileName.lastIndexOf('.') !== -1
            ? fileName.substring(fileName.lastIndexOf('.'))
            : ''
        fileName =
          fileName.substring(0, 100 - fileExtension.length) + fileExtension
        file = new File([attachment.file], fileName, {
          type: attachment.file.type,
        })
      }
      formData.append('file', file)
      formData.append('file_name', attachment.file_name)

      yield call(() => {
        return backendAPI.post(
          `api/casework/${case_num}/add_attachment/`,
          formData
        )
      })
      yield put(setUploadComplete(i))
    }
  } catch (error) {
    console.error(error)
    yield put(setErrors('Upload attachment failed'))
  }
}

function* deleteAttachmentSaga(attachment_ids) {
  try {
    for (let id of attachment_ids) {
      yield put(setRemoveWaiting(id))
      yield call(() => {
        return backendAPI.post(`api/casework/remove_attachment/${id}/`)
      })
      yield put(setRemoveComplete(id))
    }
  } catch (error) {
    console.error(error)
    yield put(setErrors('Delete attachment failed'))
  }
}

function* createCaseworkInstanceSaga(action) {
  const { values, callbackSuccess, callbackFailure } = action.payload
  try {
    if (!values.constituent && values.newConstituent) {
      try {
        if (!isAddressEmpty(values.newConstituent.home_address)) {
          values.newConstituent.home_address = yield handleAddressSaga(
            values.newConstituent.home_address,
            callbackFailure
          )
        }
        const response = yield call(() => {
          return backendAPI.post(CONSTITUENT_ENDPOINT, values.newConstituent)
        })
        const { id } = response.data
        values.constituent = id
      } catch (error) {
        console.error('Creating new constituent with casework failed', error)
        yield put(
          setErrors('Creating new constituent with casework failed', error)
        )

        if (callbackFailure)
          yield call(callbackFailure, {
            'newConstituent': error.response.data,
          })
        yield cancel()
      }
    }

    const response = yield call(() => {
      return backendAPI.post(CASEWORK_ENDPOINT, values)
    })
    const { case_num } = response.data

    if (values.add_attachments?.length > 0) {
      yield call(uploadAttachmentSaga, values.add_attachments, case_num)
    }

    if (callbackSuccess) yield call(callbackSuccess, case_num)
    yield put(cancelLoading())
  } catch (error) {
    console.error(error)
    yield put(setErrors('Create casework failed'))
    if (callbackFailure) yield call(callbackFailure)
  }
}

function* filterSaga(action) {
  try {
    const { filters_changed, filters } = yield select(state => state.casework)
    if (!filters_changed) return

    const url = yield getCaseworkUrl()
    const response = yield call(() => {
      return backendAPI.get(`${url}?${getFilterQueryString(filters)}`)
    })

    const { count, next, previous, results } = response.data
    yield put(clearCasework())
    yield put(storeCasework({ count, next, previous, results }))
    if (action.payload?.callback) yield call(action.payload?.callback)
  } catch (error) {
    console.error(error)
    yield put(setErrors('Filter failed'))
  }
}

function* loadCaseworkInstanceSaga(action) {
  try {
    const { case_num, archive } = action.payload
    const url = CASEWORK_ENDPOINT + `${archive ? 'archive/' : ''}${case_num}/`

    const response = yield call(() => {
      return backendAPI.get(url)
    })
    const casework = response.data

    if (casework.opened_at)
      casework.opened_at = formatDatetime(casework.opened_at)

    if (casework.closed_at)
      casework.closed_at = formatDatetime(casework.closed_at)
    const activityUrl = CASEWORK_ENDPOINT + case_num + '/activity/'
    const activityResponse = yield call(() => {
      return backendAPI.get(activityUrl)
    })
    casework.activity = activityResponse.data
    yield put(storeCaseworkInstance(casework))
  } catch (error) {
    console.error(error)
    yield put(setErrors('Casework not found'))
  }
}

function* updateCaseworkInstanceSaga(action) {
  const { values, callbackSuccess, callbackFailure } = action.payload
  try {
    if (values.address !== null && values.address !== undefined) {
      const previousAddress = yield select(
        state => state.casework.casework_instance?.address
      )
      const addressChanged = !isEqualObject(previousAddress, values.address)

      if (addressChanged && !isAddressEmpty(values.address)) {
        values.address = yield handleAddressSaga(
          values.address,
          callbackFailure
        )
      }
    }

    if (!values.constituent && values.newConstituent) {
      try {
        values.newConstituent.home_address = values.home_address
        const response = yield call(() => {
          return backendAPI.post(CONSTITUENT_ENDPOINT, values.newConstituent)
        })
        const { id } = response.data
        values.constituent = id
      } catch (error) {
        console.error('Creating new constituent with casework failed', error)
        yield put(
          setErrors('Creating new constituent with casework failed', error)
        )

        if (callbackFailure)
          yield call(callbackFailure, {
            'newConstituent': error.response.data,
          })
        yield cancel()
      }
    }

    yield call(() => {
      return backendAPI.patch(CASEWORK_ENDPOINT + `${values.case_num}/`, values)
    })

    if (values.add_attachments?.length > 0) {
      yield call(uploadAttachmentSaga, values.add_attachments, values.case_num)
    }

    if (values.remove_attachments?.length > 0) {
      yield call(deleteAttachmentSaga, values.remove_attachments)
    }
    yield put(setSuccess())
    if (callbackSuccess) yield call(callbackSuccess)
  } catch (error) {
    console.error(error)
    yield put(setErrors('Casework update failed'))
    if (callbackFailure) yield call(callbackFailure, error)
  }
}

function* loadCaseworkActivitySaga() {
  try {
    const { case_num } = yield select(state => state.casework.casework_instance)
    const response = yield call(() => {
      return backendAPI.get(CASEWORK_ENDPOINT + `${case_num}/activity/`)
    })
    yield put(storeCaseworkActivity(response.data))
  } catch (error) {
    console.error('Get casework activity failed', error)
    yield put(setErrors('Get casework activity failed', error))
  }
}

function* createCaseworkActivitySaga(action) {
  try {
    const { values, callback } = action.payload
    const { case_num } = yield select(state => state.casework.casework_instance)
    yield call(() => {
      return backendAPI.post(
        CASEWORK_ENDPOINT + `${case_num}/activity/`,
        values
      )
    })

    yield put(setSuccess())
    yield call(callback)
  } catch (error) {
    console.error(error)
    yield put(setErrors('Create activity failed'))
  }
}

function* updateCaseworkActivitySaga(action) {
  try {
    const { values, callback } = action.payload

    const { case_num } = yield select(state => state.casework.casework_instance)
    yield call(() => {
      return backendAPI.patch(
        CASEWORK_ENDPOINT + `${case_num}/activity/${values.id}/`,
        values
      )
    })
    yield put(setSuccess())
    yield call(callback)
  } catch (error) {
    console.error('Update casework activity failed', error)
    yield put(setErrors('Update casework activity failed', error))
  }
}

function* deleteCaseworkActivitySaga(action) {
  if (!action) return

  const { id, callbackSuccess, callbackFailure } = action.payload
  try {
    const { case_num } = yield select(state => state.casework.casework_instance)
    yield call(() => {
      return backendAPI.delete(
        CASEWORK_ENDPOINT + `${case_num}/activity/${id}/`
      )
    })
    yield put(setSuccess())
    if (callbackSuccess) yield call(callbackSuccess)
  } catch (error) {
    if (callbackFailure) yield call(callbackFailure)
    console.error('Delete casework failed', error)
    yield put(setErrors('Delete casework activity failed', error))
  }
}

function* loadTopicsSaga(action) {
  try {
    const params = action.payload?.params
    let url = `/api/casework/topics/`
    if (params) url += `?${getFilterQueryString(params)}`

    const response = yield call(() => {
      return backendAPI.get(url)
    })
    yield put(storeTopics(response.data))
  } catch (error) {
    console.error(error)
  }
}

function* createTopicSaga(action) {
  const { topics, callback } = action.payload
  try {
    for (let topic of topics) {
      yield call(() => {
        return backendAPI.post(`/api/casework/topics/`, topic)
      })
    }
    if (callback) yield call(callback)
  } catch (error) {
    console.error(error)
  }
}

function* updateTopicSaga(action) {
  try {
    const { topics, callback } = action.payload
    for (let topic of topics) {
      yield call(() => {
        return backendAPI.patch(`/api/casework/topics/${topic.id}/`, topic)
      })
    }
    if (callback) yield call(callback)
  } catch (error) {
    console.error(error)
  }
}

function* loadCaseworkTagsSaga() {
  try {
    const response = yield call(() => {
      return backendAPI.get(CASEWORK_ENDPOINT + `tags/`)
    })
    const { results, next } = response.data
    yield put(storeCaseworkTags({ results, next }))
  } catch (error) {
    console.error(error)
  }
}

function* paginateCaseworkTagsSaga() {
  const nextUrl = yield select(state => state.casework.tags_next)
  if (!nextUrl) return

  try {
    const response = yield call(() => {
      return backendAPI.get(nextUrl)
    })
    const { results, next } = response.data
    yield put(storeCaseworkTags({ results, next }))
  } catch (error) {
    console.error(error)
  }
}

function* deleteCaseworkInstanceSaga(action) {
  const { case_num, callbackSuccess, callbackFailure } = action.payload
  try {
    yield call(() => {
      return backendAPI.delete(`/api/casework/${case_num}/`)
    })
    if (callbackSuccess) yield call(callbackSuccess)
  } catch (error) {
    console.error(error)
    if (callbackFailure) yield call(callbackFailure)
  }
}

function* transferCaseworkInstanceSaga(action) {
  const { values, callbackSuccess, callbackFailure } = action.payload
  try {
    yield call(() => {
      return backendAPI.post(CASEWORK_ENDPOINT + 'transfer', values)
    })
    if (callbackSuccess) yield call(callbackSuccess)
  } catch (error) {
    console.error(error)
    if (callbackFailure) yield call(callbackFailure)
  }
}

function* exportCaseworkSaga(action) {
  try {
    const { useFilters } = action.payload
    let url = CASEWORK_ENDPOINT + 'export/'
    if (useFilters) {
      const filters = yield select(state => state.casework.filters)
      url += `?${getFilterQueryString(filters)}`
    }

    const response = yield call(() => {
      return backendAPI.get(url, { responseType: 'arraybuffer' })
    })

    yield call(downloadExcelFile, response.data, 'casework.xlsx')
  } catch (error) {
    console.error(error)
  }
  yield put(completeExportCasework())
}

function* caseworkAPI() {
  yield takeLatest(applyCaseworkFilters, filterSaga)
  yield takeLatest(clearCaseworkFilters, filterSaga)
  yield takeLatest(clearCaseworkSearch, filterSaga)
  yield takeLatest(createCaseworkActivity, createCaseworkActivitySaga)
  yield takeLatest(createCaseworkInstance, createCaseworkInstanceSaga)
  yield takeLatest(createTopic, createTopicSaga)
  yield takeLatest(deleteCaseworkActivity, deleteCaseworkActivitySaga)
  yield takeLatest(deleteCaseworkInstance, deleteCaseworkInstanceSaga)
  yield takeLatest(loadAssignedCasework, loadAssignedCaseworkSaga)
  yield takeLatest(loadCasework, loadCaseworkSaga)
  yield takeLatest(loadCaseworkActivity, loadCaseworkActivitySaga)
  yield takeLatest(loadCaseworkInstance, loadCaseworkInstanceSaga)
  yield takeLatest(loadCaseworkTags, loadCaseworkTagsSaga)
  yield takeLatest(paginateCaseworkTags, paginateCaseworkTagsSaga)
  yield takeLatest(loadTopics, loadTopicsSaga)
  yield takeLatest(paginate, paginateSaga)
  yield takeLatest(setCaseworkOrderBy, filterSaga)
  yield takeLatest(setCaseworkSearch, filterSaga)
  yield takeLatest(setTabIndex, loadCaseworkSaga)
  yield takeLatest(startExportCasework, exportCaseworkSaga)
  yield takeLatest(transferCaseworkInstance, transferCaseworkInstanceSaga)
  yield takeLatest(updateCaseworkActivity, updateCaseworkActivitySaga)
  yield takeLatest(updateCaseworkInstance, updateCaseworkInstanceSaga)
  yield takeLatest(updateTopic, updateTopicSaga)
}

export default caseworkAPI
