import React, { useCallback } from 'react'

import NoSearchResults from './NoSearchResults'
import SearchOption from './SearchOption'
import SearchOptions from './SearchOptions'
import SearchSelect from './SearchSelect'
import SearchSelectedTags from './SearchSelectedTags'
import { languages } from '../../../constants'

const LanguageSelect = ({
  onSelect = () => {},
  onRemove = () => {},
  onClear = () => {},
  initialSelected,
  inputProps = {},
  dropdownHeight,
}) => {
  const filterOptions = useCallback(
    (searchTerm, selectedOptions) => {
      let options = languages.filter(
        language =>
          !selectedOptions.map(option => option.value).includes(language)
      )

      if (searchTerm)
        options = options.filter(language =>
          language.toLowerCase().includes(searchTerm.toLowerCase())
        )

      if (options.length === 0) return null
      return options
    },
    [languages]
  )

  return (
    <SearchSelect>
      <SearchOptions
        onClear={onClear}
        initialSelected={initialSelected}
        dropdownHeight={dropdownHeight}
        inputProps={{
          placeholder: 'Select language(s) from dropdown',
          ...inputProps,
        }}
      >
        {({ searchTerm, selectedOptions }) =>
          filterOptions(searchTerm, selectedOptions)?.map((language, index) => (
            <SearchOption
              key={`language-option-${index}`}
              option={{ label: language, value: language }}
              onSelect={onSelect}
            >
              {language}
            </SearchOption>
          )) || <NoSearchResults>No languages found</NoSearchResults>
        }
      </SearchOptions>
      <SearchSelectedTags onRemove={onRemove} />
    </SearchSelect>
  )
}

export default LanguageSelect
