import React from 'react'

import { Box, Heading } from '@chakra-ui/react'

import { NoAssignmentsIcon } from '../../assets/SvgIcons'

const NoAssignments = () => {
  return (
    <Box
      my='10'
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
    >
      <NoAssignmentsIcon />
      <Heading as='p' size='md' mt='5'>
        No Casework
      </Heading>
    </Box>
  )
}

export default NoAssignments
