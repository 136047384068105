import React, { useCallback } from 'react'

import { Button, Grid, GridItem, Input, Tag, Text } from '@chakra-ui/react'

const SelectedGroup = ({
  index,
  name,
  role = '',
  onRemove = () => {},
  onRoleChange = () => {},
}) => {
  const handleRoleChange = useCallback(
    e => onRoleChange(index, e.target.value),
    [index]
  )

  return (
    <Grid
      as={Tag}
      templateAreas={{
        base: `"name actions"
               "role role"`,
        sm: `"name role actions"`,
      }}
      templateRows={{
        base: 'repeat(2, 1fr)',
        sm: '1fr',
      }}
      templateColumns={{
        base: '2fr 0.25fr',
        sm: '1fr 0.75fr 0.25fr',
      }}
      minH={{ base: '80px', sm: '48px' }}
      gap={2}
      p={1}
    >
      <GridItem area='name'>
        <Text p={2}>{name}</Text>
      </GridItem>
      <GridItem area='role' px={{ base: 4, sm: 2 }}>
        <Input
          id={`group-role-${index}`}
          w={300}
          variant='flushed'
          value={role}
          onChange={handleRoleChange}
          placeholder='Role (Optional)'
          borderColor='gray.500'
          maxLength={128}
          size='md'
        />
      </GridItem>
      <GridItem area='actions'>
        <Button
          variant='outline'
          onClick={() => onRemove(index)}
          size='sm'
          colorScheme='red'
        >
          Remove
        </Button>
      </GridItem>
    </Grid>
  )
}

export default SelectedGroup
