import React from 'react'

import { ListItem, useColorModeValue } from '@chakra-ui/react'

import { useSearchSelect } from './SearchSelectContext'

const SearchOption = ({ option, children, onSelect = () => {} }) => {
  const { handleSelectOption } = useSearchSelect()

  const topicListItemHover = useColorModeValue('gray.100', 'gray.600')

  if (!option) return null

  const handleSelect = () => {
    onSelect(option.value)
    handleSelectOption(option)
  }

  return (
    <ListItem
      _hover={{
        bg: topicListItemHover,
        cursor: 'pointer',
      }}
      py={1}
      px={3}
      onClick={handleSelect}
    >
      {children}
    </ListItem>
  )
}

export default SearchOption
