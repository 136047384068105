import React from 'react'

import { Text, useToast } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'

import {
  loadCaseworkInstance,
  updateCaseworkInstance,
} from '../../../../redux/features/caseworkSlice'
import { calculateMS } from '../../../../utils'
import { ConfirmActionDialog } from '../../../ui'

const ReopenDialog = ({ case_num, ...props }) => {
  const dispatch = useDispatch()
  const toast = useToast()

  const handleConfirm = () => {
    dispatch(
      updateCaseworkInstance({
        values: {
          case_num,
          status: 'Reopen',
        },
        callbackSuccess: () => {
          dispatch(loadCaseworkInstance({ case_num }))
          toast({
            title: 'Successfully reopened casework',
            status: 'info',
            duration: calculateMS(3),
          })
        },
        callbackFailure: errors => {
          if (errors?.response?.status === 403) {
            toast({
              title: 'You do not have permission to open this case.',
              description:
                'Please contact your Chief of Staff to proceed or open a new case.',
              status: 'error',
            })
          } else {
            toast({
              title: 'Something went wrong trying to reopen this casework.',
              status: 'error',
              duration: calculateMS(3),
            })
          }
        },
      })
    )
    props.onClose()
  }

  return (
    <ConfirmActionDialog {...props} onConfirm={handleConfirm}>
      <Text as='b'>This casework will be reopened and set to In Progress.</Text>
      <Text>
        Cases older than 90 days can only be reopened by your district&apos;s
        Chief of Staff or Director of Constituent Services. Are you sure you
        want to proceed?
      </Text>
    </ConfirmActionDialog>
  )
}
export default ReopenDialog
