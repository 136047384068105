export const tableRowStyles = {
  'td': { alignContent: 'center' },
  '&:hover td, &:hover td.options-column:last-child': {
    cursor: 'pointer',
    backgroundColor: 'gray.100 !important',
    _dark: {
      backgroundColor: 'gray.700 !important',
    },
  },
  'td.options-column:last-child': {
    textAlign: 'right !important',
    pos: 'sticky',
    right: 0,
    backgroundColor: 'white',
    _dark: {
      backgroundColor: 'gray.800',
    },
  },
}
