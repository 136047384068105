/** Directory */
export const CONSTITUENTS_TAB = 0
export const GROUPS_TAB = 1
export const STAFF_TAB = 2
export const AGENCIES_TAB = 3

/** Casework */
export const CASEWORK_TAB = 0
export const ARCHIVED_CASEWORK_TAB = 1

/** Tools */
export const ADDRESS_LOOKUP_TAB = 0
export const REPORTS_TAB = 1
