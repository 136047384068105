// MIDDLEWARES
import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga'

import agenciesSlice from './features/agenciesSlice'
import authSlice from './features/authSlice'
import caseworkSlice from './features/caseworkSlice'
import constituentsSlice from './features/constituentsSlice'
import directorySlice from './features/directorySlice'
import groupsSlice from './features/groupsSlice'
import homeSlice from './features/homeSlice'
import profileSlice from './features/profileSlice'
import reportSlice from './features/reportSlice'
import staffSlice from './features/staffSlice'
import toolsSlice from './features/toolsSlice'
import rootSaga from './sagas'

const sagaMiddleware = createSagaMiddleware()

const middleware = [sagaMiddleware]

if (process.env.NODE_ENV === 'development') {
  middleware.push(logger)
}

export const store = configureStore({
  reducer: {
    auth: authSlice,
    casework: caseworkSlice,
    constituents: constituentsSlice,
    groups: groupsSlice,
    staff: staffSlice,
    directory: directorySlice,
    profile: profileSlice,
    tools: toolsSlice,
    home: homeSlice,
    agencies: agenciesSlice,
    reports: reportSlice,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: [
          'payload.navigate',
          'payload.callback',
          'payload.callbackSuccess',
          'payload.callbackFailure',
          'payload.values', // Ignore File objects
        ],
      },
    }).concat(middleware),
  devTools: process.env.NODE_ENV !== 'production',
})

sagaMiddleware.run(rootSaga)
