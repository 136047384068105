import React from 'react'

import { Icon } from '@chakra-ui/icon'
import { MinusIcon } from '@heroicons/react/24/outline'

const NoValue = () => (
  <Icon as={MinusIcon} color='var(--chakra-colors-chakra-text-color)' />
)

export default NoValue
