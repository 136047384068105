import React, { useRef } from 'react'

import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Card,
  CardBody,
  Collapse,
  Flex,
  Heading,
  Icon,
  IconButton,
  Spacer,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { PencilIcon } from '@heroicons/react/24/outline'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import CaseworkDetailTable from './CaseworkDetailTable'
import {
  AddressText,
  CaseworkPriorityIndicator,
  FormattedDate,
  NoValue,
  Person,
  Section,
} from '../../ui'
import { formatCaseNum } from '../../utils'

const CaseworkCard = ({ casework, hideConstituent }) => {
  const navigate = useNavigate()
  const user = useSelector(state => state.auth.user)
  const { isOpen, onOpen } = useDisclosure()

  const menuButtonRef = useRef(null)
  const menuRef = useRef(null)

  const handleExpandCasework = e => {
    if (
      menuButtonRef.current?.contains(e.target) ||
      menuRef.current?.contains(e.target)
    )
      return
    onOpen()
  }

  const {
    case_num,
    priority,
    status,
    last_updated,
    address,
    assigned_staff,
    referral_staff,
    opened_at,
    closed_at,
    created_at,
    district,
    intake_method,
  } = casework

  const url = `/casework/${status === 'Archived' ? 'archive/' : ''}${case_num}`
  return (
    <Card>
      <CardBody
        cursor={isOpen ? 'default' : 'pointer'}
        onClick={handleExpandCasework}
      >
        <Stack>
          <Flex>
            <Stack direction='row' align='center'>
              <CaseworkPriorityIndicator priority={priority} height={10} />
              {/* Casework Number */}
              <Heading
                size='lg'
                color='nyccBlue.800'
                _dark={{ color: 'blue.200' }}
              >
                #{formatCaseNum(casework)}
              </Heading>
            </Stack>

            <Spacer />
            <Box align='end'>
              <Button variant='outline' onClick={() => navigate(url)}>
                View
              </Button>
              {status !== 'Archived' && (
                <Tooltip label='Edit casework' placement='bottom-end'>
                  <IconButton
                    icon=<Icon as={PencilIcon} fontSize='2xl' />
                    variant='ghost'
                    onClick={() => navigate(`/casework/${case_num}/edit`)}
                    ml={1}
                  />
                </Tooltip>
              )}
              <Tooltip label='Open page in new tab' placement='bottom-end'>
                <IconButton
                  icon=<ExternalLinkIcon fontSize='2xl' />
                  variant='ghost'
                  onClick={() => window.open(url, '_blank')}
                  ml={1}
                />
              </Tooltip>
            </Box>
          </Flex>

          {(district === user.district || user.is_admin) && (
            <Flex
              gap={{ base: 4, lg: 10 }}
              direction={{ base: 'column', lg: 'row' }}
            >
              <CaseworkDetailTable
                {...casework}
                tableVariant='unstyled'
                filtered
                hideConstituent={hideConstituent}
              />

              <Stack gap={3} w='xs'>
                {/* Assigned Staff */}
                <Section
                  heading='Assigned Staff'
                  direction={{ base: 'row', lg: 'column' }}
                  align={{ base: 'center', lg: 'start' }}
                >
                  {assigned_staff?.length > 0 ? (
                    assigned_staff.map((staff, index) => (
                      <Person
                        key={index}
                        link={`/directory/staff/${staff.id}`}
                        name={staff.full_name}
                      />
                    ))
                  ) : (
                    <NoValue />
                  )}
                </Section>

                {referral_staff && (
                  <Section heading='Referral Staff'>
                    <Person
                      link={`/directory/staff/${referral_staff.id}`}
                      name={referral_staff.full_name}
                    />
                  </Section>
                )}
              </Stack>
            </Flex>
          )}
        </Stack>

        <Collapse in={isOpen} animateOpacity>
          {district === user.district || user.is_admin ? (
            <Card p={5} mt={2} bg='gray.100' _dark={{ bg: 'gray.500' }}>
              <Stack direction='row'>
                <Section heading='Reporting Address'>
                  <Box>
                    <AddressText address={address} />
                  </Box>
                </Section>
                {/* Last Updated */}
                <Section heading='Last Updated'>
                  <FormattedDate date={last_updated} direction='column' />
                </Section>

                <Section heading='Intake Method'>
                  <Text> {intake_method || <NoValue />} </Text>
                </Section>

                {status === 'REQUESTED' && (
                  <Section heading='Submitted'>
                    <FormattedDate date={created_at} direction='column' />
                  </Section>
                )}

                <Section heading='Opened'>
                  <FormattedDate date={opened_at} direction='column' />
                </Section>

                <Section heading='Closed'>
                  <FormattedDate date={closed_at} direction='column' />
                </Section>
              </Stack>
            </Card>
          ) : (
            <Text>
              This casework is {status}. Please contact{' '}
              <b> District {district} </b>
              for information about this casework.
            </Text>
          )}
        </Collapse>
      </CardBody>
    </Card>
  )
}

export default CaseworkCard
