const subheading = {
  textTransform: 'uppercase',
  fontWeight: 'bold',
}
export const textTheme = {
  variants: {
    'subheading': {
      ...subheading,
    },
    'subheading-gray': {
      ...subheading,
      color: 'gray.500',
      _dark: {
        color: 'gray.300',
      },
    },
    'subheading-sm': {
      ...subheading,
      color: 'gray.500',
      fontSize: 'sm',
      _dark: {
        color: 'gray.200',
      },
    },
    'subheading-blue': {
      ...subheading,
      color: 'blue.500',
      _dark: {
        color: 'blue.300',
      },
    },
  },
}
