import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import SearchSelect from './SearchSelect'
import NoSearchResults from './NoSearchResults'
import SearchOption from './SearchOption'
import SearchOptionCategory from './SearchOptionCategory'
import SearchOptions from './SearchOptions'
import SearchSelectedTags from './SearchSelectedTags'
import { loadTopics } from '../../../redux/features/caseworkSlice'

const TopicSelect = ({
  onSelect = () => {},
  onRemove = () => {},
  onClear = () => {},
  initialSelected,
  dropdownHeight,
  showUnassigned,
  inputProps = {},
}) => {
  const dispatch = useDispatch()

  const topics = useSelector(state => state.casework.topics) || []

  useEffect(() => {
    dispatch(loadTopics())
  }, [dispatch])

  const filterTopics = (searchTerm, selectedOptions) => {
    let lowerSearch = searchTerm.toLowerCase()
    let options = []
    for (let topicIssue of topics) {
      let { issue, topics } = topicIssue

      let unselectedTopics =
        selectedOptions.length > 0
          ? topics.filter(
              ({ id }) =>
                !selectedOptions.find(option => option.value.id === id)
            )
          : topics

      if (searchTerm) {
        let issueNameFound = issue.toLowerCase().includes(lowerSearch)
        if (issueNameFound) {
          options.push({ ...topicIssue, topics: unselectedTopics })
        } else {
          let topicsFound = unselectedTopics.some(topic =>
            topic.name.toLowerCase().includes(lowerSearch)
          )

          if (topicsFound) {
            let filteredTopics = unselectedTopics.filter(topic =>
              topic.name.toLowerCase().includes(lowerSearch)
            )
            options.push({ ...topicIssue, topics: filteredTopics })
          }
        }
      } else {
        if (unselectedTopics.length > 0)
          options.push({ ...topicIssue, topics: unselectedTopics })
      }
    }

    if (options.length === 0) return null
    return options
  }

  return (
    <SearchSelect>
      <SearchOptions
        onClear={onClear}
        initialSelected={initialSelected}
        dropdownHeight={dropdownHeight}
        inputProps={{ placeholder: 'Search for a topic', ...inputProps }}
      >
        {({ searchTerm, selectedOptions }) =>
          filterTopics(searchTerm, selectedOptions)?.map(
            ({ topics: topicOptions, issue }, index) =>
              (issue !== '' || (issue === '' && showUnassigned)) && (
                <SearchOptionCategory key={`issue-${index}`} category={issue}>
                  {topicOptions.map((topic, index) => (
                    <SearchOption
                      key={`topic-option-${index}`}
                      option={{ label: topic.name, value: topic }}
                      onSelect={onSelect}
                    >
                      {topic.name}
                    </SearchOption>
                  ))}
                </SearchOptionCategory>
              )
          ) || <NoSearchResults> No topics found </NoSearchResults>
        }
      </SearchOptions>
      <SearchSelectedTags onRemove={onRemove} />
    </SearchSelect>
  )
}

export default TopicSelect
