import React from 'react'

import { Box, Button, Text } from '@chakra-ui/react'

const Pagination = ({
  currentPage,
  totalPages,
  results,
  onPageChange = () => {},
  handleNextPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1)
    }
  },
  handlePreviousPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1)
    }
  },
}) => {
  if (totalPages <= 0) return <></>

  return (
    <Box display='flex' justifyContent='space-between' width='100%' p={2}>
      <Button
        onClick={handlePreviousPage}
        visibility={currentPage <= 1 ? 'hidden' : 'visible'}
        colorScheme='teal'
        variant='outline'
      >
        Previous
      </Button>

      <Box align='center' my='auto'>
        {totalPages > 0 && (
          <Text>
            <b>
              Page {currentPage} of {totalPages}
            </b>
            {results && ` (${results} Result${results !== 1 ? 's' : ''})`}
          </Text>
        )}
      </Box>

      <Button
        onClick={handleNextPage}
        visibility={currentPage === totalPages ? 'hidden' : 'visible'}
        colorScheme='teal'
        variant='outline'
      >
        Next
      </Button>
    </Box>
  )
}

export default Pagination
