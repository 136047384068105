import React from 'react'

import { Text, Tooltip } from '@chakra-ui/react'

import NoValue from './NoValue'
import { isAddressEmpty } from '../utils'

const AddressText = ({ address }) => {
  if (!address || isAddressEmpty(address)) return <NoValue />

  const { label, line1, line2, po_box, city, state, zipcode } = address
  return (
    <>
      {label &&
        (label.length > 40 ? (
          <Tooltip label={label} hasArrow openDelay={300}>
            <Text>{label.slice(0, 40)}...</Text>
          </Tooltip>
        ) : (
          <Text>{label}</Text>
        ))}
      {line1 && <Text>{line1}</Text>}
      {line2 && <Text>{line2}</Text>}
      {po_box && <Text>{po_box}</Text>}
      <Text>
        {city ? `${city}, ` : ''} {state} {zipcode}
      </Text>
    </>
  )
}

export default AddressText
