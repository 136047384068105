import React from 'react'

import { Grid, GridItem, useBoolean } from '@chakra-ui/react'

import ConstituentDetailsBox from './ConstituentDetailsBox'
import DistrictBox from './DistrictBox'
import NameBox from './NameBox'
import EditConstituentDrawer from '../EditConstituentDrawer'

const ConstituentProfile = () => {
  const [editModalOpen, setEditModalOpen] = useBoolean()

  return (
    <Grid
      gridTemplateRows={{ base: '1fr', lg: '0.5fr 3fr' }}
      gridTemplateColumns={{ base: '1fr', lg: '1.5fr 0.75fr' }}
      templateAreas={{
        base: `
        "name"
        "details"
        "district"
        `,
        lg: `
          "name district"
          "details district"
        `,
      }}
      gap={3}
      p={3}
      maxW='7xl'
    >
      <GridItem area='name'>
        <NameBox handleClick={setEditModalOpen.on} />
      </GridItem>
      <GridItem area='district'>
        <DistrictBox />
      </GridItem>
      <GridItem area='details'>
        <ConstituentDetailsBox />
      </GridItem>
      <EditConstituentDrawer
        isOpen={editModalOpen}
        onClose={setEditModalOpen.off}
      />
    </Grid>
  )
}

export default ConstituentProfile
