import React from 'react'

import { Global } from '@emotion/react'

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Gotham';
        src: url('/assets/fonts/Gotham/Gotham-Book.otf') format('opentype');
        font-weight: 400;
        font-style: normal;
      }

      @font-face {
        font-family: 'Bell Gothic Std';
        src: url('/assets/fonts/Bell_Gothic_Std/BellGothicStd-Light.otf') format('opentype');
        font-weight: 300;
        font-style: normal;
      }

      @font-face {
        font-family: 'Bell Gothic Std';
        src: url('/assets/fonts/Bell_Gothic_Std/BellGothicStd-Bold.otf') format('opentype');
        font-weight: 700;
        font-style: normal;
      }
      
      @font-face {
        font-family: 'Bell Gothic Std';
        src: url('/assets/fonts/Bell_Gothic_Std/BellGothicStd-Black.otf') format('opentype');
        font-weight: 900;
        font-style: normal;
      }
    `}
  />
)

export default Fonts
