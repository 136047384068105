import React, { useCallback, useMemo } from 'react'

import { Stack } from '@chakra-ui/react'
import { useField } from 'formik'

import BaseArrayField from './BaseArrayField'
import SelectedGroup from './SelectedGroup'
import { ConstituentGroupSelect } from '../../../../ui/select'

const GroupField = ({ field }) => {
  const [formikField, , helpers] = useField(field)

  const { value: groups } = formikField
  const { setValue } = helpers
  const onRemoveGroup = useCallback(
    removeIndex => setValue(groups.toSpliced(removeIndex, 1)),
    [setValue, groups]
  )

  const onRoleChange = useCallback(
    (index, value) => {
      let updatedGroups = [...groups]
      updatedGroups[index] = { ...updatedGroups[index], role: value }
      setValue(updatedGroups)
    },
    [setValue, groups]
  )

  const selectedGroups = useMemo(
    () => groups.map(group => ({ label: group.name, value: group })),
    [groups]
  )

  return (
    <>
      <BaseArrayField field={field} label='Groups'>
        {({ onSelect, onClear }) => (
          <ConstituentGroupSelect
            onSelect={onSelect}
            onClear={onClear}
            initialSelected={selectedGroups}
            inputProps={{
              id: 'group-select',
              placeholder: 'Search for an existing group in district',
            }}
          />
        )}
      </BaseArrayField>
      {groups.length > 0 && (
        <Stack pt={2} gap={0.5}>
          {groups.map((group, index) => (
            <SelectedGroup
              key={index}
              index={index}
              {...group}
              onRoleChange={onRoleChange}
              onRemove={onRemoveGroup}
            />
          ))}
        </Stack>
      )}
    </>
  )
}

export default GroupField
