import React, { useEffect } from 'react'

import {
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  Portal,
  useDisclosure,
  Tooltip,
  Icon,
  useToast,
} from '@chakra-ui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'

import ConfirmDeleteGroupDialog from './ConfirmDeleteGroupDialog'
import { deleteGroup, loadGroups } from '../../../redux/features/groupsSlice'

const GroupsOptionsMenu = ({ openEditModal, group, onClose }) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)
  const toast = useToast()

  const {
    isOpen: deleteIsOpen,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure()

  const {
    isOpen: isMenuOpen,
    onOpen: openMenu,
    onClose: closeMenu,
  } = useDisclosure()

  const {
    isOpen: isTooltipOpen,
    onOpen: openTooltip,
    onClose: closeTooltip,
  } = useDisclosure()

  useEffect(() => {
    if (onClose) {
      closeMenu()
      closeTooltip()
    }
    return () => {}
  }, [onClose, closeMenu, closeTooltip])

  const handleDelete = e => {
    e.stopPropagation()

    const callbackSuccess = () => {
      dispatch(loadGroups())
      onCloseDelete()
    }

    const callbackFailure = () => {
      toast({
        title: 'Something went wrong',
        description: `Could not delete group ${group.name}`,
        status: 'error',
      })
    }

    dispatch(deleteGroup({ id: group.id, callbackSuccess, callbackFailure }))
  }

  const canDeleteGroup = user.district === group.district || user.is_admin

  return (
    <>
      <Menu isOpen={isMenuOpen} onClose={closeMenu}>
        <Tooltip
          hasArrow
          label='Options'
          aria-label='Options'
          isOpen={isTooltipOpen}
          onClose={closeTooltip}
        >
          <MenuButton
            onClick={e => {
              e.stopPropagation()
              openMenu()
              closeTooltip()
            }}
            aria-label='See options menu for constituent group'
            onMouseEnter={openTooltip}
            onMouseLeave={closeTooltip}
          >
            <Icon as={EllipsisVerticalIcon} boxSize={6} />
          </MenuButton>
        </Tooltip>
        <Portal>
          <MenuList>
            <MenuItem
              onClick={e => {
                e.stopPropagation()
                openEditModal(group)
              }}
            >
              Edit Group
            </MenuItem>
            {canDeleteGroup && (
              <MenuItem
                color='red'
                onClick={e => {
                  e.stopPropagation()
                  onOpenDelete()
                }}
              >
                Delete
              </MenuItem>
            )}
          </MenuList>
        </Portal>
      </Menu>
      <ConfirmDeleteGroupDialog
        group={group}
        isOpen={deleteIsOpen}
        onConfirm={handleDelete}
        onClose={onCloseDelete}
        scheme='delete'
      />
    </>
  )
}
export default GroupsOptionsMenu
