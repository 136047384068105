import React, { useCallback } from 'react'

import { useSelector } from 'react-redux'

import NoSearchResults from './NoSearchResults'
import SearchOption from './SearchOption'
import SearchOptions from './SearchOptions'
import SearchSelect from './SearchSelect'
import SearchSelectedTags from './SearchSelectedTags'

const TagSelect = ({
  tagOptions = [],
  onSelect = () => {},
  onRemove = () => {},
  onClear = () => {},
  loadMoreOptions = () => {},
  initialSelected,
  dropdownHeight,
  inputProps = {},
  preventCreate,
}) => {
  const user = useSelector(state => state.auth.user)
  const createNewTag = value => {
    let newTag = {
      label: value,
      district: user.district,
    }
    onSelect(newTag)
  }

  const handleKeyDown = useCallback(
    (e, { selectedOptions }) => {
      if (e.key === 'Enter' && !preventCreate) {
        let value = e.target.value.toUpperCase()

        let foundOption = tagOptions.find(({ label }) => label === value)
        let foundSelected = selectedOptions.find(({ label }) => label === value)

        if (!foundOption && !foundSelected && value !== '') createNewTag(value)
        if (foundOption && !foundSelected) onSelect(foundOption)
      }
    },
    [tagOptions, createNewTag, onSelect]
  )

  const filterOptions = useCallback(
    (searchTerm, selectedOptions) => {
      let options = tagOptions.filter(
        tag =>
          !selectedOptions.find(selected => selected.value?.label === tag.label)
      )

      if (searchTerm) {
        let lowerSearch = searchTerm.toLowerCase()
        options = tagOptions.filter(
          tag =>
            tag.label.toLowerCase().includes(lowerSearch) &&
            !selectedOptions.find(
              selected => selected.value?.label === tag.label
            )
        )
      }

      if (options.length === 0) return null
      return options
    },
    [tagOptions]
  )

  return (
    <SearchSelect>
      <SearchOptions
        loadMoreOptions={loadMoreOptions}
        onClear={onClear}
        onKeyDown={handleKeyDown}
        initialSelected={initialSelected}
        dropdownHeight={dropdownHeight}
        inputProps={{
          placeholder: 'Select existing tag(s) from dropdown or create new',
          ...inputProps,
        }}
      >
        {({ searchTerm, selectedOptions }) =>
          filterOptions(searchTerm, selectedOptions)?.map((tag, index) => (
            <SearchOption
              key={`tag-option-${index}`}
              option={{ label: tag.label, value: tag }}
              onSelect={onSelect}
            >
              {tag.label}
            </SearchOption>
          )) || (
            <NoSearchResults>
              {preventCreate ? (
                <>No tag found</>
              ) : (
                <>
                  No tag found, press Enter to create <b>{searchTerm}</b> as new
                  tag
                </>
              )}
            </NoSearchResults>
          )
        }
      </SearchOptions>
      <SearchSelectedTags onRemove={onRemove} />
    </SearchSelect>
  )
}

export default TagSelect
