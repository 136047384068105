import React from 'react'

import { Box, Heading } from '@chakra-ui/react'

import { NoResultsIcon } from '../../assets/SvgIcons'

const NoResults = () => {
  return (
    <Box
      mt='10'
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
    >
      <NoResultsIcon />
      <Heading as='p' size='md'>
        No Results
      </Heading>
    </Box>
  )
}

export default NoResults
