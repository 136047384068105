import React from 'react'

import { Stack } from '@chakra-ui/react'

import CaseworkCard from './CaseworkCard'
import { NoAssignments } from '../../ui'

const CaseworkAccordion = ({ casework, hideConstituent }) => {
  if (!casework) return <NoAssignments />
  if (casework.length === 0) return <NoAssignments />
  return (
    <Stack gap={4}>
      {casework?.map(c => (
        <CaseworkCard
          casework={c}
          hideConstituent={hideConstituent}
          key={`casework-card-${c.case_num}`}
        />
      ))}
    </Stack>
  )
}

export default CaseworkAccordion
