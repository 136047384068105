import React, { useRef, useState } from 'react'

import {
  Button,
  Flex,
  Input,
  Spacer,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { useSelector } from 'react-redux'

const ConstituentGroupForm = ({ onCreate = () => {}, onClose }) => {
  const [name, setName] = useState('')
  const [alias, setAlias] = useState('')
  const [description, setDescription] = useState('')

  const user = useSelector(state => state.auth.user)

  const nameRef = useRef(null)
  const aliasRef = useRef(null)
  const descRef = useRef(null)

  const values = { name, alias, description, district: user.district }

  const handleSubmit = () => {
    if (!name) return

    onClose()
    onCreate(values)
  }

  return (
    <Stack>
      <Text as='b'>Create New Group</Text>
      <Input
        id='new-group-name'
        placeholder='Name (Required)'
        ref={nameRef}
        value={name}
        onChange={e => setName(e.target.value)}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault()
            aliasRef.current?.focus()
          }
        }}
        maxLength={100}
      />
      <Input
        id='new-group-alias'
        placeholder='Alias'
        ref={aliasRef}
        value={alias}
        onChange={e => setAlias(e.target.value)}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault()
            descRef.current?.focus()
          }
        }}
        maxLength={100}
      />
      <Input
        id='new-group-description'
        as={Textarea}
        ref={descRef}
        placeholder='Description'
        value={description}
        onChange={e => setDescription(e.target.value)}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault()
            handleSubmit()
          }
        }}
      />
      <Flex>
        <Button onClick={onClose} variant='ghost' colorScheme='red'>
          Cancel
        </Button>
        <Spacer />
        <Button onClick={handleSubmit}>Create</Button>
      </Flex>
    </Stack>
  )
}

export default ConstituentGroupForm
