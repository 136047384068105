import React, { useCallback, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'

import NoSearchResults from './NoSearchResults'
import SearchOption from './SearchOption'
import SearchOptions from './SearchOptions'
import SearchSelect from './SearchSelect'
import SearchSelectedTags from './SearchSelectedTags'
import {
  clearConstituentOptions,
  loadConstituentOptions,
  paginateConstituentOptions,
} from '../../../redux/features/constituentsSlice'

const ConstituentSelect = ({
  onSelect = () => {},
  onRemove = () => {},
  onClear = () => {},
  districtOnly,
  initialSelected,
  inputProps,
  showTags = true,
  enableClear,
}) => {
  const dispatch = useDispatch()
  const searchLoading = useSelector(state => state.constituents.options_loading)
  const constituentOptions =
    useSelector(state => state.constituents.options) || []

  useEffect(() => {
    dispatch(clearConstituentOptions())
    dispatch(loadConstituentOptions({ value: '', districtOnly: districtOnly }))

    return () => {
      dispatch(clearConstituentOptions())
    }
  }, [])

  const debouncedHandleChange = useDebouncedCallback(e => {
    dispatch(clearConstituentOptions())
    dispatch(
      loadConstituentOptions({
        value: e.target.value,
        districtOnly: districtOnly,
      })
    )
  }, 200)

  const loadMoreResults = useCallback(() => {
    dispatch(paginateConstituentOptions())
  }, [])

  const filterOptions = (searchTerm, selectedOptions) => {
    let unselectedOptions = constituentOptions.filter(
      ({ id }) => !selectedOptions.find(({ value }) => value.id === id)
    )

    if (searchTerm)
      unselectedOptions = unselectedOptions.filter(({ full_name }) =>
        full_name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    if (unselectedOptions.length === 0) return null
    return unselectedOptions
  }

  return (
    <SearchSelect>
      <SearchOptions
        loadMoreOptions={loadMoreResults}
        onClear={onClear}
        onSearch={debouncedHandleChange}
        enableClear={enableClear}
        isLoading={searchLoading}
        initialSelected={initialSelected}
        inputProps={inputProps}
      >
        {({ searchTerm, selectedOptions }) =>
          filterOptions(searchTerm, selectedOptions)?.map(
            (constituent, index) => (
              <SearchOption
                key={`constituent-option-${index}`}
                option={{ label: constituent.full_name, value: constituent }}
                onSelect={onSelect}
              >
                {constituent.full_name}
              </SearchOption>
            )
          ) || <NoSearchResults> No constituent found </NoSearchResults>
        }
      </SearchOptions>
      {showTags && <SearchSelectedTags onRemove={onRemove} />}
    </SearchSelect>
  )
}

export default ConstituentSelect
