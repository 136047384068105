import React from 'react'

import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import {
  Button,
  Collapse,
  Flex,
  IconButton,
  Spacer,
  useBoolean,
  useColorModeValue,
  useMediaQuery,
} from '@chakra-ui/react'

const ShowHideFormField = ({
  heading,
  field,
  children,
  buttonVariant,
  mt,
  defaultShow,
}) => {
  const [showField, setShowField] = useBoolean(!!defaultShow)
  const [hovered, setHovered] = useBoolean(false)
  const [isLargerThanSm] = useMediaQuery('(min-width: 30em)')

  const hoverButtonBg = useColorModeValue('gray.200', 'gray.600')
  return (
    <>
      <Flex
        mb={mt}
        direction='row'
        align='center'
        onClick={setShowField.toggle}
        cursor='pointer'
        onMouseEnter={setHovered.on}
        onMouseLeave={setHovered.off}
      >
        {heading}
        <Spacer />
        {buttonVariant === 'text' && isLargerThanSm ? (
          <Button variant='link'>
            {showField ? 'Hide' : 'Show'} {field}
          </Button>
        ) : (
          <IconButton
            icon={showField ? <ChevronUpIcon /> : <ChevronDownIcon />}
            variant='unstyled'
            bg={hovered && hoverButtonBg}
            borderRadius='full'
          />
        )}
      </Flex>
      <Collapse in={showField} p={0} m={0}>
        {children}
      </Collapse>
    </>
  )
}

export default ShowHideFormField
