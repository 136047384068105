import React from 'react'

import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  Show,
  Spacer,
  Stack,
  Tag,
  Text,
  Tooltip,
  Wrap,
  WrapItem,
  useColorModeValue,
} from '@chakra-ui/react'
import { UserIcon } from '@heroicons/react/24/outline'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { formatContactInfo } from '../../casework/casework/caseworkForm/utils'
import { AddressText } from '../../ui'
import { isAddressEmpty, renderTextLines } from '../../utils'

const Section = ({ label = '', children }) => (
  <Box borderLeft='2px solid #ccccddaa ' pl={2}>
    <Text variant='subheading-blue' opacity={0.8} casing='initial'>
      {label}
    </Text>
    <Box>{children}</Box>
  </Box>
)

const SectionHeading = ({ children }) => (
  <Badge variant='unstyled' p={0} fontSize='sm' color='gray.500'>
    {children}
  </Badge>
)

const ConstituentCard = ({
  constituent,
  index,
  variant,
  hideOpenConstituent,
  showDescriptions = true,
  cardStyles,
}) => {
  const navigate = useNavigate()
  const user = useSelector(state => state.auth.user)
  const profileUrl = `/directory/constituents/${constituent.id}`
  const altBg = useColorModeValue('lightBlue', 'blue.800')

  const tagStyles = {
    variant: 'outline',
    colorScheme: 'blue',
    size: 'md',
  }

  const wrapStyles = {
    spacing: 1,
    pt: 1,
  }

  const showHomeAddress =
    constituent.home_address && !isAddressEmpty(constituent.home_address)

  const showBusinessAddress =
    constituent.business_address &&
    !isAddressEmpty(constituent.business_address)

  const contactInfo = formatContactInfo(constituent.contact_info)

  if (constituent.email)
    contactInfo.unshift({
      contact_type: 'Primary Email',
      contact_data: constituent.email,
    })

  return (
    <Card
      overflow='hidden'
      bg={index % 2 === 0 && altBg}
      sx={{ ...cardStyles }}
    >
      <CardBody>
        <Flex>
          <Stack spacing={0}>
            <HStack>
              <Icon as={UserIcon} fontSize='xl' />
              <Heading fontSize='2xl'>
                {constituent.full_name ||
                  constituent.first_name + ' ' + constituent.last_name}
              </Heading>
            </HStack>
            <Tooltip
              label={constituent.title?.length > 60 && constituent.title}
            >
              <Text variant='subheading-gray' fontSize='sm'>
                {constituent.title?.length > 60
                  ? `${constituent.title?.slice(0, 60)}...`
                  : constituent.title}
              </Text>
            </Tooltip>
          </Stack>
          <Spacer />
          {!hideOpenConstituent && (
            <Box align='end'>
              <Button
                onClick={() => {
                  if (variant === 'selected') window.open(profileUrl, '_blank')
                  else navigate(profileUrl)
                }}
                size={{ base: 'sm', sm: 'md' }}
                variant='outline'
              >
                <Show below='sm'>View</Show>
                <Show above='sm'>View Constituent</Show>
              </Button>
              <Show above='sm'>
                {variant !== 'selected' && (
                  <Tooltip label='Open in new tab'>
                    <IconButton
                      icon={<ExternalLinkIcon />}
                      variant='unstyled'
                      onClick={() => window.open(profileUrl, '_blank')}
                    />
                  </Tooltip>
                )}
              </Show>
            </Box>
          )}
        </Flex>

        <Flex direction={{ base: 'column', sm: 'row' }}>
          <Box minW='50%'>
            <SectionHeading>Contact Information</SectionHeading>
            <Stack>
              {contactInfo.length === 0 ? (
                <Section>No emails or phone numbers provided</Section>
              ) : (
                <Stack>
                  {contactInfo?.map((contact, index) => (
                    <Section key={index} label={contact.contact_type}>
                      <Text>
                        {contact.contact_data}&nbsp;
                        {showDescriptions &&
                          contact.description &&
                          `(${contact.description})`}
                      </Text>
                    </Section>
                  ))}
                </Stack>
              )}
            </Stack>
          </Box>
          <Spacer />
          <Box minW='50%'>
            {(showHomeAddress || showBusinessAddress) && (
              <Box>
                <SectionHeading>Address Information</SectionHeading>
                <Stack>
                  {showHomeAddress && (
                    <Section label='Home Address'>
                      <AddressText address={constituent.home_address} />
                    </Section>
                  )}

                  {showBusinessAddress && (
                    <Section label='Business Address'>
                      <AddressText address={constituent.business_address} />
                    </Section>
                  )}
                  {constituent.address &&
                    !isAddressEmpty(constituent.address) && (
                      <Section label='Business Address'>
                        <AddressText address={constituent.address} />
                      </Section>
                    )}
                </Stack>
              </Box>
            )}
          </Box>
        </Flex>

        {constituent.contacts?.length > 0 && (
          <Stack>
            {constituent.contacts.map((contact, index) => (
              <Box key={index}>
                <SectionHeading> Contact: {contact.full_name}</SectionHeading>
                {contact.contact_info?.map((contact, index) => (
                  <Section key={index} label={contact.contact_type}>
                    <Text>
                      {contact.contact_data}&nbsp;
                      {showDescriptions &&
                        contact.description &&
                        `(${contact.description})`}
                    </Text>
                  </Section>
                ))}
              </Box>
            ))}
          </Stack>
        )}

        {constituent.notes && (
          <Box>
            <SectionHeading>Notes</SectionHeading>
            <Section>{renderTextLines(constituent.notes)}</Section>
          </Box>
        )}

        {constituent.languages?.length > 0 && (
          <Box>
            <SectionHeading>Languages</SectionHeading>
            <Section>
              <Wrap {...wrapStyles}>
                {constituent.languages?.map((language, index) => (
                  <WrapItem key={index}>
                    <Tag {...tagStyles}>{language}</Tag>
                  </WrapItem>
                ))}
              </Wrap>
            </Section>
          </Box>
        )}
        {constituent.tags?.length > 0 && (
          <Box>
            <SectionHeading>Tags</SectionHeading>
            <Section>
              <Wrap {...wrapStyles}>
                {constituent.tags.map((tag, index) => (
                  <WrapItem key={index}>
                    <Tag {...tagStyles}>{tag.label}</Tag>
                  </WrapItem>
                ))}
              </Wrap>
            </Section>
          </Box>
        )}
        {constituent.associated_topics?.length > 0 && (
          <Box>
            <SectionHeading>Associated Topics</SectionHeading>
            <Section>
              <Wrap {...wrapStyles}>
                {constituent.associated_topics.map((topic, index) => (
                  <WrapItem key={index}>
                    <Tooltip label={topic.issue}>
                      <Tag {...tagStyles}>{topic.name}</Tag>
                    </Tooltip>
                  </WrapItem>
                ))}
              </Wrap>
            </Section>
          </Box>
        )}
      </CardBody>
      <CardFooter>
        <Flex w='100%' direction={{ base: 'column', sm: 'row' }}>
          {/* <Text variant='subheading-gray' fontSize='sm'>
            {constituent.casework_count} casework in district
          </Text> */}
          <Spacer />
          <Text variant='subheading-gray' fontSize='sm'>
            District {constituent.district || user.district}
          </Text>
        </Flex>
      </CardFooter>
    </Card>
  )
}

export default ConstituentCard
