import { all } from 'redux-saga/effects'

import agenciesAPI from './agenciesSaga'
import authAPI from './authSaga'
import caseworkAPI from './caseworkSaga'
import constituentAPI from './constituentsSaga'
import directoryAPI from './directorySaga'
import groupsAPI from './groupsSaga'
import homeAPI from './homeSaga'
import profileAPI from './profileSaga'
import reportsAPI from './reportSaga'
import staffAPI from './staffSaga'
import toolsAPI from './toolsSaga'

export default function* rootSaga() {
  yield all([
    authAPI(),
    caseworkAPI(),
    constituentAPI(),
    groupsAPI(),
    staffAPI(),
    directoryAPI(),
    profileAPI(),
    toolsAPI(),
    homeAPI(),
    agenciesAPI(),
    reportsAPI(),
  ])
}
