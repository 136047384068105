import { call, put, takeLatest } from 'redux-saga/effects'

import backendAPI from '../axiosConfig'
import { loginSuccess as updateUserState } from '../features/authSlice'
import { updateProfile, setErrors } from '../features/profileSlice'

function* updateProfileSaga(action) {
  try {
    const { id, values, callback } = action.payload

    const response = yield call(() =>
      backendAPI.patch(`/api/staff/${id}/`, values)
    )
    const { user, district, role } = response.data
    // updateUserState is updating the store after updating the DB to avoid unnecessary backend call
    yield put(updateUserState({ ...user, district: district, role: role }))
    yield call(callback)
  } catch (error) {
    console.error(error)
    yield put(setErrors())
  }
}

function* profileAPI() {
  yield takeLatest(updateProfile, updateProfileSaga)
}

export default profileAPI
