import React from 'react'

import {
  Flex,
  Icon,
  Progress,
  Spacer,
  Spinner,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import {
  CheckCircleIcon,
  DocumentTextIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'

import { truncateFilename } from '../utils'

const File = ({
  file,
  onClick,
  onButtonClick,
  isEditing,
  textColor,
  textWeight,
  bgColor,
  strikethrough,
  width,
  uploading,
  uploadComplete,
  removing,
  removeComplete,
}) => {
  if (!file) return <></>
  const fileName = file?.file_name
    ? truncateFilename(file.file_name)
    : 'File name unknown'

  const fileSize = file.file?.size || file?.file_size

  const convertFileSizeToString = bytes => {
    if (bytes < 1024) {
      // Convert to bytes
      return Math.floor(bytes) + ' B'
    } else if (bytes < 1024 * 1024) {
      // Convert to kilobytes
      return Math.floor(bytes / 1024) + ' KB'
    } else {
      // Convert to megabytes
      return Math.floor(bytes / (1024 * 1024)) + ' MB'
    }
  }

  return (
    <Tooltip
      label={fileName !== file?.file_name ? file?.file_name : ''}
      hasArrow
      openDelay={250}
    >
      <Flex
        direction='row'
        w={width}
        color={textColor}
        fontWeight={textWeight}
        bg={bgColor}
        borderRadius='lg'
        p={2}
        _hover={onClick && { cursor: 'pointer' }}
        onClick={onClick}
        flexDirection='column'
      >
        <Stack direction='row'>
          <Icon as={DocumentTextIcon} boxSize={5} />
          <Text as={strikethrough ? 's' : ''} pl={1}>
            {fileName}
          </Text>
          <Spacer />
          <Text color={textColor} fontWeight='normal'>
            {convertFileSizeToString(fileSize)}
          </Text>
          {isEditing &&
            (removing && !removeComplete ? (
              <Spinner />
            ) : removeComplete ? (
              <Icon as={CheckCircleIcon} boxSize={5} />
            ) : (
              <Icon
                as={TrashIcon}
                boxSize={5}
                _hover={{ cursor: 'pointer ' }}
                onClick={onButtonClick}
              />
            ))}
        </Stack>
        {(uploading || uploadComplete) && (
          <Progress
            borderRadius='xl'
            size='xs'
            colorScheme='teal'
            isIndeterminate={uploading && !uploadComplete}
            value={uploadComplete ? 100 : undefined}
          />
        )}
      </Flex>
    </Tooltip>
  )
}

export default File
