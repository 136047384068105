import React from 'react'

import {
  Box,
  useDisclosure,
  Stack,
  useColorModeValue,
  Flex,
} from '@chakra-ui/react'
import {
  DocumentPlusIcon,
  MagnifyingGlassCircleIcon,
} from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'

import {
  AssignedCasework,
  QuickSearch,
  ActionButton,
  WelcomeBanner,
  PasswordChangeAlert,
} from '../home'
import { PatchNotes } from '../patchNotes'
// import Reminders from '../home/Reminders'

const Home = () => {
  const navigate = useNavigate()

  const {
    isOpen: quickSearchIsOpen,
    onOpen: quickSearchOnOpen,
    onClose: quickSearchOnClose,
  } = useDisclosure()

  document.title = 'Council Connect'
  const bgColor = useColorModeValue('gray.100', 'gray.900')
  return (
    <>
      <PasswordChangeAlert />
      <Flex
        h='100%'
        bgColor={bgColor}
        pt={1}
        px={10}
        py={2}
        direction={{ base: 'column-reverse', lg: 'row' }}
      >
        <Box w={{ lg: '50%' }} px={{ lg: 5 }}>
          <AssignedCasework />
        </Box>
        <Box w={{ lg: '50%' }}>
          <Stack>
            {/* <Reminders /> */}

            <WelcomeBanner />

            <Stack>
              <ActionButton
                onClick={quickSearchOnOpen}
                icon={MagnifyingGlassCircleIcon}
              >
                Quick Search
              </ActionButton>
              <ActionButton
                onClick={() => navigate('/casework/create')}
                icon={DocumentPlusIcon}
              >
                Create Casework
              </ActionButton>
            </Stack>
            <Box
              position={{ lg: 'absolute' }}
              bottom={{ lg: 0 }}
              right={{ lg: 0 }}
              p={{ lg: 0 }}
              m={2}
            >
              <PatchNotes />
            </Box>
          </Stack>
        </Box>
      </Flex>

      <QuickSearch isOpen={quickSearchIsOpen} onClose={quickSearchOnClose} />
    </>
  )
}

export default Home
