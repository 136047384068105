import { put, takeLatest } from 'redux-saga/effects'

import { generateDistrictsSaga } from './directorySaga'
import {
  generateDistricts,
  setErrors,
  storeDistrictInfo,
} from '../features/toolsSlice'

function* generateDistrictsToolSaga(action) {
  const { values } = action.payload

  try {
    const address = yield generateDistrictsSaga(values)
    yield put(storeDistrictInfo(address))
  } catch (error) {
    console.error(error)
    yield put(setErrors('Error generating district from tool'))
  }
}

function* toolsAPI() {
  yield takeLatest(generateDistricts, generateDistrictsToolSaga)
}

export default toolsAPI
