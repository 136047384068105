import React from 'react'

import { Box, Select, Stack, Text, Textarea } from '@chakra-ui/react'

import { ReferralStaffField } from './fields'
import {
  intakeMethodOptions,
  referralCategoryOptions,
} from '../../../../constants'
import { FormField } from '../../../ui'
import { formatDatetime } from '../../../utils'

const IntakeInfoPage = () => {
  const currentDatetime = formatDatetime()

  return (
    <>
      <Stack gap={3}>
        <Box>
          <Text as='b'>Intake Method</Text>
          <FormField
            field='intake_method'
            inputType={Select}
            options={intakeMethodOptions}
          />
        </Box>
        <Box>
          <Text as='b'>Reference Numbers</Text>
          <FormField field='reference_numbers' inputType={Textarea} />
        </Box>
        <Box>
          <Text as='b'>Referral Staff</Text>
          <ReferralStaffField />
        </Box>
        <Box>
          <Text as='b'>Referral Category</Text>
          <FormField
            field='referral_category'
            inputType={Select}
            placeholder='Select category of referral staff'
            options={referralCategoryOptions}
          />
        </Box>
        <Box>
          <Text as='b'>Additional Constituent Comments</Text>
          <FormField
            field='comments'
            placeholder='Record any comments from the constituent'
            inputType={Textarea}
          />
        </Box>
        <Box>
          <Stack direction='row'>
            <Stack w='100%'>
              <Text as='b'>Opened At</Text>
              <FormField
                field='opened_at'
                type='datetime-local'
                otherProps={{
                  max: currentDatetime,
                }}
              />
            </Stack>
            <Stack w='100%'>
              <Text as='b'>Closed At</Text>
              <FormField
                field='closed_at'
                type='datetime-local'
                otherProps={{ max: currentDatetime }}
              />
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </>
  )
}

export default IntakeInfoPage
