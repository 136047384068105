import React, { useCallback, useEffect } from 'react'

import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'

import NoSearchResults from './NoSearchResults'
import SearchOption from './SearchOption'
import SearchOptions from './SearchOptions'
import SearchSelect from './SearchSelect'
import {
  clearGroupOptions,
  loadGroupOptions,
  paginateGroupOptions,
} from '../../../redux/features/groupsSlice'
import CreateGroupModal from '../forms/CreateGroupModal'

const ConstituentGroupSelect = ({
  onSelect = () => {},
  onClear = () => {},
  initialSelected,
  dropdownHeight,
  inputProps = {},
}) => {
  const dispatch = useDispatch()

  const groupOptions = useSelector(state => state.groups.options)
  const searchLoading = useSelector(state => state.groups.options_loading)

  // Modal Disclosure
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    dispatch(clearGroupOptions())
    dispatch(loadGroupOptions({ value: '' }))
  }, [])

  const debouncedHandleChange = useDebouncedCallback(e => {
    dispatch(clearGroupOptions())
    dispatch(loadGroupOptions({ value: e.target.value }))
  }, 500)

  const loadMoreResults = useCallback(() => {
    dispatch(paginateGroupOptions())
  }, [])

  const filterOptions = useCallback(
    (searchTerm, selectedOptions) => {
      let options = groupOptions.filter(
        group => !selectedOptions.find(({ value }) => value.id === group.id)
      )

      if (searchTerm) {
        let lowerSearch = searchTerm.toLowerCase()
        options = options.filter(
          group =>
            group.name.toLowerCase().includes(lowerSearch) ||
            group.alias.toLowerCase().includes(lowerSearch)
        )
      }

      if (options.length === 0) return null
      return options
    },
    [groupOptions]
  )

  return (
    <>
      <Flex direction={{ base: 'column', sm: 'row' }} w='100%' align='center'>
        <Box w='100%'>
          <SearchSelect>
            <SearchOptions
              loadMoreOptions={loadMoreResults}
              onClear={onClear}
              onSearch={debouncedHandleChange}
              initialSelected={initialSelected}
              isLoading={searchLoading}
              dropdownHeight={dropdownHeight}
              inputProps={{
                placeholder: 'Select group(s) from dropdown',
                ...inputProps,
              }}
            >
              {({ searchTerm, selectedOptions }) =>
                filterOptions(searchTerm, selectedOptions)?.map(
                  (group, index) => (
                    <SearchOption
                      key={`group-option-${index}`}
                      option={{ label: group.name, value: group }}
                      onSelect={onSelect}
                    >
                      {group.name}
                    </SearchOption>
                  )
                ) || <NoSearchResults>No groups found</NoSearchResults>
              }
            </SearchOptions>
          </SearchSelect>
        </Box>
        <Button
          size={{ base: 'md', sm: 'lg' }}
          onClick={onOpen}
          variant='outline'
          m={2}
        >
          Create new group
        </Button>
      </Flex>
      <CreateGroupModal
        isOpen={isOpen}
        onClose={onClose}
        onCreate={group => onSelect(group)}
      />
    </>
  )
}

export default ConstituentGroupSelect
