import React, { useCallback, useMemo } from 'react'

import { useFormikContext } from 'formik'

import { ConstituentSelect } from '../../../../ui/select'

const ConstituentField = () => {
  const { values, setFieldValue } = useFormikContext()

  const onSelect = useCallback(
    option => setFieldValue('constituent', option),
    [setFieldValue, values.constituent]
  )
  const onClear = useCallback(
    () => setFieldValue('constituent', null),
    [setFieldValue]
  )

  const initialSelected = useMemo(
    () =>
      values.constituent
        ? [{ label: values.constituent.full_name, value: values.constituent }]
        : [],
    [values.constituent]
  )

  return (
    <ConstituentSelect
      onSelect={onSelect}
      onRemove={onClear}
      onClear={onClear}
      initialSelected={initialSelected}
      districtOnly={true}
      inputProps={{
        id: 'constituent-select',
        placeholder: 'Search for an existing constituent *',
      }}
    />
  )
}

export default ConstituentField
