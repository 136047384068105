// This function compares two objects and returns true if objects are the same and false otherwise
export function isEqualObject(obj1, obj2) {
  if (!obj1 && !obj2) {
    return true
  }

  if (!obj1 || !obj2) {
    return false
  }

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false
    }
  }

  return true
}

export const isMatchingObject = (lhs, rhs, keys) =>
  lhs && rhs && keys.every(key => lhs[key] === rhs[key])

export function capitalize(str) {
  if (typeof str !== 'string' || str.length === 0) {
    return ''
  }
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function getFilterQueryString(filters) {
  if (!filters) return ''

  return Object.keys(filters)
    .map(key => {
      let value = filters[key]
      if (typeof value !== 'string' && Array.isArray(value)) {
        value = value.map(x =>
          typeof x === 'string'
            ? x
            : typeof x === 'boolean'
            ? x.toString()
            : x.id
        )
        if (key === 'agencies') value = value.join(';')
        else value = value.join(',')
      }
      return `${key}=${value}`
    })
    .join('&')
}

/** This function calculates the number of milliseconds
 * Modes:
 * - s: ms to seconds (default)
 * - m: ms to minutes
 * - h: ms to hours
 */
export function calculateMS(number, type = 's') {
  const s = 1000
  const m = s * 60
  const h = m * 60
  if (type === 'm') {
    return m * number
  } else if (type === 'h') {
    return h * number
  }

  return s * number
}

export function formatDateToString(date) {
  const year = date.getFullYear().toString() // Get full year
  const month = (date.getMonth() + 1).toString().padStart(2, '0') // Get month (+1 because months are zero-indexed) and pad with leading zero if necessary
  const day = date.getDate().toString().padStart(2, '0') // Get day and pad with leading zero if necessary

  return `${year}-${month}-${day}`
}

export function sortValues(values, sortCategory, sortAsc) {
  return [...values].sort((a, b) => {
    const valueA = a[sortCategory]
    const valueB = b[sortCategory]

    // Handle null, undefined, or empty string values
    if (valueA === null || valueA === undefined || valueA === '') return 1
    if (valueB === null || valueB === undefined || valueB === '') return -1

    // Convert to lowercase if values are strings for case-insensitive comparison
    const valueAToCompare =
      typeof valueA === 'string' ? valueA.toLowerCase() : valueA
    const valueBToCompare =
      typeof valueB === 'string' ? valueB.toLowerCase() : valueB

    if (sortAsc) {
      return valueAToCompare < valueBToCompare
        ? -1
        : valueAToCompare > valueBToCompare
        ? 1
        : 0
    } else {
      return valueBToCompare < valueAToCompare
        ? -1
        : valueBToCompare > valueAToCompare
        ? 1
        : 0
    }
  })
}

export function downloadExcelFile(fileData, fileName) {
  const blob = new Blob([fileData], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })

  downloadFile(blob, fileName)
}

export function downloadFile(file, fileName) {
  const url =
    typeof file === 'object' && typeof file !== 'string'
      ? window.URL.createObjectURL(file)
      : file

  const a = document.createElement('a')
  a.href = url
  a.download = fileName

  document.body.appendChild(a)
  a.click()

  window.URL.revokeObjectURL(url)
  document.body.removeChild(a)
}
