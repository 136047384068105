import { React } from 'react'

import {
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  useBoolean,
  Tooltip,
} from '@chakra-ui/react'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import { useFormikContext } from 'formik'

// use this component to quickly add password input fields with a visibility toggle.
// 'onChange' & 'value' are required fields, the rest are optional & have default values:
// - 'id' & 'name' defaults to "password"
// - 'placeholder' defaults to "Enter password"
// - 'disableVisibility' defaults to undefined
const PasswordInput = ({
  id = 'password',
  name = 'password',
  placeholder = 'Enter password',
  disableVisibility = false,
}) => {
  const [show, setShow] = useBoolean()
  const { handleChange, values } = useFormikContext()
  const [hovered, setHovered] = useBoolean()

  const tooltipLabel = show ? 'Hide password' : 'Show Password'
  const icon = hovered ^ show ? <EyeIcon /> : <EyeSlashIcon /> // xor
  return (
    <InputGroup>
      <Input
        id={id}
        name={name}
        type={show ? 'text' : 'password'}
        size={{ base: 'md', md: 'lg' }}
        variant='filled'
        placeholder={placeholder}
        onChange={handleChange}
        value={values[name]}
      />
      <InputRightElement h={'full'} w={'11'} paddingRight={2}>
        <Tooltip label={tooltipLabel}>
          <IconButton
            variant='unstyled'
            colorScheme={show ? 'red' : 'blue'}
            size='sm'
            color='gray.400'
            onClick={setShow.toggle}
            visibility={disableVisibility ? 'hidden' : 'visible'}
            icon={icon}
            onMouseEnter={setHovered.on}
            onMouseLeave={setHovered.off}
          />
        </Tooltip>
      </InputRightElement>
    </InputGroup>
  )
}

export default PasswordInput
