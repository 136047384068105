import * as Yup from 'yup'

export const initialStaffValues = {
  user: {
    first_name: '',
    middle_name: '',
    last_name: '',
    preferred_name: '',
    suffix: '',
    pronouns: '',
    email: '',
  },
  role: '',
  district: '',
}

export const staffValidationSchema = Yup.object().shape({
  user: Yup.object().shape({
    first_name: Yup.string().required('First name is required'),
    middle_name: Yup.string(),
    last_name: Yup.string().required('Last name is required'),
    preferred_name: Yup.string(),
    suffix: Yup.string(),
    pronouns: Yup.string(),
    email: Yup.string().required('Staff must have an email'),
  }),
  role: Yup.string().required('Staff must be assigned a role'),
  district: Yup.string().required('Staff must be assigned a district'),
})
