import React, { useCallback } from 'react'

import { Button } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { useDispatch } from 'react-redux'

import ConstituentFormInputs from './ConstituentFormInputs'
import {
  initialAddressValues,
  initialConstituentValues,
} from '../../../../constants'
import { constituentValidationSchema } from '../../../../constants'
import {
  createConstituent,
  updateConstituent,
} from '../../../../redux/features/constituentsSlice'
import { formatContactInfo } from '../../../casework/casework/caseworkForm/utils'
import { isAddressEmpty } from '../../../utils'

const ConstituentForm = ({
  isEditing,
  constituentValues = initialConstituentValues,
  handleFormSubmit,
}) => {
  const dispatch = useDispatch()

  /** Prevent uncontrolled value for constituent address*/
  const initialValues = Object.assign({}, constituentValues)
  if (!initialValues.home_address) {
    initialValues.home_address = initialAddressValues
  }
  if (!initialValues.business_address) {
    initialValues.business_address = initialAddressValues
  }

  const handleSubmit = useCallback(
    (values, { setErrors, setSubmitting }) => {
      setSubmitting(true)
      const formattedValues = { ...values }

      formattedValues.contact_info = formatContactInfo(
        formattedValues.contact_info
      )

      if (isAddressEmpty(formattedValues.home_address)) {
        formattedValues.home_address = null
      }
      if (isAddressEmpty(formattedValues.business_address)) {
        formattedValues.business_address = null
      }

      if (!formattedValues.home_address && formattedValues.business_address)
        formattedValues.primary_address = 'business'

      if (!formattedValues.business_address && formattedValues.home_address)
        formattedValues.primary_address = 'home'

      const callbackSuccess = id => {
        handleFormSubmit(id)
        setSubmitting(false)
      }

      const callbackFailure = errors => {
        setSubmitting(false)
        setErrors(errors)
      }

      if (isEditing) {
        dispatch(
          updateConstituent({
            id: formattedValues.id,
            values: formattedValues,
            callbackSuccess,
            callbackFailure,
          })
        )
      } else {
        dispatch(
          createConstituent({
            values: formattedValues,
            callbackSuccess,
            callbackFailure,
          })
        )
      }
    },
    [isEditing, handleFormSubmit]
  )

  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        onSubmit={handleSubmit}
        validationSchema={constituentValidationSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <ConstituentFormInputs />

            <Button isLoading={isSubmitting} type='submit' mt={5} width='100%'>
              {isEditing ? 'Save Constituent' : 'Create Constituent'}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ConstituentForm
