import React from 'react'

import { Icon } from '@chakra-ui/react'

export const MenuToggleDropdownIcon = props => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 59 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M57.0391 2L1.99906 2'
      stroke={props.stroke ? props.stroek : 'currentColor'}
      strokeWidth='3'
      strokeLinecap='round'
    />
    <path
      d='M57.0391 17.3604L1.99906 17.3604'
      stroke={props.stroke ? props.stroek : 'currentColor'}
      strokeWidth='3'
      strokeLinecap='round'
    />
    <path
      d='M30.1602 34L2.64016 34'
      stroke={props.stroke ? props.stroek : 'currentColor'}
      strokeWidth='3'
      strokeLinecap='round'
    />
  </svg>
)

export const EnvelopeNotificationIcon = props => (
  <Icon viewBox='0 0 24 24' boxSize={props.size || '24px'} {...props}>
    <path
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
      stroke='currentColor'
      d='M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75'
    />
  </Icon>
)

export const MagnifyingGlass = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={props.width}
    color={props.color}
    fill='none'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    className='w-6 h-6'
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z'
    />
  </svg>
)

export const ArrowCircleRight = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={props.width}
    fill='none'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    className='w-6 h-6'
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
    />
  </svg>
)

export const FilterHorizontal = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={props.width}
    fill='none'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    className='w-6 h-6'
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75'
    />
  </svg>
)

export const ArrowDownloadDownTray = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={props.width}
    fill='none'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    className='w-6 h-6'
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3'
    />
  </svg>
)

export const NoResultsIcon = () => (
  <svg
    width='328'
    height='316'
    viewBox='0 0 328 316'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='152' cy='156' r='108.5' fill='#EEEDF0' stroke='#BEBFC6' />
    <path
      d='M236 88L131 133.829V181.148L239 220C261.5 189 273 134.5 236 88Z'
      fill='white'
    />
    <path
      d='M213.5 245L56 171.5L99 171L133.5 182L239 220C231.5 230.5 225 236.5 213.5 245Z'
      fill='url(#paint0_linear_210_83)'
    />
    <path
      d='M238.833 220.471L239.305 220.638L239.638 219.695L239.167 219.529L238.833 220.471ZM131.333 182.471L238.833 220.471L239.167 219.529L131.667 181.529L131.333 182.471Z'
      fill='#E8EAEC'
    />
    <circle cx='152' cy='156' r='108.5' stroke='#BEBFC6' />
    <path d='M80 143.5H39V171.283L80 171V143.5Z' fill='#E0E2E4' />
    <path d='M98.5 143.5L125 136.5V180L98.5 171V143.5Z' fill='white' />
    <path d='M125 181.5V134H131.5V181.5H125Z' fill='#E0E2E4' />
    <path d='M33 145L38 144V171.152L33 169.5V145Z' fill='white' />
    <rect x='80' y='144' width='18' height='27' fill='white' />
    <path
      d='M32.5 160.5L38.5 162.5H79.8559H98.5L125 166.5H131.5V181.5H125V180L98.5 171H79.8559H38.5L32.5 169.5V160.5Z'
      fill='#EDEDF0'
    />
    <path d='M150 129V186' stroke='#BEBFC6' strokeLinecap='round' />
    <path d='M194 108H242V208H209.34' stroke='#BEBFC6' strokeLinecap='round' />
    <path d='M149.5 108.5V125.5L189.5 108.5H149.5Z' fill='#F1F3F4' />
    <path
      d='M149.5 125.5V108.5H189.5M149.5 125.5L189.5 108.5M149.5 125.5L131 134M189.5 108.5L236 88.5'
      stroke='#E8EAEC'
    />
    <path d='M150 190V208H201' stroke='#E8EAEC' strokeLinecap='round' />
    <rect
      x='83.5'
      y='153.5'
      width='12'
      height='6'
      rx='3'
      fill='white'
      stroke='#BEBFC6'
    />
    <path
      d='M38.5 143.5V171.5M38.5 143.5L32.5 145V169.5L38.5 171.5M38.5 143.5H80M38.5 171.5H80M80 143.5H98.5V171.5H80M80 143.5V171.5M43 150H75.5M43 155.5H75.5M43 160.5H75.5M43 166H75.5'
      stroke='#BEBFC6'
      strokeLinejoin='round'
    />
    <circle cx='92.5' cy='156.5' r='1.5' fill='#BEBFC6' />
    <path
      d='M98.5 143.5L125 136M125 136V180.5M125 136V134H131.5V182H125V180.5M125 180.5L98.5 171.5'
      stroke='#BEBFC6'
    />
    <path d='M176.722 180L175.222 181' stroke='black' strokeLinecap='round' />
    <path
      d='M184.722 147.5C188.827 134.855 202.755 133.688 207.722 148C200.222 156.5 189.222 153.5 184.722 147.5Z'
      fill='#E0E2E6'
    />
    <circle cx='196.222' cy='138' r='15.5' stroke='#BEBFC6' />
    <path
      d='M201.722 131.5C201.722 134.852 199.223 137.5 196.222 137.5C193.222 137.5 190.722 134.852 190.722 131.5C190.722 128.148 193.222 125.5 196.222 125.5C199.223 125.5 201.722 128.148 201.722 131.5Z'
      fill='#E0E2E6'
      stroke='#BFC2CC'
    />
    <path
      d='M184.222 147.5C190.222 134.5 201.222 133.5 208.222 148'
      stroke='#BFC2CC'
    />
    <circle cx='178.222' cy='182' r='2.5' stroke='#BEBFC6' />
    <path
      d='M186.222 180C187 180 187 183.5 187 183.5M187.222 184.5L187 183.5M187.722 178.5C193.023 178.24 192.372 179.387 188.222 182.5M188.222 182.5L192.222 184M188.222 182.5L187 183.5M193.222 182H194.222M194.222 182C194.222 182 197.329 182.276 198.222 182C199.115 181.724 198.222 179.5 196.722 179.5C195.222 179.5 194.509 180.744 194.222 182ZM194.222 182C194.222 182 194.722 184 196.722 184.5C198.722 185 199.222 183.5 199.222 183.5M203.722 180C203.722 180 203.222 179 202.222 179C201.222 179 200.222 180 200.722 180.5C201.222 181 205.222 184 204.722 184.5C204.222 185 203.722 185.5 203.222 185.5C202.722 185.5 201.722 185 201.722 184.5C201.722 184 201.722 183 201.722 183M206.222 179.5C206.222 179.5 205.722 181 206.222 183.5C206.722 186 209.222 184 209.722 183.5C210.222 183 210.722 180 210.222 180M212.722 177C213.222 176.5 213.222 183.5 213.222 183.5M216.722 176.5C216.563 179.749 216.896 181.475 217.722 184.5M214.222 181C216.543 181.17 217.929 180.31 220.722 180M224.722 180C223.722 179 222.722 179 222.222 179C221.722 179 220.722 179.5 221.222 180.5C221.722 181.5 224.722 183 225.222 184.5C225.722 186 221.722 185 221.722 184.5C221.722 184 222.222 183.5 222.222 183.5M169.722 163.5H219.722M179.222 158.5H212.722'
      stroke='#BEBFC6'
      strokeLinecap='round'
    />
    <path
      d='M167.222 183.5C167.222 183.5 166.722 178.5 167.222 179C167.722 179.5 172.722 184 173.222 183.5C173.722 183 173.222 178 173.222 178'
      stroke='#BEBFC6'
      strokeLinecap='round'
    />
    <circle cx='12.5' cy='105.5' r='2.75' stroke='#BCBEC7' strokeWidth='1.5' />
    <circle cx='94.5' cy='285.5' r='2.75' stroke='#BCBEC7' strokeWidth='1.5' />
    <circle cx='146.5' cy='303.5' r='2.75' stroke='#BCBEC7' strokeWidth='1.5' />
    <circle cx='324.5' cy='102.5' r='2.75' stroke='#BCBEC7' strokeWidth='1.5' />
    <circle cx='288.5' cy='88.5' r='2.75' stroke='#BCBEC7' strokeWidth='1.5' />
    <circle cx='48.5' cy='59.5' r='2.75' stroke='#BCBEC7' strokeWidth='1.5' />
    <circle cx='320.5' cy='153.5' r='2.75' stroke='#BCBEC7' strokeWidth='1.5' />
    <circle cx='239' cy='283' r='2.25' stroke='#BCBEC7' strokeWidth='1.5' />
    <path
      d='M118 24C118.394 24 118.784 24.0776 119.148 24.2284C119.512 24.3791 119.843 24.6001 120.121 24.8787C120.4 25.1573 120.621 25.488 120.772 25.852C120.922 26.2159 121 26.606 121 27C121 27.394 120.922 27.7841 120.772 28.1481C120.621 28.512 120.4 28.8427 120.121 29.1213C119.843 29.3999 119.512 29.6209 119.148 29.7716C118.784 29.9224 118.394 30 118 30'
      stroke='#BDBFC5'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M6 176C6.39397 176 6.78407 176.078 7.14805 176.228C7.51203 176.379 7.84275 176.6 8.12132 176.879C8.3999 177.157 8.62087 177.488 8.77164 177.852C8.9224 178.216 9 178.606 9 179C9 179.394 8.9224 179.784 8.77164 180.148C8.62087 180.512 8.3999 180.843 8.12132 181.121C7.84274 181.4 7.51203 181.621 7.14805 181.772C6.78407 181.922 6.39397 182 6 182'
      stroke='#BDBFC5'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M193.25 22.9946C192.857 23.0112 192.464 22.9502 192.094 22.8149C191.724 22.6796 191.384 22.4728 191.094 22.2062C190.804 21.9396 190.569 21.6185 190.403 21.2613C190.237 20.904 190.143 20.5175 190.127 20.1239C190.11 19.7302 190.171 19.3372 190.306 18.9672C190.442 18.5972 190.648 18.2574 190.915 17.9674C191.182 17.6773 191.503 17.4425 191.86 17.2766C192.217 17.1106 192.604 17.0166 192.997 17'
      stroke='#BDBFC5'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M273.25 235.995C272.857 236.011 272.464 235.95 272.094 235.815C271.724 235.68 271.384 235.473 271.094 235.206C270.804 234.94 270.569 234.619 270.403 234.261C270.237 233.904 270.143 233.517 270.127 233.124C270.11 232.73 270.171 232.337 270.306 231.967C270.442 231.597 270.648 231.257 270.915 230.967C271.182 230.677 271.503 230.443 271.86 230.277C272.217 230.111 272.604 230.017 272.997 230'
      stroke='#BDBFC5'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <circle cx='38' cy='29' r='2' fill='#BCBEC7' />
    <circle cx='294' cy='144' r='2' fill='#BCBEC7' />
    <circle cx='203' cy='302' r='2' fill='#BCBEC7' />
    <circle cx='97' cy='2' r='2' fill='#BCBEC7' />
    <circle cx='302' cy='216' r='2' fill='#BCBEC7' />
    <circle cx='67' cy='292' r='2' fill='#BCBEC7' />
    <circle cx='29' cy='208' r='2' fill='#BCBEC7' />
    <circle cx='196' cy='280' r='2' fill='#BCBEC7' />
    <circle cx='298' cy='60' r='2' fill='#BCBEC7' />
    <circle cx='171.5' cy='32.5' r='1.5' fill='#EEEEEF' />
    <circle cx='31.5' cy='97.5' r='1.5' fill='#EEEEEF' />
    <circle cx='1.5' cy='145.5' r='1.5' fill='#EEEEEF' />
    <circle cx='19.5' cy='231.5' r='1.5' fill='#EEEEEF' />
    <circle cx='278.5' cy='263.5' r='1.5' fill='#EEEEEF' />
    <circle cx='174.5' cy='311.5' r='1.5' fill='#EEEEEF' />
    <circle cx='117.5' cy='314.5' r='1.5' fill='#EEEEEF' />
    <circle cx='213.5' cy='23.5' r='1.5' fill='#EEEEEF' />
    <circle cx='287.5' cy='192.5' r='1.5' fill='#EEEEEF' />
    <rect x='238' y='41' width='3' height='2' rx='1' fill='#BFBFC9' />
    <rect x='58' y='261' width='3' height='2' rx='1' fill='#BFBFC9' />
    <rect
      x='38'
      y='247'
      width='3'
      height='2'
      rx='1'
      transform='rotate(-90 38 247)'
      fill='#BFBFC9'
    />
    <rect
      x='38'
      y='280'
      width='3'
      height='2'
      rx='1'
      transform='rotate(-90 38 280)'
      fill='#BFBFC9'
    />
    <path
      d='M259.977 34.1631L261.391 38.6315C261.538 39.097 261.903 39.4617 262.368 39.609L266.837 41.0233C267.302 41.1706 267.302 41.8294 266.837 41.9767L262.368 43.391C261.903 43.5383 261.538 43.903 261.391 44.3685L259.977 48.8369C259.829 49.3024 259.171 49.3024 259.023 48.8369L257.609 44.3685C257.462 43.903 257.097 43.5383 256.632 43.391L252.163 41.9767C251.698 41.8294 251.698 41.1706 252.163 41.0233L256.632 39.609C257.097 39.4617 257.462 39.097 257.609 38.6315L259.023 34.1631C259.171 33.6976 259.829 33.6976 259.977 34.1631Z'
      stroke='#BDBFC5'
    />
    <path
      d='M39.9767 255.163L41.391 259.632C41.5383 260.097 41.903 260.462 42.3685 260.609L46.8369 262.023C47.3024 262.171 47.3024 262.829 46.8369 262.977L42.3685 264.391C41.903 264.538 41.5383 264.903 41.391 265.368L39.9767 269.837C39.8294 270.302 39.1706 270.302 39.0233 269.837L37.609 265.368C37.4617 264.903 37.097 264.538 36.6315 264.391L32.1631 262.977C31.6976 262.829 31.6976 262.171 32.1631 262.023L36.6315 260.609C37.097 260.462 37.4617 260.097 37.609 259.632L39.0233 255.163C39.1706 254.698 39.8294 254.698 39.9767 255.163Z'
      stroke='#BDBFC5'
    />
    <path
      d='M260 7V26'
      stroke='#BDBFC5'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M260 58L260 67'
      stroke='#BDBFC5'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M39 285L39 294'
      stroke='#BDBFC5'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M277 41H285'
      stroke='#BDBFC5'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M15 262H23'
      stroke='#BDBFC5'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <defs>
      <linearGradient
        id='paint0_linear_210_83'
        x1='181'
        y1='149.5'
        x2='137.5'
        y2='240'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#878787' />
        <stop offset='1' stopColor='#D9D9D9' stopOpacity='0' />
      </linearGradient>
    </defs>
  </svg>
)

export const NoAssignmentsIcon = () => (
  <svg
    width='220'
    // height='955'
    viewBox='0 0 1244 955'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.41992 493.91L460.73 937.68L1234.18 815.4L782.71 342.72L7.41992 493.91Z'
      fill='#2F6176'
    />
    <path
      d='M784.04 2.70996L1243.71 462.39V843.68L784.04 384.01V2.70996Z'
      fill='#C5C8CE'
    />
    <path
      d='M0 110.16C4.98 110.67 727.45 7.9 783.77 0V381.3C727.45 389.19 4.98 491.96 0 491.45V110.16Z'
      fill='#C5C8CE'
    />
    <g>
      <path
        d='M75.28 100.04L75.21 136.5L75.19 147.61C75.21 147.27 75.3 146.95 75.35 146.63C75.25 147.38 75.14 148.12 75.14 148.88L74.52 481.44C243.68 457.96 650.36 400.18 759.75 384.7L760.38 48.29L760.45 10.7V3.29004C652.31 18.6 245.27 76.43 75.28 100.04Z'
        fill='#D8DADE'
      />
    </g>
    <path
      d='M821.56 16.8301L433.74 67.6401C427.94 68.4601 423.25 74.1701 423.23 80.3701L423.18 107.56L130.98 148.64C122.99 149.75 116.46 157.71 116.44 166.34L115.67 579.57C115.65 588.19 122.16 594.26 130.15 593.13L816.63 500.35C824.67 499.22 831.15 491.32 831.17 482.7L831.87 107.06L831.94 69.4801L832.02 26.6201C832.03 20.4101 827.36 16.0201 821.56 16.8301Z'
      fill='#7F9CC6'
    />
    <path
      d='M381.61 25.04C387.41 24.23 392.09 28.62 392.07 34.89L392.02 62.09L817.55 5.97004C825.59 4.84004 832.04 10.91 832.03 19.58V26.62C832.03 20.41 827.35 16.02 821.55 16.83L433.73 67.64C427.93 68.46 423.24 74.17 423.22 80.37L423.17 107.56L130.97 148.64C123.37 149.71 117.1 156.94 116.48 165.07L116.5 153.96L116.57 116.44L116.65 73.58C116.65 67.31 121.35 61.6 127.11 60.8L381.59 25.03L381.61 25.04Z'
      fill='#647C99'
    />
    <g>
      <path
        d='M472.51 162.17L472.56 134.98C472.58 128.72 467.9 124.32 462.1 125.12L207.62 160.89C201.86 161.7 197.17 167.41 197.16 173.68L197.08 216.54L197.01 254.06L196.99 265.17C197.01 264.84 197.1 264.52 197.15 264.19C197.05 264.94 196.94 265.68 196.93 266.44L196.22 649.81L866.93 559.16C874.97 558.03 881.45 550.13 881.46 541.51L882.16 165.86L882.23 128.27L882.27 108.15L472.5 162.18L472.51 162.17Z'
        fill='#D8DADE'
      />
    </g>
    <path
      d='M943.36 134.4L555.54 185.22C549.74 186.04 545.05 191.75 545.04 197.95L544.99 225.15L252.79 266.22C244.8 267.34 238.26 275.3 238.25 283.92L237.48 697.15C237.46 705.78 243.97 711.85 251.96 710.72L938.44 617.94C946.48 616.81 952.96 608.91 952.97 600.29L953.67 224.64L953.74 187.06L953.82 144.2C953.82 138 949.15 133.6 943.36 134.41V134.4Z'
      fill='#7F9CC6'
    />
    <path
      d='M503.41 142.6C509.21 141.8 513.89 146.19 513.87 152.46L513.82 179.65L939.36 123.53C947.4 122.4 953.85 128.47 953.83 137.15V144.18C953.83 137.98 949.15 133.58 943.36 134.39L555.54 185.21C549.74 186.03 545.05 191.74 545.04 197.94L544.99 225.14L252.79 266.21C245.19 267.28 238.92 274.51 238.3 282.64L238.33 271.54L238.4 234.02L238.48 191.16C238.49 184.9 243.18 179.18 248.94 178.38L503.41 142.61V142.6Z'
      fill='#647C99'
    />
    <g>
      <path
        d='M575.78 291.38L575.83 264.18C575.84 257.91 571.17 253.52 565.37 254.34L310.89 290.09C305.13 290.9 300.45 296.62 300.43 302.87L300.35 345.73L300.28 383.26L300.26 394.36C300.29 394.03 300.38 393.71 300.42 393.38C300.32 394.12 300.21 394.86 300.21 395.62L299.5 778.99L970.22 688.34C978.26 687.21 984.74 679.33 984.75 670.7L985.45 295.05L985.52 257.47L985.56 237.34L575.79 291.37L575.78 291.38Z'
        fill='#D8DADE'
      />
    </g>
    <path
      d='M1046.63 263.62L658.81 314.42C653.01 315.24 648.32 320.95 648.31 327.16L648.26 354.36L356.06 395.42C348.07 396.55 341.54 404.49 341.52 413.12L340.75 826.35C340.73 834.97 347.23 841.04 355.23 839.93L1041.71 747.14C1049.75 746.01 1056.23 738.13 1056.25 729.5L1056.95 353.86L1057.02 316.28L1057.1 273.42C1057.11 267.21 1052.44 262.81 1046.64 263.63L1046.63 263.62Z'
      fill='#7F9CC6'
    />
    <path
      d='M606.67 271.82C612.47 271 617.15 275.39 617.14 281.66L617.09 308.85L1042.63 252.75C1050.67 251.62 1057.12 257.69 1057.11 266.36V273.4C1057.11 267.19 1052.43 262.79 1046.63 263.61L658.81 314.41C653.01 315.23 648.32 320.94 648.31 327.15L648.26 354.35L356.06 395.41C348.46 396.48 342.19 403.73 341.58 411.85L341.6 400.75L341.67 363.22L341.75 320.36C341.76 314.11 346.45 308.38 352.21 307.57L606.68 271.82H606.67Z'
      fill='#647C99'
    />
    <g>
      <path
        d='M717.71 394.93L717.76 367.73C717.77 361.47 713.1 357.07 707.29 357.88L452.82 393.65C447.06 394.46 442.38 400.16 442.36 406.44L442.28 449.3L442.21 486.82L442.19 497.92C442.22 497.59 442.3 497.26 442.35 496.94C442.26 497.68 442.14 498.43 442.14 499.19L441.43 882.56L1112.14 791.9C1120.18 790.77 1126.66 782.87 1126.68 774.25L1127.38 398.6L1127.45 361.02L1127.49 340.9L717.72 394.93H717.71Z'
        fill='#D8DADE'
      />
    </g>
    <path
      d='M1188.55 367.14L800.73 417.96C794.93 418.78 790.24 424.49 790.23 430.7L790.18 457.9L497.98 498.97C489.99 500.09 483.46 508.04 483.44 516.67L482.67 929.9C482.65 938.53 489.16 944.59 497.14 943.46L1183.62 850.69C1191.66 849.56 1198.14 841.66 1198.15 833.04L1198.85 457.39L1198.92 419.81L1199 376.95C1199.01 370.75 1194.34 366.35 1188.53 367.15L1188.55 367.14Z'
      fill='#7F9CC6'
    />
    <path
      d='M748.6 375.35C754.4 374.54 759.08 378.94 759.07 385.2L759.02 412.4L1184.55 356.28C1192.59 355.15 1199.04 361.21 1199.03 369.9V376.93C1199.03 370.73 1194.36 366.33 1188.55 367.13L800.73 417.95C794.93 418.77 790.24 424.48 790.23 430.69L790.18 457.89L497.98 498.96C490.38 500.03 484.11 507.26 483.5 515.39L483.52 504.29L483.59 466.77L483.67 423.91C483.68 417.64 488.37 411.93 494.13 411.13L748.6 375.36V375.35Z'
      fill='#647C99'
    />
    <path
      d='M459.67 573.6C464.66 574.11 1187.12 471.34 1243.44 463.44V844.74C1187.12 852.64 464.66 955.4 459.67 954.9V573.61V573.6Z'
      fill='#E3E8F0'
    />
    <g>
      <path
        d='M766.35 726.85C767.73 726.99 1020.44 689.31 1035.98 687.12V741.47C1020.44 743.64 767.73 781.34 766.35 781.2V726.85Z'
        fill='#D8DADE'
      />
    </g>
    <path
      d='M747.69 714.14C749.06 714.28 1001.78 676.6 1017.31 674.42V728.76C1001.78 730.94 749.06 768.63 747.69 768.5V714.14Z'
      fill='white'
    />
    <path d='M0 113.93L459.67 573.6V954.9L0 495.22V113.93Z' fill='#E3E8F0' />
  </svg>
)
