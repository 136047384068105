import React, { useEffect, useMemo } from 'react'

import { HStack, Text } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'

import NoSearchResults from './NoSearchResults'
import SearchOption from './SearchOption'
import SearchOptions from './SearchOptions'
import SearchSelect from './SearchSelect'
import SearchSelectedTags from './SearchSelectedTags'
import { loadAgenciesData } from '../../../redux/features/agenciesSlice'

const AgencySelect = ({
  onSelect = () => {},
  onRemove = () => {},
  onClear = () => {},
  initialSelected,
  dropdownHeight,
  inputProps,
  hideTags,
}) => {
  const dispatch = useDispatch()

  const agenciesData = useSelector(state => state.agencies.data)

  useEffect(() => {
    dispatch(loadAgenciesData())
  }, [])

  const agencyNames = useMemo(
    () =>
      Object.entries(agenciesData).map(([agency_name, agency_data]) => ({
        name: agency_name,
        acronym: agency_data.agency_acronym,
      })),
    [agenciesData]
  )

  const filterAgencies = (searchTerm, selectedOptions) => {
    let lowerSearch = searchTerm.toLowerCase()

    let unselectedAgencies = agencyNames.filter(
      ({ name }) => !selectedOptions.find(({ value }) => value === name)
    )

    let options = unselectedAgencies.filter(
      ({ name, acronym }) =>
        name.toLowerCase().includes(lowerSearch) ||
        acronym?.toLowerCase().includes(lowerSearch)
    )

    if (options.length === 0) return null
    return options
  }

  return (
    <SearchSelect>
      <SearchOptions
        onClear={onClear}
        initialSelected={initialSelected}
        dropdownHeight={dropdownHeight}
        inputProps={inputProps}
      >
        {({ searchTerm, selectedOptions }) =>
          filterAgencies(searchTerm, selectedOptions)?.map(
            ({ name, acronym }, index) => (
              <SearchOption
                key={`agency-option-${index}`}
                option={{ label: name, value: name }}
                onSelect={onSelect}
              >
                <HStack>
                  <Text> {name} </Text>
                  <Text as='i' color='gray.400' pl={2}>
                    {acronym}
                  </Text>
                </HStack>
              </SearchOption>
            )
          ) || <NoSearchResults> No agency found </NoSearchResults>
        }
      </SearchOptions>
      {!hideTags && <SearchSelectedTags onRemove={onRemove} />}
    </SearchSelect>
  )
}

export default AgencySelect
