import React, { useCallback, useMemo } from 'react'

import { useFormikContext } from 'formik'

import { TopicSelect } from '../../../../ui/select'

const TopicField = () => {
  const { values, errors, setFieldValue } = useFormikContext()
  const onTopicSelect = useCallback(
    option => setFieldValue('topics', [...values.topics, option]),
    [setFieldValue, values.topics]
  )

  const onTopicRemove = useCallback(
    removeIndex =>
      setFieldValue('topics', values.topics.toSpliced(removeIndex, 1)),
    [setFieldValue, values.topics]
  )

  const onTopicClear = useCallback(
    () => setFieldValue('topics', []),
    [setFieldValue]
  )

  const selectedTopics = useMemo(
    () =>
      values.topics.map(topic => ({
        label: topic.name,
        value: topic,
      })),

    [values.topics]
  )

  return (
    <TopicSelect
      onSelect={onTopicSelect}
      onRemove={onTopicRemove}
      onClear={onTopicClear}
      initialSelected={selectedTopics}
      inputProps={{
        isInvalid: errors.topics,
        id: 'topic-select',
      }}
    />
  )
}

export default TopicField
