import React, { useState, useEffect, useRef } from 'react'

import {
  Box,
  Icon,
  Td,
  Text,
  Tooltip,
  Tr,
  useMediaQuery,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react'
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'

import ConstituentCard from './ConstituentCard'
import ConstituentOptionsMenu from './ConstituentOptionsMenu'
import {
  clearConstituents,
  loadConstituents,
  paginate,
} from '../../../redux/features/constituentsSlice'
import {
  AddressText,
  Loading,
  NoResults,
  NoValue,
  ResizableTable,
} from '../../ui'
import { throttle } from '../../utils'
import { tableRowStyles } from '../styles/directoryTable'

const openConstituent = (e, constituent) => {
  const url = `/directory/constituents/${constituent.id}`
  if (e.metaKey || e.ctrlKey) {
    window.open(url, '_blank')
  } else if (window.getSelection().toString().length > 0) {
    e.preventDefault()
  } else {
    document.location.href = url
  }
}

const truncateArrayToString = (arr, maxLength = 2) => {
  if (!arr || arr.length === 0) return ''

  return arr.length > maxLength
    ? arr.slice(0, maxLength).join(', ') + ` +${arr.length - maxLength} others`
    : arr.join(', ')
}

const TdLabel = ({ children }) => (
  <Text casing='uppercase' fontWeight='bold' color='gray.500' fontSize='sm'>
    {children}
  </Text>
)

const TableRow = ({ constituent, index, onClose }) => {
  const selectedConstituents =
    useSelector(state => state.constituents.selected) || []
  const optionsMenuRef = useRef(null)
  const url = `/directory/constituents/${constituent.id}`

  const altEmails = constituent.contact_info.filter(contact =>
    contact.contact_type.includes('Email')
  )

  const phoneNumbers = constituent.contact_info.filter(contact =>
    contact.contact_type.includes('Phone')
  )

  const selectedBg = useColorModeValue('yellow.100', 'yellow.900')
  return (
    <Tr
      onClick={e => {
        if (optionsMenuRef.current.contains(e.target)) return
        openConstituent(e, constituent)
      }}
      sx={{
        ...tableRowStyles,
        'td': {
          backgroundColor: selectedConstituents.includes(index) && selectedBg,
          alignContent: 'center',
        },
      }}
    >
      {/* Name */}
      <Td>
        <Box>
          <TdLabel>
            <Tooltip
              label={constituent.title?.length > 30 && constituent.title}
            >
              {constituent.title?.length > 30
                ? `${constituent.title?.slice(0, 30)}...`
                : constituent.title}
            </Tooltip>
          </TdLabel>
          <Text>
            {constituent.full_name}
            {constituent.council_stat_id && (
              <Tooltip label='Transferred from CouncilSTAT'>
                <Icon as={DocumentDuplicateIcon} color='gray' />
              </Tooltip>
            )}
          </Text>
        </Box>
      </Td>
      {/* Address */}
      <Td>
        {constituent.home_address || constituent.business_address ? (
          <Stack>
            {constituent.home_address && (
              <Box>
                <TdLabel>
                  Home Address
                  {constituent.primary_address === 'home' &&
                    constituent.business_address &&
                    ' (Primary)'}
                </TdLabel>
                <AddressText address={constituent.home_address} />
              </Box>
            )}
            {constituent.business_address && (
              <Box>
                <TdLabel>
                  Business Address
                  {constituent.primary_address === 'business' &&
                    constituent.home_address &&
                    ' (Primary)'}
                </TdLabel>
                <AddressText address={constituent.business_address} />
              </Box>
            )}
          </Stack>
        ) : (
          <NoValue />
        )}
      </Td>
      {/* Email */}
      <Td>
        {constituent.email || altEmails.length > 0 ? (
          <Box>
            {constituent.email && (
              <>
                {altEmails.length > 0 && <TdLabel>Primary Email</TdLabel>}
                <Text>{constituent.email}</Text>
              </>
            )}
            {altEmails.map((contact, index) => (
              <Box key={index}>
                <TdLabel>Alternate Email</TdLabel>
                <Text>{contact.contact_data}</Text>
              </Box>
            ))}
          </Box>
        ) : (
          <NoValue />
        )}
      </Td>
      {/* Phone */}
      <Td>
        <Box>
          {phoneNumbers.length > 0 ? (
            phoneNumbers.map((contact, index) => (
              <Box key={index}>
                <TdLabel>{contact.contact_type}</TdLabel>
                <Text>{contact.contact_data}</Text>
              </Box>
            ))
          ) : (
            <NoValue />
          )}
        </Box>
      </Td>
      {/* Groups */}
      <Td>
        <Box>
          {truncateArrayToString(constituent.groups?.map(g => g.name)) || (
            <NoValue />
          )}
        </Box>
      </Td>
      {/* Associated topics */}
      <Td>
        <Box>
          {truncateArrayToString(
            constituent.associated_topics.map(topic => topic.name)
          ) || <NoValue />}
        </Box>
      </Td>
      {/* District */}
      <Td>
        <Box>{constituent.district || <NoValue />}</Box>
      </Td>
      {/* Options Menu */}
      <Td
        className='options-column'
        ref={optionsMenuRef}
        bgColor={
          selectedConstituents.includes(index) && `${selectedBg} !important`
        }
      >
        <Box>
          <ConstituentOptionsMenu
            url={url}
            constituentIndex={index}
            constituent={constituent}
            onClose={onClose}
          />
        </Box>
      </Td>
    </Tr>
  )
}

const ConstituentsTable = ({ tableRef }) => {
  // const tableRef = useRef(null)
  const dispatch = useDispatch()
  const [onClose, setOnClose] = useState(false)

  const loading = useSelector(state => state.constituents.loading)
  const constituents = useSelector(state => state.constituents.data)

  const [isLargerThan992] = useMediaQuery('(min-width: 992px)')

  // Define table headers for ResizableTable
  const headers = [
    { name: 'Name', value: 'first_name' },
    { name: 'Address' },
    { name: 'Email', value: 'email' },
    { name: 'Phone Number(s)' },
    { name: 'Groups' },
    { name: 'Associated Topics' },
    { name: 'District', value: 'district' },
    { name: ' ', value: '' },
  ]

  const handleScroll = () => {
    const container = tableRef.current

    if (
      container?.scrollTop + container?.clientHeight >=
      container?.scrollHeight - 50
    ) {
      dispatch(paginate())
    }
  }

  useEffect(() => {
    dispatch(loadConstituents())

    // Load more data when reaching the end of the container
    const throttledHandleScroll = throttle(handleScroll, 200)

    const container = tableRef.current
    container?.addEventListener('scroll', throttledHandleScroll, false)
    return () => {
      container?.removeEventListener('scroll', throttledHandleScroll, false)
      dispatch(clearConstituents())
    }
  }, [dispatch])

  useEffect(() => {
    const closeOptionsOnScroll = () => {
      setOnClose(true)
    }
    const endCloseOptionsOnScroll = () => {
      setOnClose(false)
    }

    const container = tableRef.current
    container?.addEventListener('scroll', closeOptionsOnScroll, true)
    container?.addEventListener('scrollend', endCloseOptionsOnScroll, true)
    return () => {
      container?.removeEventListener('scroll', closeOptionsOnScroll, true)
      container?.removeEventListener('scrollend', endCloseOptionsOnScroll, true)
    }
  }, [])

  const tableContent = isLargerThan992 ? (
    constituents.map((constituent, index) => (
      <TableRow
        constituent={constituent}
        key={index}
        index={index}
        onClose={onClose}
      />
    ))
  ) : (
    <Stack>
      {constituents.map((constituent, index) => (
        <ConstituentCard key={index} constituent={constituent} index={index} />
      ))}
    </Stack>
  )

  return (
    <>
      {isLargerThan992 ? (
        <ResizableTable
          localStorageKeyName={'ConstituentsTable'}
          headers={headers}
          tableContent={tableContent}
          minCellWidth={150}
        />
      ) : (
        tableContent
      )}
      {loading && <Loading />}
      {!loading && constituents?.length === 0 && <NoResults />}
    </>
  )
}

export default ConstituentsTable
