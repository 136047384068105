import React from 'react'

import { MenuItem } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'

import { startExportCasework } from '../../../redux/features/caseworkSlice'
import { ExportLoading } from '../../ui'

const CaseworkActionMenu = () => {
  const dispatch = useDispatch()
  const isExporting = useSelector(state => state.casework.exporting)

  return (
    <>
      <MenuItem
        onClick={() => dispatch(startExportCasework({ useFilters: true }))}
      >
        Export current view
      </MenuItem>
      <ExportLoading isLoading={isExporting} />
    </>
  )
}

export default CaseworkActionMenu
