import React from 'react'

import { Tab, TabList, TabPanel, TabPanels, Tabs, Flex } from '@chakra-ui/react'

import CreateNewStaff from './CreateNewStaff'
import ManageExistingStaff from './ManageExistingStaff'

const StaffManager = () => {
  return (
    <>
      <Tabs isFitted>
        <TabList>
          <Tab> Create New Staff</Tab>
          <Tab> Manage Existing Staff </Tab>
        </TabList>
        <TabPanels>
          {/* Panel for creating new staff */}
          <TabPanel p='0'>
            <Flex
              h='calc(100vh - 300px)'
              flexDirection='column'
              overflowY='auto'
              ps={0}
              p={5}
            >
              <CreateNewStaff />
            </Flex>
          </TabPanel>
          {/* Panel for managing existing staff */}
          <TabPanel p='0'>
            <Flex
              h='calc(100vh - 300px)'
              flexDirection='column'
              overflowY='auto'
              p={5}
            >
              <ManageExistingStaff />
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}

export default StaffManager
