import React from 'react'

import { Box, Flex } from '@chakra-ui/react'

import Navbar from './components/ui/Navbar'

const Layout = ({ children }) => {
  return (
    <Flex direction='column' h='100vh'>
      <Navbar />
      <Box flex='1'>{children}</Box>
    </Flex>
  )
}

export default Layout
