import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  data: [],
  options: [],
  instance: null,
  loading: false,
  errors: null,
  next: null,
  count: 0,
}

export const staffSlice = createSlice({
  initialState,
  name: 'staff',
  reducers: {
    loadStaff(state) {
      state.loading = true
      state.data = []
      state.next = null
      state.errors = null
      state.count = 0
    },
    storeStaff(state, action) {
      const { count, next, results } = action.payload
      state.data = [...state.data, ...results]
      state.count = count
      state.next = next
      state.loading = false
    },

    loadStaffInstance(state) {
      state.loading = true
    },
    storeStaffInstance(state, action) {
      state.instance = action.payload
      state.loading = false
    },

    createStaff() {},
    updateStaff() {},
    deactivateStaff(state) {
      state.loading = true
    },

    loadStaffOptions(state) {
      state.options_loading = true
    },
    paginateStaffOptions() {},
    storeStaffOptions(state, action) {
      const { results, next } = action.payload
      if (state.options?.length === 0)
        state.options = [
          {
            id: '-1',
            full_name: 'Unassigned',
          },
        ]
      state.options = [...state.options, ...results]
      state.options_next = next
      delete state.options_loading
    },

    startExportStaff(state) {
      state.exporting = true
    },
    completeExportStaff(state) {
      delete state.exporting
    },

    clearStaff(state) {
      state.data = []
      state.next = null
      state.count = 0
    },
    clearStaffInstance(state) {
      state.instance = null
    },
    clearStaffOptions(state) {
      state.options = []
    },

    paginate(state) {
      state.loading = true
    },
    cancelLoading(state) {
      state.loading = false
    },
    setErrors(state, action) {
      state.loading = false
      state.errors = action.payload
    },
  },
})

export default staffSlice.reducer

export const {
  loadStaff,
  storeStaff,

  loadStaffInstance,
  storeStaffInstance,

  startExportStaff,
  completeExportStaff,

  createStaff,
  updateStaff,
  deactivateStaff,

  loadStaffOptions,
  paginateStaffOptions,
  storeStaffOptions,

  clearStaff,
  clearStaffInstance,
  clearStaffOptions,

  paginate,
  cancelLoading,
  setErrors,
} = staffSlice.actions
