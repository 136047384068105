import React, { useState } from 'react'

import { Icon } from '@chakra-ui/icons'
import {
  Box,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Spacer,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
  Tooltip as ChakraTooltip,
  Center,
  Select,
  useColorMode,
  useColorModeValue,
  Stack,
  Heading,
} from '@chakra-ui/react'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { useSelector } from 'react-redux'
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import SortableTable from './SortableTable'
import { extractCaseNum } from '../../components/utils'
import { Loading, NotFound } from '../ui'
import { generateShades } from '../utils'

const CaseworkCount = ({
  cases_opened_count,
  cases_closed_count,
  cases_created_count,
}) => (
  <Card>
    <CardBody>
      <StatGroup>
        <Stat>
          <StatLabel>
            <span border='1px solid'> Cases Opened</span>
          </StatLabel>
          <StatNumber>{cases_opened_count}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Cases Closed</StatLabel>
          <StatNumber>{cases_closed_count}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Created Cases</StatLabel>
          <StatNumber>{cases_created_count}</StatNumber>
        </Stat>
      </StatGroup>
    </CardBody>
  </Card>
)

const CaseworkList = ({ cases, caseType, setCaseType }) => {
  if (!cases || cases.length === 0) return <></>

  const valueKeys = [
    {
      label: 'Case Number',
      value: 'case_num',
      onClick: case_num => window.open(`/casework/${extractCaseNum(case_num)}`),
    },
    {
      label: 'Current Status',
      value: 'status',
    },
    {
      label: 'Opened Date',
      value: 'opened_at',
    },
    {
      label: 'Closed Date',
      value: 'closed_at',
    },
    {
      label: 'Created Date',
      value: 'created_at',
    },
  ]

  return (
    <Card maxH={625}>
      <CardBody>
        <Flex align='center' pb={1}>
          <Heading whiteSpace='nowrap' w='60%' color='blue.400' fontSize='lg'>
            {caseType === 'cases'
              ? 'Case Breakdown'
              : caseType.replace('_', ' ')}
          </Heading>
          <Spacer />
          <Select
            id='report-case-filter'
            size='md'
            value={caseType}
            onChange={e => setCaseType(e.target.value)}
          >
            <option value='cases'>Show all cases during period </option>
            <option value='cases_opened'>Show cases opened </option>
            <option value='cases_closed'>Show cases closed </option>
            <option value='cases_created'>Show cases created </option>
          </Select>
        </Flex>
        <Box pt={2}>
          <SortableTable
            values={cases}
            valueKeys={valueKeys}
            defaultSort='status'
          />
        </Box>
      </CardBody>
    </Card>
  )
}

const CaseworkIssues = ({ issues }) => {
  if (!issues || issues.length === 0) return <></>

  const valueKeys = [
    {
      label: 'Issue Category',
      value: 'issue_name',
    },
    {
      label: 'Casework Count',
      value: 'count',
    },
  ]

  return (
    <Card maxH={625} overflowY='auto'>
      <CardBody>
        <Heading color='blue.400' fontSize='lg'>
          Issues
        </Heading>

        <SortableTable
          values={issues}
          valueKeys={valueKeys}
          defaultSort='count'
        />
      </CardBody>
    </Card>
  )
}

const CaseworkStaff = ({ staffBreakdown }) => {
  if (!staffBreakdown || staffBreakdown.length === 0) return <></>

  const valueKeys = [
    {
      label: 'Staff Name',
      value: 'staff',
    },
    {
      label: 'Casework Opened',
      value: 'opened',
    },
    {
      label: 'Casework Closed',
      value: 'closed',
    },
  ]
  return (
    <Card maxH={750} overflowY='auto'>
      <CardBody>
        <Text casing='uppercase' w='100%' align='left'>
          Staff Breakdown
        </Text>

        <SortableTable
          values={staffBreakdown}
          valueKeys={valueKeys}
          defaultSort='opened'
        />
      </CardBody>
    </Card>
  )
}

const RecolorButton = ({ setColors, numShades }) => {
  const { colorMode } = useColorMode()
  return (
    <ChakraTooltip label='Recolor if hard to see'>
      <IconButton
        icon={<Icon as={ArrowPathIcon} />}
        variant='ghost'
        onClick={() => {
          setColors(generateShades(numShades, colorMode))
        }}
      />
    </ChakraTooltip>
  )
}

const IntakeMethods = ({ intakeMethods }) => {
  if (!intakeMethods || intakeMethods.length === 0) return <></>
  const { colorMode } = useColorMode()
  const [colors, setColors] = useState(
    generateShades(intakeMethods.length, colorMode)
  )

  const legendText = (value, entry, color) => {
    return <Text color={color}>{value}</Text>
  }
  const legendTextColor = useColorModeValue('black', '#fff')
  return (
    <Card>
      <CardBody align='center'>
        <Flex align='center'>
          <Heading fontSize='lg' color='blue.400'>
            Intake Methods
          </Heading>

          <Spacer />
          <RecolorButton
            setColors={setColors}
            numShades={intakeMethods.length}
          />
        </Flex>
        <Center py={3}>
          <PieChart width={400} height={400}>
            <Pie data={intakeMethods} dataKey='count' nameKey='name'>
              {intakeMethods.map((_, index) => (
                <Cell key={`cell-${index}`} fill={colors[index]} />
              ))}
            </Pie>

            <Legend
              formatter={(value, entry) =>
                legendText(value, entry, legendTextColor)
              }
            />

            <Tooltip />
          </PieChart>
        </Center>
      </CardBody>
    </Card>
  )
}

const CaseworkTimeline = ({ dateBreakdown }) => {
  if (!dateBreakdown || dateBreakdown.length === 0) return <></>
  const { colorMode } = useColorMode()
  const [colors, setColors] = useState(generateShades(2, colorMode))
  const altColor = useColorModeValue('black', '#fff')

  const legendText = value => {
    return <Text color={altColor}>{value}</Text>
  }

  const tooltipBox = ({ active, label, payload }) => {
    if (active && payload && payload.length) {
      const opened = payload[0]
      const closed = payload[1]

      return (
        <Card bg='white' variant='outline'>
          <CardBody color='black'>
            <Text>{label}</Text>
            <Stack direction='row' align='center'>
              <Box h={3} w={3} bg={opened.color} />
              <Text>{opened.value}&nbsp;casework opened</Text>
            </Stack>
            <Stack direction='row' align='center'>
              <Box h={3} w={3} bg={closed.color} />
              <Text>{closed.value}&nbsp;casework closed</Text>
            </Stack>
          </CardBody>
        </Card>
      )
    }
    return null
  }

  return (
    <Card>
      <CardBody>
        <Flex align='center'>
          <Heading fontSize='lg' color='blue.400'>
            Date Breakdown
          </Heading>
          <Spacer />
          <RecolorButton setColors={setColors} numShades={2} />
        </Flex>
        <Center py={3}>
          <BarChart width={600} height={400} data={dateBreakdown}>
            <XAxis dataKey='label' type='category' stroke={altColor} />
            <YAxis stroke={altColor} />
            <Tooltip content={tooltipBox} />
            <Legend formatter={legendText} />
            <Bar dataKey='opened' fill={colors[0]} />
            <Bar dataKey='closed' fill={colors[1]} />
          </BarChart>
        </Center>
      </CardBody>
    </Card>
  )
}

const Report = () => {
  const loading = useSelector(state => state.reports.loading)
  const results = useSelector(state => state.reports.results)
  const [caseType, setCaseType] = useState('cases')

  if (loading)
    return <Loading message={'Please be patient...Data processing...'} />
  if (!results) return <NotFound />
  return (
    <Grid
      templateAreas={{
        base: `
          "count"
          "cases"
          "issues"
          "timeline"
          "intakeMethods"
          "staff"
        `,
        lg: `
          "count count"
          "cases issues"
          "timeline intakeMethods"
          "staff staff"
        `,
      }}
      gap={3}
    >
      <GridItem area='count'>
        <CaseworkCount
          {...results}
          caseType={caseType}
          setCaseType={setCaseType}
        />
      </GridItem>

      <GridItem area='cases'>
        <CaseworkList
          cases={results[caseType]}
          caseType={caseType}
          setCaseType={setCaseType}
        />
      </GridItem>
      <GridItem area='issues'>
        <CaseworkIssues issues={results.issues_count} />
      </GridItem>
      <GridItem area='intakeMethods'>
        <IntakeMethods intakeMethods={results.intake_method_count} />
      </GridItem>
      <GridItem area='timeline'>
        <CaseworkTimeline dateBreakdown={results.date_breakdown} />
      </GridItem>
      <GridItem area='staff'>
        <CaseworkStaff staffBreakdown={results.staff_breakdown} />
      </GridItem>
    </Grid>
  )
}

export default Report
