import React, { createContext, useContext } from 'react'

import { Box, Text, Stack } from '@chakra-ui/react'

const SearchOptionCategoryContext = createContext()

export const useSearchOptionCategory = () =>
  useContext(SearchOptionCategoryContext)

const SearchOptionCategory = ({ category, children }) => {
  if (category === undefined) return null

  return (
    <SearchOptionCategoryContext.Provider value={{ category }}>
      <Stack gap={0}>
        {category && (
          <Text
            casing='uppercase'
            fontSize='lg'
            color='blue.400'
            position='sticky'
            top='0'
            px={3}
            py={2}
            zIndex='sticky'
            backgroundColor='var(--popper-bg)'
          >
            {category}
          </Text>
        )}

        <Box>{children}</Box>
      </Stack>
    </SearchOptionCategoryContext.Provider>
  )
}

export default SearchOptionCategory
