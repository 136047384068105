import { createStandaloneToast } from '@chakra-ui/toast'
import axios from 'axios'
import Cookies from 'js-cookie'

import { calculateMS } from '../utils'

const { toast } = createStandaloneToast()

const backendAPI = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
})

const toast_id = 'network-error'
// Add an interceptor to handle errors globally
backendAPI.interceptors.response.use(
  response => response,
  error => {
    if (error.config?.baseURL?.includes(process.env.REACT_APP_BACKEND_URL)) {
      /** Handle 401 error, e.g., redirect to login page or show a notification */
      if (error.response && error.response.status === 401) {
        console.error('Unauthorized error occurred')
        delete axios.defaults.headers.common['Authorization']
        Cookies.remove('api_token')
        document.location.href = '/'
        // Once cookie is removed, it should redirect to login because of protected routes.
      }
      /** In the event the backend is down, remove logins */
      if (error.message === 'Network Error' && !error.response) {
        if (!toast.isActive(toast_id)) {
          toast({
            title: 'Oops! Looks like our servers are down.',
            description:
              "Please try again later. We'll have Council Connect up and running as soon as possible.",
            status: 'error',
            duration: calculateMS(10),
            isClosable: true,
            id: toast_id,
          })
        }
        delete axios.defaults.headers.common['Authorization']
        Cookies.remove('api_token')
      }
    }

    return Promise.reject(error)
  }
)

export default backendAPI
