import React from 'react'

import { Box, Text } from '@chakra-ui/react'

const NoSearchResults = ({ children }) => {
  return (
    <Box p={3}>
      <Text> {children} </Text>
    </Box>
  )
}

export default NoSearchResults
