import React from 'react'

import { Heading, Select, Grid, GridItem } from '@chakra-ui/react'
import { Form } from 'formik'
import { useSelector } from 'react-redux'

import { districtOptions } from '../../../constants'
import { FormField } from '../../ui'

const StaffForm = () => {
  const user = useSelector(state => state.auth.user)
  return (
    <Form>
      <Heading size='md' mb={2}>
        Personal Information
      </Heading>
      <Grid
        templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
        gap={4}
        mb={4}
        gridTemplateAreas={`
          "first_name preferred_name"
          "middle_name suffix"
          "last_name pronouns"
        `}
      >
        <GridItem colSpan={[1, 1]} w='100%' gridArea='first_name'>
          <FormField
            field='user.first_name'
            placeholder='First Name *'
            isRequired
          />
        </GridItem>
        <GridItem colSpan={[1, 1]} w='100%' gridArea='middle_name'>
          <FormField field='user.middle_name' placeholder='Middle Name' />
        </GridItem>
        <GridItem colSpan={[1, 1]} w='100%' gridArea='last_name'>
          <FormField
            field='user.last_name'
            placeholder='Last Name *'
            isRequired
          />
        </GridItem>
        <GridItem colSpan={[1, 1]} w='100%' gridArea='preferred_name'>
          <FormField field='user.preferred_name' placeholder='Preferred Name' />
        </GridItem>
        <GridItem colSpan={[1, 1]} w='100%' gridArea='suffix'>
          <FormField field='user.suffix' placeholder='Suffix' />
        </GridItem>
        <GridItem colSpan={[1, 1]} w='100%' gridArea='pronouns'>
          <FormField field='user.pronouns' placeholder='Pronouns' />
        </GridItem>
      </Grid>

      {user?.is_admin && (
        <>
          <Heading size='md' mb={2}>
            Council Information
          </Heading>
          <Grid gap={4} mb={4}>
            <GridItem colSpan={[1, 2]} w='100%'>
              <FormField
                field='role'
                options={[
                  'Constituent Liaison',
                  'Intern',
                  'Director of Constituent Services',
                  'Chief of Staff',
                  'Council Member',
                ]}
                inputType={Select}
                placeholder='Role *'
              />
            </GridItem>
            <GridItem colSpan={[1, 2]} w='100%'>
              <FormField
                field='district'
                placeholder='District *'
                options={districtOptions}
                inputType={Select}
              />
            </GridItem>
            <GridItem colSpan={[1, 2]} w='100%'>
              <FormField field='user.email' placeholder='Email *' />
            </GridItem>
          </Grid>
        </>
      )}
    </Form>
  )
}

export default StaffForm
