import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: null,
  loading: false,
  errors: null,
}

export const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    logout() {
      return initialState
    },
    login() {},
    loginWithToken() {},
    forgotPassword() {},
    resetPassword() {},
    validateResetToken() {},
    authFailure(state) {
      state.errors = 'No account found with provided credentials.'
      state.loading = false
      delete state.otp_loading
    },
    waitForAuth(state) {
      if (state.otp === 'pending') {
        state.otp_loading = true
      } else {
        state.loading = true
      }
      state.errors = null
    },
    waitForOTP(state) {
      state.loading = false
      state.otp = 'pending'
      state.errors = null
    },
    invalidOTP(state) {
      state.otp_err = 'Invalid one time password.'
      delete state.otp_loading
    },
    expiredOTP(state) {
      state.otp_err =
        'One time password expired. Only valid for 15 minutes. A new one has been emailed to you.'
      delete state.otp_loading
    },
    cancelOTP(state) {
      delete state.otp
      delete state.otp_err
      state.loading = false
      state.errors = null
    },
    loginSuccess(state, action) {
      state.user = action.payload
      state.loading = false
      state.errors = null
      delete state.otp
      delete state.otp_err
      delete state.otp_loading
    },
    passwordExpired(state) {
      state.password_expired = true
      state.loading = false
      state.errors = null
      delete state.otp
      delete state.otp_err
      delete state.otp_loading
    },
    clearErrors(state) {
      delete state.otp_err
      delete state.password_expired
      delete state.errors
      delete state.password_validation_errors
    },
    passwordValidationErrors(state, action) {
      state.password_validation_errors = action.payload
    },
    changePassword(state) {
      state.errors = null
    },
    setErrors(state, action) {
      state.loading = false
      state.errors = action.payload
    },
    clearLoading(state) {
      state.loading = false
    },
  },
})

export default authSlice.reducer

export const {
  login,
  loginWithToken,
  logout,
  forgotPassword,
  validateResetToken,
  resetPassword,
  passwordValidationErrors,
  authFailure,
  waitForAuth,
  waitForOTP,
  invalidOTP,
  expiredOTP,
  cancelOTP,
  loginSuccess,
  passwordExpired,
  clearErrors,
  changePassword,
  setErrors,
  clearLoading,
} = authSlice.actions
