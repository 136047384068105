import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [],
  options: [],
  instance: null,
  loading: false,
  errors: null,
  next: null,
  count: 0,
}

export const groupsSlice = createSlice({
  initialState,
  name: 'groups',
  reducers: {
    loadGroups(state) {
      state.loading = true
      state.data = []
      state.next = null
      state.count = 0
    },
    storeGroups(state, action) {
      const { count, next, results } = action.payload
      state.data = [...state.data, ...results]
      state.count = count
      state.next = next
      state.loading = false
    },
    clearGroups(state) {
      state.data = []
      state.next = null
      state.count = 0
    },

    loadGroupInstance(state) {
      state.loading = true
      state.instance = null
    },
    storeGroupInstance(state, action) {
      state.instance = action.payload
      state.loading = false
    },
    clearGroupInstance(state) {
      state.instance = null
    },

    createGroup() {},
    updateGroup() {},
    deleteGroup() {},

    loadGroupOptions(state) {
      state.options_loading = true
    },
    storeGroupOptions(state, action) {
      const { results, next } = action.payload
      state.options = [...state.options, ...results]
      state.options_next = next
      state.options_loading = false
    },
    paginateGroupOptions() {},
    clearGroupOptions(state) {
      state.options = []
      delete state.options_next
    },

    paginate(state) {
      state.loading = true
    },
    cancelLoading(state) {
      state.loading = false
    },
    setErrors(state, action) {
      state.loading = false
      state.errors = action.payload
    },
  },
})

export default groupsSlice.reducer

export const {
  loadGroups,
  storeGroups,
  clearGroups,

  loadGroupInstance,
  storeGroupInstance,
  clearGroupInstance,

  createGroup,
  updateGroup,
  deleteGroup,

  loadGroupOptions,
  storeGroupOptions,
  paginateGroupOptions,
  clearGroupOptions,

  paginate,
  cancelLoading,
  setErrors,
} = groupsSlice.actions
