import React, { useCallback, useMemo } from 'react'

import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Textarea,
} from '@chakra-ui/react'
import { FastField, Field, useField } from 'formik'

import { capitalize } from '../../../utils'
import { snakeCasetoTitle } from '../../utils'

const FormField = ({
  field,
  id = field,
  name = field,
  type = 'text',
  isRequired,
  placeholder,
  placeholderIcon,
  helpMessage,
  isDisabled,
  isInvalid,
  inputType = Input,
  options = [],
  hideErrors,
  otherProps = {},
  variant,
  onBlur,
  shouldOptimize,
  validate,
}) => {
  const [formikField, meta] = useField(field)

  const errors = useMemo(() => capitalize(meta.error), [meta.error])

  const handleBlur = useCallback(
    e => {
      if (onBlur) onBlur(e)
      formikField.onBlur(e)
    },
    [onBlur, formikField.onBlur]
  )

  const handleKeyDown = useCallback(
    e => {
      if (e.key === 'Enter' && inputType !== Textarea) e.preventDefault()
    },
    [inputType]
  )

  placeholder = useMemo(
    () =>
      placeholder === null ? null : placeholder || snakeCasetoTitle(field),
    [placeholder, formikField.value]
  )

  const inputProps = useMemo(
    () => ({
      as: inputType,
      id: id,
      name: name,
      placeholder: placeholder,
      autoComplete: 'off',
      variant: variant,
      type: type,
      ...otherProps,
      textIndent: placeholderIcon && '1.15em',
      validate: validate,
    }),
    [
      id,
      name,
      placeholder,
      placeholderIcon,
      inputType,
      variant,
      type,
      otherProps,
    ]
  )

  return (
    <FormControl
      isDisabled={isDisabled}
      isInvalid={isInvalid || errors}
      isRequired={isRequired}
    >
      <InputGroup>
        {placeholderIcon && (
          <InputLeftElement>
            <Icon as={placeholderIcon} color='gray.300' pt={0.5} />
          </InputLeftElement>
        )}
        {inputType === Select ? (
          <FastField {...inputProps} onBlur={handleBlur}>
            {options.map((option, index) => (
              <option value={option} key={index}>
                {option}
              </option>
            ))}
          </FastField>
        ) : shouldOptimize ? (
          <FastField
            {...inputProps}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <Field
            {...inputProps}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
          />
        )}
      </InputGroup>
      {helpMessage && <FormHelperText> {helpMessage} </FormHelperText>}

      {!hideErrors && <FormErrorMessage> {errors} </FormErrorMessage>}
    </FormControl>
  )
}

export default FormField
