import React, { useEffect, useRef, useState } from 'react'

import {
  Box,
  Button,
  Flex,
  HStack,
  Spacer,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { caseworkSchema, initialCaseworkValues } from '../../constants'
import { createCaseworkInstance } from '../../redux/features/caseworkSlice'
import {
  AttachmentsPage,
  CaseworkFormStepper,
  CaseworkInfoPage,
  ConstituentSelectPage,
  IntakeInfoPage,
  ReviewPage,
} from '../casework'
import {
  formatFormValues,
  formatInitialValues,
} from '../casework/casework/caseworkForm/utils'

const CreateCasework = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const user = useSelector(state => state.auth.user)
  const pageContentRef = useRef(null)

  const [formPage, setFormPage] = useState(0)
  const [missingFields, setMissingFields] = useState(false)
  const [constituentMode, setConstituentMode] = useState('select') // select from directory or create new

  useEffect(() => {
    if (pageContentRef.current) pageContentRef.current.scroll({ top: 0 })
  }, [formPage, pageContentRef])

  document.title = 'Create Casework'
  const pages = [
    {
      title: 'Constituent',
      description: 'Select existing constituent or create new one',
      component: ConstituentSelectPage,
      componentProps: {
        mode: constituentMode,
        setMode: mode => {
          setMissingFields(false)
          setConstituentMode(mode)
        },
      },
      validatePage: (setErrors, values) => {
        let valid = true
        let errors = {}
        if (!values.constituent && constituentMode === 'select') {
          errors['constituent'] = 'Required field'
          valid = false
        }
        if (constituentMode === 'create') {
          if (!values.newConstituent.first_name) {
            if (!('newConstituent' in errors)) errors['newConstituent'] = {}

            errors['newConstituent']['first_name'] = 'Required field'
            valid = false
          }
          if (!values.newConstituent.last_name) {
            if (!('newConstituent' in errors)) errors['newConstituent'] = {}
            errors['newConstituent']['last_name'] = 'Required field'

            valid = false
          }
        }

        setErrors(errors)
        return valid
      },
    },
    {
      title: 'Casework Details',
      description: 'Provide casework information',
      component: CaseworkInfoPage,
      componentProps: {
        constituentMode: constituentMode,
      },
      validatePage: (setErrors, values) => {
        let valid = true

        let errors = {}
        if (values.status === 'Closed' && values.topics.length === 0) {
          errors['topics'] = 'Topic required to close casework'
          valid = false
        }
        if (!values.details) {
          errors['details'] = 'Required field'
          valid = false
        }

        if (!valid) setErrors(errors)

        return valid
      },
    },
    {
      title: 'Intake Information',
      description: 'Provide intake information',
      component: IntakeInfoPage,
      componentProps: {},
      fields: [
        'intake_method',
        'reference_numbers',
        'referral_staff',
        'comments',
      ],
      validatePage: () => true,
    },
    {
      title: 'Attachments',
      description: 'Upload any relevant attachments',
      component: AttachmentsPage,
      componentProps: {},
      fields: ['attachments'],
      validatePage: () => true,
    },
    {
      title: 'Review',
      description: 'Review all details before submitting',
      component: ReviewPage,
      componentProps: {
        creatingConstituent: constituentMode === 'create',
      },
      fields: [],
      validatePage: () => true,
    },
  ]

  const CurrentPage = pages[formPage].component

  const initialValues = formatInitialValues(initialCaseworkValues)
  if (location.state?.constituent)
    initialValues.constituent = location.state?.constituent
  if (user.role === 'Intern') {
    initialValues.assigned_staff.push(user)
  }

  initialValues.assigned_staff = []
  const formikProps = {
    initialValues: initialValues,
    validationSchema: caseworkSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      const callbackSuccess = case_num => {
        navigate(`/casework/${case_num}`)
        setSubmitting(false)
      }

      const callbackFailure = () => {
        setSubmitting(false)
      }

      dispatch(
        createCaseworkInstance({
          values: formatFormValues(values, constituentMode),
          callbackSuccess,
          callbackFailure,
        })
      )
    },
  }

  return (
    <>
      <Box m='auto' maxW='5xl' mt={5}>
        <Formik {...formikProps}>
          {({
            values,
            errors,
            isSubmitting,

            handleSubmit,
            setFieldValue,
            setErrors,
          }) => {
            const handlePrevPage = () => {
              setFormPage(currPage =>
                currPage === 0 ? currPage : currPage - 1
              )
            }

            const handleNextPage = () => {
              if (!pages[formPage].validatePage(setErrors, values)) {
                setMissingFields(true)
                return
              }
              setMissingFields(false)
              setErrors({})
              setFormPage(currPage =>
                currPage === pages.length - 1 ? currPage : currPage + 1
              )
            }
            return (
              <>
                <CaseworkFormStepper
                  steps={pages}
                  activeStep={formPage}
                  onChangeStep={setFormPage}
                  onNext={handleNextPage}
                  onPrevious={handlePrevPage}
                />
                <Form>
                  <Box
                    m='auto'
                    maxW='5xl'
                    my={2}
                    h='68vh'
                    overflowY='auto'
                    px={2}
                    ref={pageContentRef}
                  >
                    <CurrentPage {...pages[formPage].componentProps} />
                  </Box>

                  <Stack>
                    {formPage === pages.length - 1 &&
                      JSON.stringify(errors) !== '{}' && (
                        <Box align='end'>
                          <Text color='red'>Required fields are missing!</Text>
                        </Box>
                      )}
                    <Flex align='center' w='100%'>
                      <Button
                        visibility={formPage === 0 ? 'hidden' : 'visible'}
                        onClick={handlePrevPage}
                        variant='outline'
                      >
                        Previous Step
                      </Button>
                      <Spacer />
                      {formPage === pages.length - 1 ? (
                        <>
                          <HStack>
                            <Text>Close Casework</Text>
                            <Switch
                              id='status'
                              defaultChecked={
                                !!values.closed_at || values.status === 'Closed'
                              }
                              isDisabled={!!values.closed_at}
                              onChange={e => {
                                if (e.target.checked)
                                  setFieldValue('status', 'Closed')
                                else setFieldValue('status', 'In Progress')
                              }}
                            />
                          </HStack>
                          <Button
                            onClick={handleSubmit}
                            h={50}
                            isLoading={isSubmitting}
                            ml={3}
                          >
                            Create Casework
                          </Button>
                        </>
                      ) : (
                        <>
                          {missingFields && (
                            <Text color='red' mr={3}>
                              Required fields were not filled in.
                            </Text>
                          )}
                          <Button onClick={handleNextPage} variant='outline'>
                            Next Step
                          </Button>
                        </>
                      )}
                    </Flex>
                  </Stack>
                </Form>
              </>
            )
          }}
        </Formik>
      </Box>
    </>
  )
}

export default CreateCasework
