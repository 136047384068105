import React from 'react'

import {
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { TrashIcon } from '@heroicons/react/24/solid'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { deleteConstituent } from '../../../../redux/features/constituentsSlice'
import { ConfirmActionDialog, NotFound } from '../../../ui'

const ConstituentMenu = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const toast = useToast()

  const user = useSelector(state => state.auth.user)
  const constituent = useSelector(state => state.constituents.instance)

  if (!constituent) return <NotFound />

  const { casework, district, id, full_name } = constituent
  const {
    isOpen: confirmDeleteIsOpen,
    onOpen: confirmDeleteOnOpen,
    onClose: confirmDeleteOnClose,
  } = useDisclosure()

  const handleDeleteConstituent = () => {
    dispatch(
      deleteConstituent({
        id,
        callbackSuccess: () => {
          navigate('/directory')
          toast({
            title: `Successfully deleted constituent ${full_name}`,
            status: 'success',
          })
        },
        callbackFailure: () => {
          toast({
            title: 'Error deleting constituent',
            status: 'error',
          })
        },
      })
    )
    confirmDeleteOnClose()
  }

  const actions = []
  if (casework.length === 0 && (user.district === district || user.is_admin))
    actions.push('delete')

  if (actions.length === 0) return <></>

  return (
    <>
      <Menu>
        <MenuButton
          aria-label='Additional Constituent Actions'
          as={IconButton}
          icon={<Icon as={EllipsisVerticalIcon} boxSize={6} />}
          variant='ghost'
        />
        <MenuList>
          {actions.includes('delete') && (
            <MenuItem
              color='red'
              icon={<Icon as={TrashIcon} />}
              onClick={confirmDeleteOnOpen}
            >
              DELETE
            </MenuItem>
          )}
        </MenuList>
      </Menu>
      {actions.includes('delete') && (
        <ConfirmActionDialog
          isOpen={confirmDeleteIsOpen}
          onClose={confirmDeleteOnClose}
          onConfirm={handleDeleteConstituent}
          scheme='delete'
        >
          <Text fontWeight='bold'>
            This constituent will be deleted and cannot be recovered.
          </Text>
          <Text> Are you sure you want to proceed?</Text>
        </ConfirmActionDialog>
      )}
    </>
  )
}

export default ConstituentMenu
