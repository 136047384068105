import React, { useCallback, useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'

const AdvancedFilterInput = ({
  setFilters = () => {},
  filterKey,
  store,
  labelKey,
  children,
}) => {
  const dispatch = useDispatch()

  const filters = useSelector(state => state[store].filters)

  const onSelect = useCallback(
    option => {
      let filtersArray = [...(filters[filterKey] || [])]
      if (!filtersArray.find(c => c.id === option.id))
        filtersArray.push({ id: option.id, [labelKey]: option[labelKey] })

      dispatch(
        setFilters({
          ...filters,
          [filterKey]: filtersArray,
        })
      )
    },
    [dispatch, setFilters, filters, filterKey]
  )

  const onRemove = useCallback(
    removeIndex => {
      let filtersArray = [...(filters[filterKey] || [])]
      if (filtersArray.length >= removeIndex)
        filtersArray.splice(removeIndex, 1)

      dispatch(
        setFilters({
          ...filters,
          [filterKey]: filtersArray,
        })
      )
    },
    [dispatch, setFilters, filters, filterKey]
  )

  const onClear = useCallback(() => {
    dispatch(setFilters({ ...filters, [filterKey]: [] }))
  }, [dispatch, setFilters, filters, filterKey])

  const initialSelected = useMemo(
    () =>
      filters[filterKey]?.map(option => ({
        label: option[labelKey],
        value: option,
      })) || [],
    [filters, filterKey]
  )
  return React.cloneElement(children, {
    onSelect,
    onRemove,
    onClear,
    initialSelected,
  })
}

export default AdvancedFilterInput
