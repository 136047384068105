import { popoverAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
  content: {
    borderColor: 'gray.400',
    _dark: {
      borderColor: 'gray.600',
    },
  },
})

export const popoverTheme = defineMultiStyleConfig({ baseStyle })
