import * as Yup from 'yup'

import {
  addressValidationSchema,
  caseworkNewConstituentValidationSchema,
  initialAddressValues,
  initialConstituentValues,
} from './directory'

export const FILE_SIZE_LIMIT_MB = 350
export const FILE_SIZE_LIMIT_BYTES = FILE_SIZE_LIMIT_MB * 1024 * 1024

export const activityCategoryOptions = [
  'Note',
  'Call',
  'Email',
  'Miscellaneous',
]

export const initialCaseworkValues = {
  address: initialAddressValues,
  details: '',
  status: 'In Progress',
  priority: 'Low',
  intake_method: '',
  reference_numbers: '',
  constituent: null,
  newConstituent: initialConstituentValues,
  referral_staff: null,
  referral_category: '',
  topics: [],
  assigned_staff: [],
  attachments: [],
  add_attachments: [],
  remove_attachments: [],
  agencies: [],
  tags: [],
  opened_at: '',
  closed_at: '',
  comments: '',
}

export const caseworkSchema = Yup.object().shape({
  address: addressValidationSchema.nullable(),
  details: Yup.string().required('Details are required.'),
  status: Yup.string(),
  priority: Yup.string(),
  intake_method: Yup.string(),
  topics: Yup.array().test(
    'topicReqOnClose',
    'Topic required to close casework',
    function (value) {
      const { status } = this.parent

      if (status !== 'Closed' || !value) return true

      return value.length !== 0
    }
  ),
  reference_numbers: Yup.string(),
  constituent: Yup.mixed().nullable(true),
  newConstituent: caseworkNewConstituentValidationSchema
    .nullable(true)
    .required(false),
  assigned_staff: Yup.array().of(Yup.mixed()),
  attachments: Yup.array(),
  agencies: Yup.array(),
  tags: Yup.array(),
  comments: Yup.string(),
  referral_staff: Yup.object().nullable(),
  referral_category: Yup.string(),
  add_attachments: Yup.array(),
  remove_attachments: Yup.array(),
  opened_at: Yup.date()
    .nullable()
    .test(
      'lessThanClosed',
      'Opened date must be before or on Closed date',
      function (value) {
        const { closed_at } = this.parent
        if (!closed_at || !value) return true
        return new Date(value) <= new Date(closed_at)
      }
    ),
  closed_at: Yup.date()
    .nullable()
    .test(
      'greaterThanOpened',
      'Closed date must be after or on Opened date',
      function (value) {
        const { opened_at } = this.parent
        if (!opened_at || !value) {
          return true
        }
        return new Date(value) >= new Date(opened_at)
      }
    ),
})

export const initialActivityValues = {
  description: '',
  category: '',
}

export const activitySchema = Yup.object().shape({
  description: Yup.string().required('Description is required'),
  category: Yup.string(),
})
