import React, { useMemo } from 'react'

import { EditIcon } from '@chakra-ui/icons'
import {
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerCloseButton,
  Button,
  Divider,
  Text,
  HStack,
  Icon,
  Spacer,
  useDisclosure,
  ButtonGroup,
  useToast,
  Badge,
  Stack,
  Box,
} from '@chakra-ui/react'
import { UserIcon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'

import Constituent from './Constituent'
import { deleteGroup, loadGroups } from '../../../../redux/features/groupsSlice'
import ConfirmDeleteGroupDialog from '../ConfirmDeleteGroupDialog'

const DetailContent = ({ handleEditClick, onClose }) => {
  const dispatch = useDispatch()
  const toast = useToast()

  const user = useSelector(state => state.auth.user)
  const group = useSelector(state => state.groups.instance)
  const handleDelete = e => {
    e.stopPropagation()

    const callbackSuccess = () => {
      dispatch(loadGroups())
      onCloseDelete()
      onClose()
    }

    const callbackFailure = () => {
      toast({
        title: 'Something went wrong',
        description: `Could not delete group ${group.name}`,
        status: 'error',
      })
    }

    dispatch(deleteGroup({ id: group.id, callbackSuccess, callbackFailure }))
  }
  const {
    isOpen: deleteIsOpen,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure()

  const canDeleteGroup = useMemo(
    () =>
      (user.is_admin || user.district === group?.district) &&
      user.permissions.includes('directory.delete_constituentgroup'),
    [user, group]
  )
  if (!group) return null
  return (
    <>
      <DrawerHeader>
        <Box position='sticky' top={0} bg='var(--chakra-colors-chakra-body-bg)'>
          <Text fontSize='xl' variant='subheading-blue'>
            {group?.name} {group?.alias && `(${group.alias})`}
          </Text>
          {user.is_admin && group.district && (
            <Badge> DISTRICT {group.district}</Badge>
          )}
          <HStack align='center' gap={1}>
            <Icon as={UserIcon} fontSize='sm' />
            <Text variant='subheading-sm'>
              {group.member_count} Constituent
              {group.member_count !== 1 ? 's' : ''} in group
            </Text>
          </HStack>
          <DrawerCloseButton top={0} right={0} />
        </Box>
      </DrawerHeader>
      <DrawerBody>
        <Text>
          {group?.description || (
            <Text as='em' color='gray.400'>
              This group does not have a description.
            </Text>
          )}
        </Text>
        <Divider mt={2} mb={3} />
        <Text variant='subheading-blue' pb={2}>
          Constituents
        </Text>
        <Stack gap={0}>
          {group?.constituents?.map((c, index) => (
            <Constituent key={index} {...c} />
          ))}
        </Stack>
      </DrawerBody>
      <DrawerFooter>
        {canDeleteGroup && (
          <>
            <Button colorScheme='red' variant='link' onClick={onOpenDelete}>
              Delete Group
            </Button>
            <Spacer />
          </>
        )}
        <ButtonGroup>
          <Button variant='ghost' onClick={onClose}>
            Close
          </Button>
          <Button
            variant='outline'
            onClick={handleEditClick}
            leftIcon={<EditIcon />}
          >
            Edit
          </Button>
        </ButtonGroup>
      </DrawerFooter>
      <ConfirmDeleteGroupDialog
        group={group}
        isOpen={deleteIsOpen}
        onConfirm={handleDelete}
        onClose={onCloseDelete}
      />
    </>
  )
}

export default DetailContent
