import React, { useRef, useState, cloneElement } from 'react'

import { Button, ButtonGroup, IconButton } from '@chakra-ui/button'
import { HamburgerIcon } from '@chakra-ui/icons'
import {
  Tabs,
  TabList,
  Tab,
  Flex,
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
  InputRightElement,
  Popover,
  PopoverTrigger,
  useDisclosure,
  Badge,
  Menu,
  MenuButton,
  MenuList,
  Portal,
} from '@chakra-ui/react'
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline'

import { FilterHorizontal } from '../../assets/SvgIcons'

const TabsSearchFilter = ({
  clearSearch,
  updateSearch,
  selectTab,
  tabs,
  filterMenu,
  createModal,
  tabIndex,
  searchBar,
  actionMenu,
  onCreate,
  activeFilters,
}) => {
  const [searchInput, setSearchInput] = useState('')
  const [changedFlag, setChangedFlag] = useState(false)
  const filterButtonRef = useRef(null)

  const {
    isOpen: filterPopoverIsOpen,
    onClose: closeFilterPopover,
    onToggle: toggleFilterPopover,
  } = useDisclosure({ id: 'filterPopover' })

  const {
    isOpen: createIsOpen,
    onOpen: onOpenCreate,
    onClose: onCloseCreate,
  } = useDisclosure()

  const handleChangeTab = index => {
    selectTab(index)
    setSearchInput('')
    setChangedFlag(false)
  }

  const handleInputChange = e => {
    setSearchInput(e.target.value)
    setChangedFlag(true)
  }

  const handleSearchInput = () => {
    if (changedFlag) {
      updateSearch(searchInput)
      setChangedFlag(false)
    }
  }

  const handleClear = () => {
    setSearchInput('')
    clearSearch()
    setChangedFlag(false)
  }

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      handleSearchInput()
      setChangedFlag(false)
    }
  }

  return (
    <Popover
      isOpen={filterPopoverIsOpen}
      id='filterPopover'
      closeOnBlur={false}
    >
      <Tabs
        py={{ base: 2, sm: 4 }}
        px={{ base: 2, sm: 6 }}
        variant='enclosed'
        size={{ base: 'sm', sm: 'lg' }}
        isLazy
        isManual
        index={tabIndex}
        onChange={handleChangeTab}
        zIndex='1'
        className='tabs'
      >
        <Flex
          mx='auto'
          justify='space-between'
          gap={{ base: 2, sm: 4 }}
          direction={{ base: 'column', lg: 'row' }}
        >
          <TabList>
            {tabs.map((tab, index) => (
              <Tab key={index}> {tab} </Tab>
            ))}
          </TabList>
          <Flex
            direction={{ base: 'column', sm: 'row' }}
            flexGrow={1}
            align={{ sm: 'center' }}
            justify='space-between'
          >
            {!searchBar ? (
              <Flex flexGrow={1} mr={{ sm: '2' }} mb={{ base: 2, sm: 0 }}>
                <InputGroup size={{ base: 'md', sm: 'lg' }}>
                  <InputLeftElement pointerEvents='none'>
                    <Icon
                      as={MagnifyingGlassIcon}
                      fontSize={{ base: 'lg', sm: 'xl' }}
                    />
                  </InputLeftElement>
                  <Input
                    variant='filled'
                    id='searchBar'
                    placeholder={'Search for...'}
                    value={searchInput}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                    onBlur={handleSearchInput}
                    width='100%'
                  />
                  <InputRightElement>
                    {searchInput.length > 0 && (
                      <IconButton
                        icon={<XMarkIcon />}
                        aria-label='Search'
                        variant='unstyled'
                        color='gray.400'
                        size='sm'
                        onClick={handleClear}
                      />
                    )}
                  </InputRightElement>
                </InputGroup>
              </Flex>
            ) : (
              <>{searchBar}</>
            )}
            <ButtonGroup
              variant='outline'
              size={{ base: 'md', sm: 'lg' }}
              mx='auto'
            >
              {actionMenu && (
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label='Options'
                    icon={<HamburgerIcon />}
                    variant='outline'
                  />
                  <Portal>
                    <MenuList zIndex='popover'>{actionMenu}</MenuList>
                  </Portal>
                </Menu>
              )}
              {filterMenu && (
                <PopoverTrigger>
                  <Button
                    maxW='115px'
                    leftIcon={<FilterHorizontal width='24' />}
                    rightIcon={
                      !!activeFilters && (
                        <Badge
                          variant='solid'
                          bg='blue.400'
                          borderRadius='full'
                          p={1}
                        >
                          {activeFilters}
                        </Badge>
                      )
                    }
                    onClick={toggleFilterPopover}
                    ref={filterButtonRef}
                  >
                    Filter
                  </Button>
                </PopoverTrigger>
              )}
              {(createModal || onCreate) && (
                <Button
                  onClick={
                    createModal ? onOpenCreate : onCreate ? onCreate : () => {}
                  }
                  variant='solid'
                >
                  {'New ' +
                    (tabs[tabIndex] === 'Constituents'
                      ? 'Constituent'
                      : tabs[tabIndex] === 'Groups'
                      ? 'Group'
                      : tabs[tabIndex] === 'Casework'
                      ? 'Casework'
                      : '')}
                </Button>
              )}
            </ButtonGroup>
          </Flex>
        </Flex>
      </Tabs>
      {filterMenu &&
        cloneElement(filterMenu, {
          isOpen: filterPopoverIsOpen,
          filterButtonRef: filterButtonRef,
          onClose: closeFilterPopover,
        })}
      {createModal &&
        cloneElement(createModal, {
          isOpen: createIsOpen,
          onClose: onCloseCreate,
        })}
    </Popover>
  )
}

export default TabsSearchFilter
