import React from 'react'

import { Center, Text, Spinner, Stack } from '@chakra-ui/react'

const Loading = ({ message }) => {
  return (
    <Center h='50vh'>
      <Stack align='center'>
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
        />
        {message && <Text>{message}</Text>}
      </Stack>
    </Center>
  )
}

export default Loading
