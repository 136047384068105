import React, { createContext, useContext, useState } from 'react'

import { useDisclosure } from '@chakra-ui/react'

import { isEqualObject } from '../../../utils'

const SearchSelectContext = createContext()

export const useSearchSelect = () => useContext(SearchSelectContext)

export const SearchSelectProvider = ({ children }) => {
  const [selectedOptions, setSelectedOptions] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleSelectOption = option => {
    onClose()

    if (
      (typeof option.value === 'string' || typeof option.value === 'number') &&
      !!selectedOptions.find(({ value }) => value === option.value)
    )
      return

    if (
      typeof option.value === 'object' &&
      option.value !== null &&
      !Array.isArray(option.value) &&
      !!selectedOptions.find(o => isEqualObject(o.value, option.value))
    ) {
      return
    }

    setSearchTerm('')
    setSelectedOptions([...selectedOptions, option])
  }

  const handleDeselectOption = removeIndex => {
    setSelectedOptions(
      selectedOptions.filter((option, index) => index !== removeIndex)
    )
  }

  const clearSelectedOptions = () => {
    setSelectedOptions([])
  }

  return (
    <SearchSelectContext.Provider
      value={{
        selectedOptions,
        setSelectedOptions,
        searchTerm,
        setSearchTerm,
        isOpen,
        onOpen,
        onClose,
        handleSelectOption,
        handleDeselectOption,
        clearSelectedOptions,
      }}
    >
      {children}
    </SearchSelectContext.Provider>
  )
}
