import axios from 'axios' // no need for axios config
import { call, put, takeLatest } from 'redux-saga/effects'

import {
  loadAgenciesData,
  loadAgencyInstanceData,
  storeAgenciesData,
  storeAgencyInstanceData,
} from '../features/agenciesSlice'
import { setErrors } from '../features/directorySlice'

const agenciesAxios = axios.create({
  baseURL: `https://data.cityofnewyork.us/resource/mdcw-n682.json`,
  params: {
    $$app_token: process.env.REACT_APP_OPENDATA_API_APP_TOKEN,
  },
})

/** Split agencies by name, divisions, and then employees. */
function processAgencies(data) {
  let agencies = {}
  data.forEach(employee => {
    /** If seeing agency for the first time, create key */
    if (!(employee.agency_name in agencies)) {
      agencies[employee.agency_name] = { employees: [] }
      let agency_keys = ['agency_acronym', 'agency_primary_phone']
      agency_keys.forEach(key => {
        if (key in employee) agencies[employee.agency_name][key] = employee[key]
      })
    }
    const agency = agencies[employee.agency_name]
    agency.employees.push(employee)
  })
  return agencies
}

function* loadAgenciesDataSaga() {
  try {
    const response = yield call(() => {
      return agenciesAxios.get('', {
        params: {
          $limit: 10000,
        },
      })
    })

    const agenciesData = yield call(processAgencies, response.data)
    yield put(storeAgenciesData(agenciesData))
  } catch (error) {
    console.error(error)
    yield put(setErrors('Error loading agencies'))
  }
}

function* loadAgencyInstanceDataSaga(action) {
  try {
    const { agency_name } = action.payload
    const response = yield call(() => {
      return agenciesAxios.get('', {
        params: {
          agency_name: agency_name,
        },
      })
    })
    yield put(storeAgencyInstanceData(response.data))
  } catch (error) {
    console.error(error)
  }
}

function* agenciesAPI() {
  yield takeLatest(loadAgenciesData, loadAgenciesDataSaga)
  yield takeLatest(loadAgencyInstanceData, loadAgencyInstanceDataSaga)
}

export default agenciesAPI
