import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { paginate } from '../../../redux/features/caseworkSlice'
import { Pagination } from '../../ui'

const CaseworkPagination = ({ pageSize = 15 }) => {
  const dispatch = useDispatch()
  const count = useSelector(state => state.casework.count)
  const page = useSelector(state => state.casework.page)
  const casework = useSelector(state => state.casework.casework)
  const loading = useSelector(state => state.casework.loading)

  const lastPage = Math.ceil(count / pageSize)

  const handlePreviousPage = () => {
    dispatch(paginate('previous'))
  }

  const handleNextPage = () => {
    dispatch(paginate('next'))
  }

  if (casework?.length === 0 && !loading) {
    return <Pagination currentPage={0} totalPages={0} />
  }

  return (
    <Pagination
      results={count}
      currentPage={page}
      totalPages={lastPage}
      handleNextPage={handleNextPage}
      handlePreviousPage={handlePreviousPage}
    />
  )
}

export default CaseworkPagination
