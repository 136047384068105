import { React } from 'react'

import { Box, VStack, Text, Center, Flex } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'

import CouncilConnectLogo from '../../assets/CouncilConnectLogo'
import { clearErrors } from '../../redux/features/authSlice'
import { LoginForm, PasswordExpiredModal } from '../auth'

const Login = () => {
  const dispatch = useDispatch()
  const password_expired = useSelector(state => state.auth.password_expired)

  function closePasswordExpiredModal() {
    dispatch(clearErrors())
  }

  return (
    <Box position='relative' overflow='hidden'>
      {/* SVG Background */}
      <Box
        position='absolute'
        bottom={{ base: '-10px', md: '0px' }}
        left={{ base: '-10px', md: '0px' }}
        transform={{
          base: 'translate(-200px, 50px)',
          md: 'translate(-100px, 200px)',
        }}
        zIndex='0'
      >
        <Box
          as='svg'
          width={{ base: '1500px', md: '2000px' }}
          height='auto'
          viewBox='0 0 315 235'
        >
          <path
            stroke='#0b2741'
            fill='none'
            d='M196.583 59.1562L102.389 4L4 61.4544V173.682L102.389 230.753L196.583 176.363'
            strokeWidth='6.50811'
            strokeLinecap='round'
          />
          <path
            stroke='#0b2741'
            fill='none'
            d='M171.796 73.9185L102.036 33.1104L29.1699 75.6188V158.652L102.036 200.877L171.796 160.636'
            strokeWidth='3.25405'
            strokeLinecap='round'
          />
        </Box>
      </Box>
      <Center
        w='100vw'
        h='100vh'
        bgGradient='linear(to-br, teal.400, blue.500)'
      >
        <Box maxWidth='1200px' width='100%' zIndex='1'>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            height='100vh'
            justify='space-around'
            align='center'
          >
            <VStack display={{ base: 'none', md: 'block' }} spacing='35px'>
              <CouncilConnectLogo
                fill='white'
                stroke1='white'
                stroke2='white'
                width={315}
              />
              <Text
                textAlign='center'
                fontSize={{ md: 'lg' }}
                fontWeight='bold'
                color='white'
              >
                Powered by the
                <br />
                New York City Council&apos;s
                <br />
                Web Development Unit
              </Text>
            </VStack>
            <VStack
              flexDirection={{ base: 'column', md: 'row' }}
              alignItems='center'
            >
              <Box display={{ base: 'block', md: 'none' }}>
                <CouncilConnectLogo
                  fill='white'
                  stroke1='white'
                  stroke2='white'
                  width={130}
                />
              </Box>
              <Text
                width={{ base: '80%', md: '100%' }}
                display={{ base: 'block', md: 'none' }}
                textAlign='center'
                fontSize='sm'
                fontWeight='bold'
                color='white'
                pb={2}
              >
                Powered by the New York City Council&apos;s Web Development Unit
              </Text>
              <LoginForm />
              <PasswordExpiredModal
                isOpen={password_expired}
                onClose={closePasswordExpiredModal}
              />
            </VStack>
          </Flex>
        </Box>
      </Center>
    </Box>
  )
}

export default Login
