import { call, put, select, takeLatest } from 'redux-saga/effects'

import { downloadExcelFile, getFilterQueryString } from '../../utils'
import backendAPI from '../axiosConfig'
import {
  completeExport,
  exportReport,
  generateReport,
  setResults,
} from '../features/reportSlice'

const REPORTS_ENDPOINT = '/api/casework/reports/'
function* generateReportSaga(action) {
  const filters = action.payload

  try {
    const response = yield call(() => {
      return backendAPI.get(
        REPORTS_ENDPOINT + `?${getFilterQueryString(filters)}`
      )
    })

    yield put(setResults(response.data))
  } catch (error) {
    yield put(setResults(null))
    console.error(error)
  }
}

function* exportReportSaga() {
  try {
    const data = yield select(state => state.reports.results)
    if (!data) return
    const response = yield call(() => {
      return backendAPI.post(REPORTS_ENDPOINT + 'export/', data, {
        responseType: 'arraybuffer',
      })
    })

    yield call(downloadExcelFile, response.data, 'casework_report.xlsx')
  } catch (error) {
    console.error(error)
  }
  yield put(completeExport())
}

function* reportsAPI() {
  yield takeLatest(generateReport, generateReportSaga)
  yield takeLatest(exportReport, exportReportSaga)
}

export default reportsAPI
