import React from 'react'

import { Text, useToast } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'

import {
  loadCaseworkInstance,
  updateCaseworkInstance,
} from '../../../../redux/features/caseworkSlice'
import { calculateMS } from '../../../../utils'
import { ConfirmActionDialog } from '../../../ui'

const UnarchiveDialog = ({ case_num, ...props }) => {
  const dispatch = useDispatch()
  const toast = useToast()

  const handleConfirm = () => {
    dispatch(
      updateCaseworkInstance({
        values: {
          case_num,
          status: 'Unarchived',
        },
        callbackSuccess: () => {
          dispatch(loadCaseworkInstance({ case_num }))
          toast({
            title: 'Successfully unarchived casework',
            status: 'info',
            duration: calculateMS(3),
          })
        },
        callbackFailure: () => {
          toast({
            title: 'Something went wrong trying to unarchive this casework.',
            status: 'error',
            duration: calculateMS(3),
          })
        },
      })
    )
    props.onClose()
  }

  return (
    <ConfirmActionDialog onConfirm={handleConfirm} {...props}>
      <Text as='b'> This casework will be unarchived. </Text>
      <Text>
        This casework will be restored to the status prior to archiving.
      </Text>
    </ConfirmActionDialog>
  )
}

export default UnarchiveDialog
