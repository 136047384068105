import React from 'react'

import {
  Card,
  CardBody,
  Flex,
  Heading,
  Spacer,
  useColorModeValue,
} from '@chakra-ui/react'

import { changes } from './changes'
import PatchNotesModal from './PatchNotesModal'

const VERSION = changes[0].version
const UPDATE_DATE = changes[0].date
const PatchNotes = () => {
  const bgColor = useColorModeValue('blue.100', 'blue.700')
  return (
    <Card bg={bgColor}>
      <CardBody>
        <Flex align='center'>
          <Heading size='sm' mr={3} fontSize={{ base: 'sm', sm: 'md' }}>
            Version {VERSION} | {UPDATE_DATE}
          </Heading>
          <Spacer />
          <PatchNotesModal />
        </Flex>
      </CardBody>
    </Card>
  )
}

export default PatchNotes
