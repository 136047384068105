import React from 'react'

import { Box, Text, useToast } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'

import {
  deleteCaseworkActivity,
  loadCaseworkActivity,
} from '../../../../redux/features/caseworkSlice'
import { ConfirmActionDialog } from '../../../ui'
import { renderTextLines } from '../../../utils'

const DeleteActivityDialog = ({ activityId, activityText, ...props }) => {
  const dispatch = useDispatch()
  const toast = useToast()

  const handleConfirm = () => {
    dispatch(
      deleteCaseworkActivity({
        id: activityId,
        callbackSuccess: () => {
          toast({
            title: `Successfully deleted activity`,
            status: 'success',
          })
          dispatch(loadCaseworkActivity())
        },
        callbackFailure: () => {
          toast({
            title: `An error occurred attempting to delete activity`,
            status: 'error',
          })
        },
      })
    )
    props.onClose()
  }

  return (
    <ConfirmActionDialog {...props} onConfirm={handleConfirm} scheme='delete'>
      <Text fontWeight='bold'>
        This activity will be permanently deleted and cannot be recovered.
      </Text>

      <Box borderLeft='4px solid #111' pl='4' fontStyle='italic' my='4'>
        {renderTextLines(activityText)}
      </Box>
      <Text> Are you sure you want to proceed?</Text>
    </ConfirmActionDialog>
  )
}

export default DeleteActivityDialog
