import React from 'react'

import {
  Box,
  Heading,
  Link,
  Text,
  Center,
  VStack,
  useColorModeValue,
  Tooltip,
} from '@chakra-ui/react'
import { useSelector } from 'react-redux'

import EmployeesTable from './EmployeesTable'
import { NotFound } from '../../ui'

const AgencyTable = ({ selectedAgency }) => {
  const agenciesData = useSelector(state => state.agencies.data)
  const linkColor = useColorModeValue('blue.700', 'blue.400')

  if (!agenciesData) return <NotFound />

  return (
    <Box maxW='7xl' mx='auto' pt={3}>
      <VStack>
        <Center>
          <Text mb={2} casing='uppercase' fontWeight='bold' fontSize='xl'>
            <Tooltip hasArrow label='Open Green Book Online' placement='top'>
              <Link
                href='https://a856-gbol.nyc.gov/GBOLWebsite/GreenBook/Online'
                target='_blank'
                color={linkColor}
                _hover={{ filter: 'brightness(80%)' }}
              >
                Greenbook
              </Link>
            </Tooltip>
            &nbsp;Agency Lookup
          </Text>
        </Center>
        <Heading size='md'> {selectedAgency} </Heading>
        <Box w='100%'>
          {selectedAgency && agenciesData ? (
            <Box maxH='calc(100vh - 324px)' overflowY='auto' p={0}>
              <EmployeesTable
                employees={agenciesData[selectedAgency]?.employees}
              />
            </Box>
          ) : (
            <Center mx='auto'>
              <Text as='i'>Search for an agency above to load results</Text>
            </Center>
          )}
        </Box>
      </VStack>
    </Box>
  )
}

export default AgencyTable
