import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  errors: null,
  next: null,
}

export const profileSlice = createSlice({
  initialState,
  name: 'profile',
  reducers: {
    updateProfile() {},
    setErrors: (state, action) => {
      state.errors = action.payload
      state.loading = false
    },
  },
})

export default profileSlice.reducer

export const { setErrors, updateProfile } = profileSlice.actions
