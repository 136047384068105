const activeLabelStyles = {
  top: 0,
  transform: 'scale(0.85) translateY(-24px)',
  color: 'var(--chakra-colors-chakra-body-text)',
}

export const formTheme = {
  variants: {
    floating: {
      container: {
        _focusWithin: {
          label: {
            ...activeLabelStyles,
          },
        },
        'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
          {
            ...activeLabelStyles,
          },
        label: {
          top: 1,
          left: 0,
          zIndex: 2,
          position: 'absolute',
          backgroundColor: 'white',
          _dark: {
            bg: 'gray.700',
          },
          pointerEvents: 'none',
          color: 'var(--chakra-colors-chakra-placeholder-color)',
          mx: 3,
          px: 1,
          my: 2,
          transformOrigin: 'left top',
        },
      },
    },
  },
}
