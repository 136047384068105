import { React, useEffect } from 'react'

import {
  Button,
  FormControl,
  Spacer,
  VStack,
  Box,
  Center,
  Heading,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'

import {
  resetPassword,
  validateResetToken,
} from '../../redux/features/authSlice'
import { calculateMS } from '../../utils'
import { PasswordCriteria } from '../auth'
import { PasswordInput } from '../ui'

const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})/,
      'New password does not satisfy all conditions above.'
    )
    .required('Required'),
  confirmPassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

const ResetPassword = () => {
  const { token } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const toast = useToast()

  const validationErrors = useSelector(
    state => state.auth.password_validation_errors
  )

  useEffect(() => {
    // validate token
    dispatch(
      validateResetToken({
        token,
        callbackFailure: () => navigate('/'),
      })
    )
  }, [dispatch, token])

  const initialValues = {
    password: '',
    confirmPassword: '',
    token: token,
  }

  document.title = 'Reset Password | Council Connect'

  return (
    <Center w='100vw' h='100vh' bgGradient='linear(to-br, teal.400, blue.500)'>
      <Box
        p={{ base: '1.5rem', md: '2rem' }}
        borderRadius={10}
        width={{ base: '85vw', md: '450px' }}
        minWidth='250px'
        boxShadow='lg'
        bg='white'
        zIndex={1}
      >
        <Heading as='h2' size='lg' textAlign='center' mb={5}>
          Reset Password
        </Heading>
        <Formik
          initialValues={initialValues}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={PasswordSchema}
          onSubmit={(values, { setSubmitting }) => {
            const toastSettings = {
              duration: calculateMS(5),
              isClosable: true,
              position: 'top',
            }
            const onResetSuccess = () => {
              setSubmitting(false)
              toast({
                title: 'Password successfully reset',
                status: 'success',
                description:
                  'Your password was successfully changed. Please log in using your new credentials.',
                ...toastSettings,
              })
              navigate('/')
            }

            const onResetFailure = error => {
              if (!error) return
              const status = error.status
              setSubmitting(false)
              if (status === 404) {
                toast({
                  title: 'The password reset link has expired',
                  description:
                    'Please request a new one under Forgot Password?',
                  status: 'error',
                  ...toastSettings,
                })
                navigate('/')
              }

              const { password } = error.data

              if (password) {
                toast({
                  title: 'Invalid password',
                  status: 'error',
                  description: `${password[0]}`,
                  duration: calculateMS(2.5),
                  isClosable: true,
                  position: 'bottom',
                })
              }
            }

            setSubmitting(true)
            dispatch(
              resetPassword({
                values,
                callbackSuccess: onResetSuccess,
                callbackFailure: onResetFailure,
              })
            )
          }}
        >
          {({ handleSubmit, values, errors, touched, isSubmitting }) => (
            <>
              <PasswordCriteria
                password={values.password}
                confirmPassword={values.confirmPassword}
              />
              <Spacer height='16px' />

              <Form onSubmit={handleSubmit} autoComplete='off'>
                <VStack spacing={4}>
                  <FormControl
                    id='password'
                    isInvalid={
                      (errors.password && touched.password) || validationErrors
                    }
                  >
                    <PasswordInput placeholder={'Enter new password'} />
                    <FormErrorMessage>{errors.password}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    id='confirmPassword'
                    isInvalid={
                      errors.confirmPassword && touched.confirmPassword
                    }
                  >
                    <PasswordInput
                      id='confirmPassword'
                      name='confirmPassword'
                      placeholder={'Confirm password'}
                    />
                    <FormErrorMessage>
                      {errors.confirmPassword}
                    </FormErrorMessage>
                  </FormControl>
                  <Spacer height='16px' />

                  <Button
                    width='100%'
                    borderRadius='full'
                    type='submit'
                    size={{ base: 'md', md: 'lg' }}
                    isLoading={isSubmitting}
                  >
                    Reset Password
                  </Button>
                </VStack>
              </Form>
            </>
          )}
        </Formik>
      </Box>
    </Center>
  )
}

export default ResetPassword
