import * as Yup from 'yup'

// Custom validation schema for tools
export const addressValidationSchema = Yup.object().shape({
  address: Yup.object().shape({
    line1: Yup.string().required('This field is required'),
    line2: Yup.string(),
    city: Yup.string().required('This field is required'),
    state: Yup.string(),
    zipcode: Yup.string(),
  }),
})
