import React, { useEffect, useState } from 'react'

import { Box, Button, Tab, TabList, Tabs } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'

import {
  clearConstituentInstance,
  loadConstituentInstance,
} from '../../redux/features/constituentsSlice'
import { CaseworkAccordion } from '../casework'
import { ConstituentChangelog, ConstituentProfile } from '../directory'
import { Loading, NotFound } from '../ui'

const ConstituentDetail = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const loading = useSelector(state => state.constituents.loading)
  const constituent = useSelector(state => state.constituents.instance)

  const [tab, setTab] = useState(0)

  useEffect(() => {
    dispatch(loadConstituentInstance({ id }))
    return () => {
      dispatch(clearConstituentInstance())
    }
  }, [dispatch, id])

  if (loading) return <Loading />
  if (!constituent) return <NotFound />

  document.title = `${constituent.full_name} - Constituent | Council Connect`

  return (
    <Box w='100%' h='100%'>
      <Box mx='auto' maxW='7xl'>
        <Tabs
          size='lg'
          pb={3}
          index={tab}
          onChange={index => setTab(index)}
          align='start'
        >
          <TabList>
            <Tab>Profile</Tab>
            <Tab>Casework</Tab>
            <Tab>Changelog</Tab>
          </TabList>
        </Tabs>
        {tab === 0 && <ConstituentProfile />}
        {tab === 1 && (
          <>
            <Button
              position='absolute'
              zIndex='docked'
              bottom='10'
              right='5'
              onClick={() =>
                navigate('/casework/create', {
                  state: {
                    constituent: constituent,
                  },
                })
              }
            >
              Create New Casework
            </Button>
            <Box maxH='80vh' overflowY='auto' px={2} pb={2} overflowX='hidden'>
              <CaseworkAccordion
                casework={constituent.casework}
                hideConstituent
              />
            </Box>
          </>
        )}
        {tab === 2 && (
          <Box h='calc(100vh - 183px)' overflowY='auto'>
            <ConstituentChangelog changelog={constituent.changelog} />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default ConstituentDetail
