import React from 'react'

import { Icon } from '@chakra-ui/icon'
import { Text, Stack, Center, Heading } from '@chakra-ui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

const NotFound = ({ message }) => {
  return (
    <Center h='50vh' align='center'>
      <Stack>
        <Center>
          <Icon as={ExclamationTriangleIcon} mr={2} boxSize={8} />
          <Heading fontSize='2xl'> 404 Not Found </Heading>
        </Center>
        {message && <Text> {message} </Text>}
      </Stack>
    </Center>
  )
}

export default NotFound
