import React, { useRef } from 'react'

import {
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Stack,
  Text,
  Spacer,
  StackDivider,
  useBoolean,
  Icon,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { PlusIcon } from '@heroicons/react/24/solid'
import { useSelector } from 'react-redux'

import ActivityForm from './ActivityForm'
import ActivityItem from './ActivityItem'
import { NotFound } from '../../ui'

const ActivityCard = () => {
  const caseworkInstance = useSelector(
    state => state.casework.casework_instance
  )
  const [showForm, setShowForm] = useBoolean()
  const bodyRef = useRef(null)

  const cardHeaderBg = useColorModeValue('blue.300', 'blue.600')

  if (!caseworkInstance) {
    return <NotFound />
  }
  return (
    <Card borderTopRadius='xl' maxW={{ base: '100%', lg: 'lg' }}>
      <CardHeader bgColor={cardHeaderBg} borderTopRadius='xl'>
        <Flex align='center'>
          <Text casing='uppercase' as='b' fontSize='lg'>
            Activity
          </Text>
          <Spacer />
          {caseworkInstance.status !== 'Archived' && (
            <Tooltip
              label={showForm ? 'Cancel add activity' : 'Add new activity'}
              openDelay={400}
            >
              <Icon
                as={showForm ? XMarkIcon : PlusIcon}
                color={showForm ? 'red.900' : 'white'}
                onClick={() => {
                  bodyRef.current.scrollTo({ top: 0, behavior: 'smooth' })
                  setShowForm.toggle()
                }}
                boxSize={6}
                _hover={{
                  cursor: 'pointer',
                  borderRadius: 'full',
                  bgColor: 'blue.500',
                }}
              />
            </Tooltip>
          )}
        </Flex>
      </CardHeader>
      <Divider width='100%' color='gray.300' />
      <CardBody overflowY='auto' maxHeight='60vh' ref={bodyRef}>
        <Stack divider={<StackDivider />} spacing={4}>
          {showForm && <ActivityForm handleFormSubmit={setShowForm.off} />}
          {caseworkInstance?.activity?.map((a, index) => (
            <ActivityItem key={index} {...a} />
          ))}
        </Stack>
      </CardBody>
    </Card>
  )
}

export default ActivityCard
