import React from 'react'

import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  Textarea,
} from '@chakra-ui/react'
import { Field, Form } from 'formik'

import AddRemoveConstituents from './AddRemoveConstituents'
import { capitalize } from '../../../../utils'

const GroupFormField = ({ field, inputType = Input, isRequired }) => {
  return (
    <FormControl
      variant='floating'
      id={field}
      name={field}
      isRequired={isRequired}
    >
      <Field
        placeholder=' '
        as={inputType}
        id={field}
        name={field}
        autoComplete='off'
      />
      <FormLabel htmlFor={field}>{capitalize(field)}</FormLabel>
    </FormControl>
  )
}

const GroupForm = () => {
  return (
    <Form>
      <Stack gap={4}>
        <GroupFormField field='name' isRequired />
        <GroupFormField field='alias' />
        <GroupFormField field='description' inputType={Textarea} />
        <AddRemoveConstituents />
      </Stack>
    </Form>
  )
}

export default GroupForm
