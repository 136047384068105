import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import CaseworkAccordion from './CaseworkAccordion'
import {
  clearCasework,
  loadCasework,
} from '../../../redux/features/caseworkSlice'
import { Loading, NoResults, NotFound } from '../../ui'

const CaseworkList = () => {
  const dispatch = useDispatch()
  const loading = useSelector(state => state.casework.loading)
  const casework = useSelector(state => state.casework.casework)

  const tabIndex = useSelector(state => state.casework.tab_index)
  useEffect(() => {
    dispatch(loadCasework())

    return () => {
      dispatch(clearCasework())
    }
  }, [tabIndex])

  if (loading) {
    return <Loading />
  }

  if (!casework) {
    return <NotFound />
  }

  if (casework?.length === 0) {
    return <NoResults />
  }

  return <CaseworkAccordion casework={casework} />
}

export default CaseworkList
