import { initialCaseworkValues } from '../../../../constants'
import { isAddressEmpty } from '../../../utils'

export const formatContactInfo = contactInfo => {
  return contactInfo.filter(
    ({ contact_type, contact_data }) => !!contact_type && !!contact_data
  )
}
export const formatFormValues = (values, constituentMode = undefined) => {
  const formattedValues = { ...values }

  formattedValues.newConstituent = {
    ...values.newConstituent,
  }

  if (constituentMode === 'create') {
    delete formattedValues.constituent
    if (isAddressEmpty(formattedValues.newConstituent.home_address)) {
      delete formattedValues.newConstituent.home_address
      formattedValues.newConstituent.primary_address = 'business'
    }
    if (isAddressEmpty(formattedValues.newConstituent.business_address)) {
      delete formattedValues.newConstituent.business_address
      formattedValues.newConstituent.primary_address = 'home'
    }

    formattedValues.newConstituent.contact_info = formatContactInfo(
      formattedValues.newConstituent.contact_info
    )
  } else {
    delete formattedValues.newConstituent
    formattedValues.constituent = values.constituent?.id || null
  }

  if (isAddressEmpty(formattedValues.address)) formattedValues.address = null

  formattedValues.assigned_staff = values.assigned_staff?.map(s => s.id) || []
  formattedValues.referral_staff = values.referral_staff?.id || null
  formattedValues.topics = values.topics.map(topic => topic.id) || null

  if (formattedValues.closed_at) formattedValues.status = 'Closed'

  if (!formattedValues.opened_at) formattedValues.opened_at = null
  if (!formattedValues.closed_at) formattedValues.closed_at = null

  return formattedValues
}

export const formatInitialValues = values => {
  let formattedValues = { ...values }
  for (let key in values) {
    if (formattedValues[key] === null || formattedValues[key] === undefined) {
      formattedValues[key] = initialCaseworkValues[key]
    }
  }
  return formattedValues
}
