import React, { useState } from 'react'

import {
  Box,
  Text,
  VStack,
  Icon,
  Input,
  Flex,
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Link,
  useColorModeValue,
  Stack,
} from '@chakra-ui/react'
import {
  ArrowRightIcon,
  MagnifyingGlassCircleIcon,
} from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'

import { clearSearchResults, quickSearch } from '../../redux/features/homeSlice'
import { Loading, NoResults } from '../ui'
import { formatCaseNum } from '../utils'

const QuickSearch = ({ isOpen, onClose }) => {
  const [searchValue, setSearchValue] = useState('')
  const searchResults = useSelector(state => state.home.search_results)
  const loading = useSelector(state => state.home.loading)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const debouncedSearchChange = useDebouncedCallback(e => {
    let search = e.target.value.trim()
    if (search.length === 0) dispatch(clearSearchResults())
    else dispatch(quickSearch(search))
  }, 300)

  const ResultLink = ({ onClick = () => {}, children }) => (
    <Box px={2} py={1}>
      <Link
        as={Flex}
        alignItems='center'
        px='4'
        py='3'
        borderRadius='md'
        cursor='pointer'
        bg={useColorModeValue('gray.100', 'gray.600')}
        _hover={{ bg: useColorModeValue('gray.200', 'gray.500') }}
        onClick={onClick}
      >
        {children}
        <Spacer />
        <Icon as={ArrowRightIcon} fontSize={22}></Icon>
      </Link>
    </Box>
  )

  const CaseworkResults = () => {
    const casework = searchResults?.casework
    if (!(casework?.length > 0)) return <></>

    return (
      <Box>
        <Text px={4} pt={4} casing='uppercase' fontWeight='bold'>
          Casework
        </Text>
        {searchResults.casework.map((c, index) => (
          <ResultLink
            onClick={() => navigate(`/casework/${c.case_num}`)}
            key={index}
          >
            <VStack align='stretch'>
              <Text fontWeight='bold'>
                #{formatCaseNum(c)}: {c.constituent.full_name}
              </Text>

              <Text noOfLines={2}>{c.details}</Text>
            </VStack>
          </ResultLink>
        ))}
      </Box>
    )
  }

  const ConstituentsResults = () => {
    const constituents = searchResults?.constituents
    if (!(constituents?.length > 0)) return <></>

    return (
      <Box>
        <Text px={4} pt={4} casing='uppercase' fontWeight='bold'>
          Constituents
        </Text>
        {searchResults.constituents.map((c, index) => (
          <ResultLink
            key={index}
            onClick={() => navigate(`/directory/constituents/${c.id}`)}
          >
            <VStack align='stretch'>
              <Text fontWeight='bold'>{c.full_name}</Text>
              {c.home_address && (
                <Text>(Home) {c.home_address?.full_address} </Text>
              )}
              {c.business_address && (
                <Text>(Business) {c.business_address?.full_address}</Text>
              )}
            </VStack>
          </ResultLink>
        ))}
      </Box>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setSearchValue('')
        dispatch(clearSearchResults())
        onClose()
      }}
      size='2xl'
      scrollBehavior='inside'
    >
      <ModalOverlay />
      <ModalContent mx={{ base: '3', md: '0' }}>
        <ModalHeader p='0'>
          <Flex alignItems='center' justifyContent='center'>
            <Icon
              position='absolute'
              left='5px'
              as={MagnifyingGlassCircleIcon}
              height='35px'
              width='35px'
              color='gray.300'
            />
            <Input
              pl='50px'
              fontSize='lg'
              height='68px'
              _focusVisible={{ border: 'none' }}
              value={searchValue}
              onChange={e => {
                setSearchValue(e.target.value)
                debouncedSearchChange(e)
              }}
              placeholder='Search for a constituent or casework'
            />
          </Flex>
        </ModalHeader>
        <ModalBody p={0}>
          {loading ? (
            <Loading />
          ) : (
            searchValue && (
              <Stack p={2}>
                <CaseworkResults />
                <ConstituentsResults />
                {searchResults?.casework?.length === 0 &&
                  searchResults?.constituents?.length === 0 && (
                    <Box mb={10}>
                      <NoResults />
                    </Box>
                  )}
              </Stack>
            )
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default QuickSearch
