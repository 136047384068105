import React, { useCallback, useEffect, useState } from 'react'

import { Drawer, DrawerContent, DrawerOverlay } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'

import DetailContent from './DetailContent'
import EditContent from './EditContent'
import { loadGroupInstance } from '../../../../redux/features/groupsSlice'

const DetailDrawer = ({ id, isOpen, onClose }) => {
  const dispatch = useDispatch()

  const group = useSelector(state => state.groups.instance)
  const [mode, setMode] = useState('detail') // detail/edit mode state
  useEffect(() => {
    dispatch(loadGroupInstance({ id }))
  }, [id])

  const handleEditClick = useCallback(() => setMode('edit'), [setMode])
  const handleSaveEdits = useCallback(() => setMode('detail'), [setMode])
  if (!group) return <></>
  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
      isLazy
      size='md'
    >
      <DrawerOverlay />
      <DrawerContent>
        {mode === 'detail' ? (
          <DetailContent onClose={onClose} handleEditClick={handleEditClick} />
        ) : (
          mode === 'edit' && (
            <EditContent onClose={onClose} handleSaveEdits={handleSaveEdits} />
          )
        )}
      </DrawerContent>
    </Drawer>
  )
}

export default DetailDrawer
