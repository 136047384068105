import React from 'react'

import { Tag, TagCloseButton, Wrap, WrapItem } from '@chakra-ui/react'

import { useSearchSelect } from './SearchSelectContext'

const SearchSelectedTags = ({ onRemove = () => {} }) => {
  const { selectedOptions, handleDeselectOption } = useSearchSelect()

  const handleRemove = index => {
    onRemove(index)
    handleDeselectOption(index)
  }

  return (
    <Wrap w='100%' align='start' mt={2}>
      {selectedOptions.map((option, index) => (
        <WrapItem key={`selected-option-${index}`}>
          <Tag>
            {option.label}
            <TagCloseButton onClick={() => handleRemove(index)} />
          </Tag>
        </WrapItem>
      ))}
    </Wrap>
  )
}

export default SearchSelectedTags
