import React from 'react'

import {
  Button,
  FormControl,
  Input,
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { useDispatch } from 'react-redux'

import { forgotPassword } from '../../redux/features/authSlice'
const ForgotPasswordModal = ({ isOpen, onClose }) => {
  const toast = useToast()
  const dispatch = useDispatch()

  const initialValues = {
    email: '',
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Forgot Password</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true)
              dispatch(forgotPassword(values))
              toast({
                title: 'Email sent',
                status: 'info',
                description:
                  'If your account exists in our system, an email will be sent to you with a password reset link shortly.',
                position: 'top',
              })
              setSubmitting(false)
              onClose()
            }}
          >
            {({ handleSubmit, handleChange, values, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <FormControl id='email'>
                  <Input
                    id='email'
                    name='email'
                    type='email'
                    size={{ base: 'md', md: 'lg' }}
                    variant='filled'
                    placeholder='Enter email address'
                    onChange={handleChange}
                    value={values.email}
                  />
                </FormControl>
                <Spacer height='16px' />

                <Button
                  width='100%'
                  borderRadius='full'
                  type='submit'
                  size={{ base: 'md', md: 'lg' }}
                  isDisabled={values.email === ''}
                  isLoading={isSubmitting}
                >
                  Send Reset Password Link
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>

        <ModalFooter>Council Connect</ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ForgotPasswordModal
