import { createSlice } from '@reduxjs/toolkit'

export const homeSlice = createSlice({
  initialState: {
    loading: false,
    search_results: null,
  },
  name: 'home',
  reducers: {
    quickSearch(state) {
      state.loading = true
      state.search_results = null
    },
    storeSearchResults(state, action) {
      state.loading = false
      state.search_results = action.payload
    },
    clearSearchResults(state) {
      state.loading = false
      state.search_results = null
    },
  },
})

export default homeSlice.reducer

export const { quickSearch, storeSearchResults, clearSearchResults } =
  homeSlice.actions
