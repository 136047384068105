import React from 'react'

import { Box, Flex, Heading, Icon, useColorModeValue } from '@chakra-ui/react'

const ActionButton = ({ onClick = () => {}, icon, children }) => {
  const bgGradient = useColorModeValue('blue.200', 'blue.700')
  const hoverColors = useColorModeValue('blue.300', 'blue.800')
  const iconBg = useColorModeValue('nyccBlue.800', 'blue.500')
  return (
    <Box
      as={Flex}
      shadow='sm'
      _hover={{
        shadow: 'lg',
        bg: hoverColors,
      }}
      direction='row'
      p={2}
      w='100%'
      bg={bgGradient}
      borderRadius='full'
      textIndent={15}
      align='center'
      onClick={onClick}
      cursor='pointer'
    >
      <Icon
        as={icon}
        w='60px'
        h='60px'
        bg={iconBg}
        color='whiteAlpha.800'
        p={3}
        borderRadius='full'
        shadow='lg'
        onClick={onClick}
        cursor='pointer'
      />
      <Heading fontSize='xl'>{children}</Heading>
    </Box>
  )
}

export default ActionButton
