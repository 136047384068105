import { createSlice } from '@reduxjs/toolkit'

import { STORED_TOOLS_TAB } from '../../constants'

const previousTab = JSON.parse(sessionStorage.getItem(STORED_TOOLS_TAB))

const initialState = {
  district_info: null,
  loading: false,
  errors: null,
  tab_index: previousTab || 0,
}

export const toolsSlice = createSlice({
  initialState,
  name: 'tools',
  reducers: {
    generateDistricts(state) {
      state.loading = true
      state.district_info = null
    },
    storeDistrictInfo(state, action) {
      state.loading = false
      state.district_info = action.payload
    },
    setErrors(state, action) {
      state.loading = false
      state.errors = action.payload
    },
    setTabIndex(state, action) {
      const tab = action.payload
      state.tab_index = tab

      sessionStorage.setItem(STORED_TOOLS_TAB, tab)
    },
  },
})

export default toolsSlice.reducer

export const { generateDistricts, storeDistrictInfo, setErrors, setTabIndex } =
  toolsSlice.actions
