import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [],
  loading: false,
  errors: null,
}

export const agenciesSlice = createSlice({
  initialState,
  name: 'agencies',
  reducers: {
    loadAgenciesData(state) {
      state.loading = true
      state.data = []
    },
    storeAgenciesData(state, action) {
      state.loading = false
      state.data = action.payload
    },
    loadAgencyInstanceData(state) {
      state.loading = true
      state.data = []
    },
    storeAgencyInstanceData(state, action) {
      state.loading = false
      state.data = action.payload
    },
    setErrors(state, action) {
      state.loading = false
      state.errors = action.payload
    },
  },
})

export default agenciesSlice.reducer

export const {
  loadAgenciesData,
  storeAgenciesData,
  loadAgencyInstanceData,
  storeAgencyInstanceData,
  setErrors,
} = agenciesSlice.actions
