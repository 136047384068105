import React from 'react'

import { LockIcon } from '@chakra-ui/icons'
import {
  Avatar,
  Badge,
  Box,
  Card,
  CardBody,
  Center,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  Spacer,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { PencilSquareIcon } from '@heroicons/react/24/outline'
import { useSelector } from 'react-redux'

import ConstituentMenu from './ConstituentMenu'
import { NotFound } from '../../../ui'

const NameBox = ({ handleClick }) => {
  const constituent = useSelector(state => state.constituents.instance)
  if (!constituent) return <NotFound />

  const { full_name, district, title, casework, visible_to_district_only } =
    constituent

  return (
    <Stack direction={{ base: 'column', sm: 'row' }}>
      <Center>
        <Avatar bg='blue.400' size='xl' />
      </Center>
      <Card w='100%' boxShadow='0px 1px 3px 1px rgba(0, 0, 0, 0.1)'>
        <CardBody>
          <Flex direction={{ base: 'column', sm: 'row' }} align='center'>
            <HStack>
              <Heading>{full_name}</Heading>
            </HStack>

            <Spacer />
            <Box align='bottom'>
              {visible_to_district_only && (
                <Tooltip label='Constituent is visible to district only'>
                  <IconButton
                    icon={<LockIcon boxSize={6} />}
                    variant='unstyled'
                  />
                </Tooltip>
              )}
              <IconButton
                icon={<Icon as={PencilSquareIcon} boxSize={6} />}
                variant='ghost'
                onClick={handleClick}
              />
              <ConstituentMenu casework={casework} district={district} />
            </Box>
          </Flex>
          {title && (
            <Text casing='uppercase' fontWeight='bold' color='gray.400'>
              {title}
            </Text>
          )}
          <Badge> District {district} </Badge>
        </CardBody>
      </Card>
    </Stack>
  )
}

export default NameBox
