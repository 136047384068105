import React, { useState } from 'react'

import {
  Card,
  CardBody,
  HStack,
  Heading,
  Icon,
  Stack,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
  Wrap,
  WrapItem,
  useDisclosure,
} from '@chakra-ui/react'
import {
  AtSymbolIcon,
  BuildingOfficeIcon,
  BuildingStorefrontIcon,
  DocumentIcon,
  HomeIcon,
  PhoneIcon,
  UsersIcon,
} from '@heroicons/react/24/outline'
import { useSelector } from 'react-redux'

import { ConstituentCard } from '..'
import { AddressText, NotFound } from '../../../ui'
import { renderTextLines } from '../../../utils'
import DetailDrawer from '../../groups/GroupDetail/DetailDrawer'

const ContactLabel = ({ category }) => {
  const categoryIcon = () => {
    if (category.includes('Phone')) return PhoneIcon
    if (category.includes('Email')) return AtSymbolIcon
    if (category.includes('Business')) return BuildingStorefrontIcon
    if (category.includes('Home')) return HomeIcon
    if (category.includes('Address')) return BuildingOfficeIcon
    return DocumentIcon
  }

  return (
    <HStack align='center'>
      <Icon as={categoryIcon()} boxSize={5} />
      <Text as='b'> {category} </Text>
    </HStack>
  )
}

const GroupLabel = ({ group_name, role = '', onClick }) => (
  <HStack onClick={onClick} _hover={{ cursor: 'pointer' }}>
    <Icon as={UsersIcon} boxSize={6} />
    <Text as='b'> {group_name} </Text>
    <Text as='em' color='gray.400'>
      {role?.toUpperCase()}
    </Text>
  </HStack>
)

const InfoCard = ({ heading, children }) => (
  <Card my={3}>
    <CardBody>
      <Heading size='md' mb={1} color='blue.400'>
        {heading}
      </Heading>
      {children}
    </CardBody>
  </Card>
)

const TableRow = ({ category, children }) => (
  <Tr>
    <Td
      sx={{
        verticalAlign: 'top',
        w: '30%',
        pl: 0,
      }}
    >
      <ContactLabel category={category} />
    </Td>
    <Td
      sx={{
        verticalAlign: 'top',
        px: 0,
        py: 3,
      }}
    >
      {children}
    </Td>
  </Tr>
)

export const ContactInfoBox = ({
  email,
  address,
  home_address,
  business_address,
  primary_address,
  contact_info,
}) =>
  contact_info?.length ||
  email ||
  address ||
  home_address ||
  business_address ? (
    <Table variant='unstyled'>
      <Tbody>
        {address && (
          <TableRow category='Address'>
            <AddressText address={address} />
          </TableRow>
        )}
        {home_address && (
          <TableRow
            category={`Home Address ${
              primary_address === 'home' && business_address ? ' (Primary)' : ''
            }`}
          >
            <AddressText address={home_address} />
          </TableRow>
        )}
        {business_address && (
          <TableRow
            category={`Business Address ${
              primary_address === 'business' && home_address ? ' (Primary)' : ''
            }`}
          >
            <AddressText address={business_address} />
          </TableRow>
        )}
        {email && <TableRow category='Primary Email'>{email}</TableRow>}
        {contact_info?.map((contact, index) => (
          <TableRow
            key={`contact-info-${index}`}
            category={contact.contact_type}
          >
            <Text>{contact.contact_data}</Text>
            <Text>{contact.description}</Text>
          </TableRow>
        ))}
      </Tbody>
    </Table>
  ) : (
    <Text> No contact information provided for this constituent.</Text>
  )

const ConstituentDetailsBox = () => {
  const constituent = useSelector(state => state.constituents.instance)
  if (!constituent) return <NotFound />

  const {
    groups,
    associated_topics,
    notes,
    tags,
    contacts,
    preferred_name,
    pronouns,
    languages,
  } = constituent
  const {
    isOpen: groupDetailIsOpen,
    onOpen: onOpenGroupDetail,
    onClose: onCloseGroupDetail,
  } = useDisclosure()
  const [group, setGroup] = useState(null)

  return (
    <>
      {(preferred_name || pronouns) && (
        <Card mb={3}>
          <CardBody>
            {preferred_name && (
              <HStack>
                <Text fontWeight='bold'>Preferred Name: </Text>
                <Text>{preferred_name}</Text>
              </HStack>
            )}
            {pronouns && (
              <HStack>
                <Text fontWeight='bold'>Pronouns: </Text>
                <Text>{constituent.pronouns}</Text>
              </HStack>
            )}
          </CardBody>
        </Card>
      )}
      <InfoCard heading='Contact Information'>
        <ContactInfoBox {...constituent} />
      </InfoCard>
      {contacts?.length > 0 && (
        <InfoCard heading='Contacts'>
          <Stack>
            {contacts.map((contact, index) => (
              <ConstituentCard
                cardStyles={{
                  variant: 'filled',
                  bg: 'gray.100',
                }}
                constituent={contact}
                key={`contact-${index}`}
              />
            ))}
          </Stack>
        </InfoCard>
      )}
      {languages?.length > 0 && (
        <InfoCard heading='Languages'>
          <Wrap gap={2} mt={1}>
            {languages.map((language, index) => (
              <WrapItem key={index}>
                <Tag>{language}</Tag>
              </WrapItem>
            ))}
          </Wrap>
        </InfoCard>
      )}
      {notes && (
        <InfoCard heading='Notes' overflowY='auto'>
          {renderTextLines(notes)}
        </InfoCard>
      )}
      {groups?.length > 0 && (
        <InfoCard heading='Groups'>
          <Stack>
            {groups.map((g, index) => (
              <GroupLabel
                key={index}
                group_name={g.name}
                role={g.role}
                onClick={() => {
                  setGroup(g)
                  onOpenGroupDetail()
                }}
              />
            ))}
          </Stack>

          {group && (
            <DetailDrawer
              id={group.id}
              isOpen={groupDetailIsOpen}
              onClose={onCloseGroupDetail}
            />
          )}
        </InfoCard>
      )}
      {tags.length > 0 && (
        <InfoCard heading='Tags'>
          <Wrap gap={2} mt={1}>
            {tags.map((tag, index) => (
              <WrapItem key={index}>
                <Tag>{tag.label}</Tag>
              </WrapItem>
            ))}
          </Wrap>
        </InfoCard>
      )}
      {associated_topics && (
        <InfoCard heading='Associated Topics'>
          {associated_topics.length ? (
            <Wrap>
              {associated_topics.map((topic, index) => (
                <WrapItem key={index}>
                  <Tooltip label={topic.issue} hasArrow>
                    <Tag p={2}>{topic.name}</Tag>
                  </Tooltip>
                </WrapItem>
              ))}
            </Wrap>
          ) : (
            <Text as='i' color='gray.400'>
              This constituent does not have any relevant casework.
            </Text>
          )}
        </InfoCard>
      )}
    </>
  )
}

export default ConstituentDetailsBox
