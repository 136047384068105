import React from 'react'

import { Input, useToast } from '@chakra-ui/react'
import { useFormikContext } from 'formik'

import {
  FILE_SIZE_LIMIT_BYTES,
  FILE_SIZE_LIMIT_MB,
} from '../../../constants/casework'
import { calculateMS } from '../../../utils'
import { Attachments } from '../../casework'
const AttachmentsField = () => {
  const toast = useToast()

  const { values, setFieldValue } = useFormikContext()
  const toRemove = values.remove_attachments || []
  const toAdd = values.add_attachments || []
  const attachments = values.attachments || []

  const cancelAdd = index => {
    setFieldValue(
      'add_attachments',
      toAdd.filter((_, i) => i !== index)
    )
  }

  const removeAttachment = file => {
    if (toRemove?.includes(file.id)) {
      setFieldValue(
        'remove_attachments',
        toRemove.filter(id => id !== file.id)
      )
    } else if (attachments?.map(file => file.id)?.includes(file.id)) {
      setFieldValue('remove_attachments', [...toRemove, file.id])
    }
  }

  return (
    <>
      <Input
        type='file'
        multiple
        p={1}
        onChange={e => {
          let added_files = []
          for (let file of e.target.files) {
            if (file.size > FILE_SIZE_LIMIT_BYTES) {
              toast({
                title: 'Maximum file size exceeded!',
                description: `${file.name} is too large. Maximum file size of ${FILE_SIZE_LIMIT_MB} MB.`,
                status: 'warning',
                duration: calculateMS(5),
                isClosable: true,
              })
            } else {
              added_files.push({
                file: file,
                file_name: file.name,
              })
            }
          }
          setFieldValue('add_attachments', [...toAdd, ...added_files])
        }}
      />
      <Attachments
        isEditing
        addedAttachments={toAdd}
        removedAttachments={toRemove}
        allAttachments={attachments}
        onCancelAdd={cancelAdd}
        onRemoveAttachment={removeAttachment}
      />
    </>
  )
}

export default AttachmentsField
