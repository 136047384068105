import React from 'react'

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useToast,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import StaffForm from './StaffForm'
import { editStaffValidationSchema } from '../../../constants'
import {
  loadStaffInstance,
  updateStaff,
} from '../../../redux/features/staffSlice'
import { NotFound } from '../../ui'

const EditStaffDrawer = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()
  const toast = useToast()
  const staff = useSelector(state => state.staff.instance)

  if (!staff) return <NotFound />

  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
      size='xl'
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <DrawerOverlay />
      <Formik
        initialValues={{ ...staff }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true)

          const callbackSuccess = () => {
            onClose()
            toast({
              title: 'Successfully updated profile',
              status: 'success',
            })
            setSubmitting(false)
            dispatch(loadStaffInstance({ id: staff?.user.id }))
          }

          const callbackFailure = () => {
            setSubmitting(false)
          }

          dispatch(
            updateStaff({
              id: staff?.user.id,
              values,
              callbackSuccess,
              callbackFailure,
            })
          )
        }}
        validationSchema={editStaffValidationSchema}
      >
        {({ handleSubmit, isSubmitting }) => (
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Edit Profile</DrawerHeader>

            <DrawerBody>
              <StaffForm isEditing />
            </DrawerBody>

            <DrawerFooter>
              <Button variant='outline' mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button
                type='submit'
                isLoading={isSubmitting}
                onClick={() => handleSubmit()}
              >
                Save
              </Button>
            </DrawerFooter>
          </DrawerContent>
        )}
      </Formik>
    </Drawer>
  )
}

export default EditStaffDrawer
