import React, { useCallback, useMemo } from 'react'

import {
  Card,
  CardBody,
  Checkbox,
  Divider,
  List,
  ListItem,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react'

import { initialAddressValues } from '../../../../constants'
import { isMatchingObject } from '../../../../utils'
import { AddressText } from '../../../ui'

const InfoItem = ({ label, children, description }) =>
  children ? (
    <ListItem>
      <Text casing='uppercase' fontWeight='bold' color='blue.400' fontSize='sm'>
        {label}
      </Text>
      {children}

      <Text>{description}</Text>
    </ListItem>
  ) : null

const NameItem = ({ children }) => (
  <Text casing='uppercase' fontWeight='bold' color='gray.400' fontSize='sm'>
    {children}
  </Text>
)

const CheckableItem = ({
  id,
  foundInValues,
  removeValue,
  addValue,
  children,
}) =>
  children ? (
    <Checkbox
      id={id}
      isChecked={foundInValues}
      onChange={e => {
        let checked = e.target.checked

        if (checked) addValue()
        else removeValue()
      }}
    >
      {children}
    </Checkbox>
  ) : null

const MergeConstituentInfo = ({ constituent, values, setFieldValue }) => {
  const isMatchingContactInfo = (lhs, rhs) =>
    isMatchingObject(lhs, rhs, ['contact_data', 'contact_type', 'description'])
  const isMatchingAddress = (lhs, rhs) =>
    isMatchingObject(lhs, rhs, [
      'line1',
      'line2',
      'po_box',
      'city',
      'state',
      'zipcode',
      'label',
    ])

  const primaryEmailFound = useMemo(
    () =>
      values.email === constituent.email ||
      !!values.contact_info?.find(
        info => info.contact_data === constituent.email
      ),
    [values.email, constituent.email, values.contact_info]
  )

  const addPrimaryEmail = useCallback(() => {
    // If there already is a primary email, add as an additional contact
    if (values.email) {
      setFieldValue('contact_info', [
        ...(values.contact_info || []),
        {
          contact_type: 'Email',
          contact_data: constituent.email,
          description: '',
        },
      ])
    } else {
      // Otherwise, set primary to this email
      setFieldValue('email', constituent.email)
    }
  }, [values.email, values.contact_info, constituent.email])

  const removePrimaryEmail = useCallback(() => {
    // If this value was the primary email
    if (values.email === constituent.email) {
      // Removing this value should bump any existing alternate emails to primary (if there is no description)
      let existingAltEmail = values.contact_info?.find(
        info => info.contact_type === 'Email' && !info.description
      )
      if (existingAltEmail) {
        setFieldValue('email', existingAltEmail.contact_data)
        setFieldValue(
          'contact_info',
          values.contact_info?.filter(
            info => !isMatchingContactInfo(info, existingAltEmail)
          )
        )
      } else setFieldValue('email', '')
    } else {
      // Otherwise, it would be the contact info

      setFieldValue(
        'contact_info',
        values.contact_info?.filter(
          info =>
            !isMatchingContactInfo(info, {
              contact_type: 'Email',
              contact_data: constituent.email,
              description: '',
            })
        )
      )
    }
  }, [values.email, constituent.email, values.contact_info])

  const contactInfoFound = useCallback(
    contact =>
      !!values.contact_info?.find(info =>
        isMatchingContactInfo(info, contact)
      ) ||
      (contact.contact_type === 'Email' &&
        values.email === contact.contact_data),
    [values.contact_info, values.email]
  )

  const removeContactInfo = useCallback(
    contact =>
      setFieldValue(
        'contact_info',
        values.contact_info.filter(
          info => !isMatchingContactInfo(info, contact)
        )
      ),
    [values.contact_info]
  )

  const addContactInfo = useCallback(
    contact => setFieldValue('contact_info', [...values.contact_info, contact]),
    [values.contact_info]
  )

  const homeAddressFound = useMemo(
    () => isMatchingAddress(constituent.home_address, values.home_address),
    [constituent.home_address, values.home_address]
  )

  const addHomeAddress = useCallback(
    () => setFieldValue('home_address', constituent.home_address),
    [constituent.home_address]
  )

  const removeHomeAddress = useCallback(
    () => setFieldValue('home_address', initialAddressValues),
    []
  )

  const businessAddressFound = useMemo(
    () =>
      isMatchingAddress(constituent.business_address, values.business_address),
    [constituent.business_address, values.business_address]
  )

  const addBusinessAddress = useCallback(
    () => setFieldValue('business_address', constituent.business_address),
    [constituent.business_address]
  )

  const removeBusinessAddress = useCallback(
    () => () => setFieldValue('business_address', initialAddressValues),
    []
  )

  const groupFound = useCallback(
    group => !!values.groups.find(g => g.id === group.id),
    [values.groups]
  )

  const addGroup = useCallback(
    group => setFieldValue('groups', [...values.groups, group]),
    [values.groups]
  )

  const removeGroup = useCallback(
    group =>
      setFieldValue(
        'groups',
        values.groups.filter(g => g.id !== group.id)
      ),
    [values.groups]
  )

  const languageFound = useCallback(
    language => !!values.languages.find(l => l === language),
    [values.languages]
  )

  const addLanguage = useCallback(
    language => setFieldValue('languages', [...values.languages, language]),
    [values.languages]
  )

  const removeLanguage = useCallback(
    language =>
      setFieldValue(
        'languages',
        values.languages.filter(l => l !== language)
      ),
    [values.languages]
  )

  const tagFound = useCallback(
    tag => !!values.tags.find(t => t.id === tag.id),
    [values.tags]
  )

  const addTag = useCallback(
    tag => setFieldValue('tags', [...values.tags, tag]),
    [values.tags]
  )

  const removeTag = useCallback(
    tag =>
      setFieldValue(
        'tags',
        values.tags.filter(t => t.id !== tag.id)
      ),
    [values.tags]
  )
  const cardBgColor = useColorModeValue('', 'blackAlpha.400')
  return (
    <Card variant='filled' bg={cardBgColor}>
      <CardBody>
        <Stack spacing={0}>
          <Text fontSize='xl' lineHeight='1.1' fontWeight='bold'>
            {constituent.full_name}{' '}
            {constituent.preferred_name && `(${constituent.preferred_name})`}
          </Text>

          <NameItem>{constituent.pronouns}</NameItem>

          <Tooltip label={constituent.title?.length > 60 && constituent.title}>
            <NameItem>
              {constituent.title?.length > 60
                ? `${constituent.title?.slice(0, 60)}...`
                : constituent.title}
            </NameItem>
          </Tooltip>
        </Stack>
        <Divider my={1} />
        <List>
          {constituent.email && (
            <InfoItem label='Primary Email'>
              <CheckableItem
                id={`checkbox-primary-email-${constituent.id}`}
                foundInValues={primaryEmailFound}
                addValue={addPrimaryEmail}
                removeValue={removePrimaryEmail}
              >
                {constituent.email}
              </CheckableItem>
            </InfoItem>
          )}
          {constituent.contact_info?.length > 0 && (
            <>
              {constituent.contact_info.map((contact, index) => (
                <InfoItem
                  key={index}
                  label={contact.contact_type}
                  description={contact.description}
                >
                  <CheckableItem
                    id={`contact-info-${constituent.id}-${index}`}
                    foundInValues={contactInfoFound(contact)}
                    addValue={() => addContactInfo(contact)}
                    removeValue={() => removeContactInfo(contact)}
                  >
                    {contact.contact_data}
                  </CheckableItem>
                </InfoItem>
              ))}
              <Divider my={1} />
            </>
          )}
          {(constituent.home_address || constituent.business_address) && (
            <>
              {constituent.home_address && (
                <InfoItem label='Home Address'>
                  <CheckableItem
                    id={`home-address-${constituent.id}`}
                    foundInValues={homeAddressFound}
                    addValue={addHomeAddress}
                    removeValue={removeHomeAddress}
                  >
                    <AddressText address={constituent.home_address} />
                  </CheckableItem>
                </InfoItem>
              )}
              {constituent.business_address && (
                <InfoItem label='Business Address'>
                  <CheckableItem
                    id={`business-address-${constituent.id}`}
                    foundInValues={businessAddressFound}
                    addValue={addBusinessAddress}
                    removeValue={removeBusinessAddress}
                  >
                    <AddressText address={constituent.business_address} />
                  </CheckableItem>
                </InfoItem>
              )}
              <Divider my={1} />
            </>
          )}
          {constituent.groups?.length > 0 && (
            <InfoItem label='Groups'>
              <Stack>
                {constituent.groups?.map((group, index) => (
                  <CheckableItem
                    key={index}
                    id={`group-${constituent.id}-${index}`}
                    foundInValues={groupFound(group)}
                    addValue={() => addGroup(group)}
                    removeValue={() => removeGroup(group)}
                  >
                    {`${group.name} ${
                      group.role ? '(' + group.role + ')' : ''
                    }`}
                  </CheckableItem>
                ))}
              </Stack>
            </InfoItem>
          )}
          {constituent.languages?.length > 0 && (
            <InfoItem label='Languages'>
              <Stack>
                {constituent.languages?.map((language, index) => (
                  <CheckableItem
                    key={index}
                    id={`language-${constituent.id}-${index}`}
                    foundInValues={languageFound(language)}
                    addValue={() => addLanguage(language)}
                    removeValue={() => removeLanguage(language)}
                  >
                    {language}
                  </CheckableItem>
                ))}
              </Stack>
            </InfoItem>
          )}
          {constituent.tags?.length > 0 && (
            <InfoItem label='Tags'>
              {constituent.tags?.map((tag, index) => (
                <CheckableItem
                  key={index}
                  id={`tag-${constituent.id}-${index}`}
                  foundInValues={tagFound(tag)}
                  addValue={() => addTag(tag)}
                  removeValue={() => removeTag(tag)}
                >
                  {tag.label}
                </CheckableItem>
              ))}
            </InfoItem>
          )}
          <InfoItem label='Notes'>{constituent.notes}</InfoItem>
        </List>
      </CardBody>
    </Card>
  )
}

export default MergeConstituentInfo
