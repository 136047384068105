import React from 'react'

import { EditIcon } from '@chakra-ui/icons'
import {
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  Button,
  Text,
  useToast,
  Box,
  Flex,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import { groupValidationSchema } from '../../../../constants'
import {
  addConstituentsToGroup,
  removeConstituentsFromGroup,
} from '../../../../redux/features/directorySlice'
import {
  loadGroupInstance,
  updateGroup,
} from '../../../../redux/features/groupsSlice'
import { calculateMS } from '../../../../utils'
import GroupForm from '../CreateOrEditGroup/GroupForm'

const EditContent = ({ handleSaveEdits }) => {
  const group = useSelector(state => state.groups.instance)
  const dispatch = useDispatch()
  const toast = useToast()

  if (!group) return null
  return (
    <Formik
      initialValues={group}
      validateOnChange={false}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true)

        const callbackSuccess = () => {
          handleSaveEdits()
          dispatch(loadGroupInstance({ id: group.id }))
          toast({
            title: 'Changes saved',
            status: 'success',
            duration: calculateMS(3),
            isClosable: true,
          })
          setSubmitting(false)
        }

        dispatch(
          addConstituentsToGroup({
            id: group.id,
            constituents: values.constituents.filter(c => c.status === 'new'),
          })
        )

        dispatch(
          removeConstituentsFromGroup({
            id: group.id,
            constituents: values.constituents.filter(
              c => c.status === 'removed'
            ),
          })
        )

        dispatch(
          updateGroup({
            values,
            id: group.id,
            callbackSuccess,
          })
        )
      }}
      validationSchema={groupValidationSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <>
          <DrawerHeader>
            <Box
              position='sticky'
              top={0}
              bg='var(--chakra-colors-chakra-body-bg)'
            >
              <Text fontSize='xl' variant='subheading-blue'>
                Editing Group
              </Text>
            </Box>
          </DrawerHeader>
          <DrawerBody>
            <GroupForm />
          </DrawerBody>
          <DrawerFooter>
            <Flex>
              <Button
                variant='ghost'
                onClick={handleSaveEdits}
                colorScheme='red'
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                leftIcon={<EditIcon />}
                isLoading={isSubmitting}
              >
                Save Changes
              </Button>
            </Flex>
          </DrawerFooter>
        </>
      )}
    </Formik>
  )
}

export default EditContent
