import { tableAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys)

const sm = defineStyle({
  fontSize: 'md',
})

const sizes = {
  sm: definePartsStyle({ td: sm }),
}

export const tableTheme = defineMultiStyleConfig({ sizes })
