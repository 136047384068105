import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  createRef,
} from 'react'

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Tooltip,
  Box,
  useColorModeValue,
  IconButton,
  Icon,
  Text,
  Flex,
  HStack,
  Spacer,
} from '@chakra-ui/react'
import { ViewColumnsIcon } from '@heroicons/react/24/outline'
import { useDispatch } from 'react-redux'

import { setDirectoryOrderBy } from '../../redux/features/directorySlice'
import { OrderByIndicator } from '../ui'
import styles from '../ui/styles/ResizableTable.module.css'

const ResizableTable = ({
  headers,
  minCellWidth,
  tableContent,
  localStorageKeyName,
}) => {
  const dispatch = useDispatch()
  const [activeIndex, setActiveIndex] = useState(null)
  const [isResized, setIsResized] = useState(false)
  const [resizeHandleHeights, setResizeHandleHeights] = useState(
    Array(headers.length).fill('auto')
  )
  const [initialClientX, setInitialClientX] = useState(null)
  const [originalWidths, setOriginalWidths] = useState([])

  const headerElement = useRef(null)
  const tableElement = useRef(null)
  const refs = useRef([])

  const headerBg = useColorModeValue('white', 'nyccBlue.800')
  const headerTextColor = useColorModeValue('gray.800', 'white')
  const resizerHoverBorderColor = useColorModeValue('gray.300', 'gray.600')

  useEffect(() => {
    refs.current = headers.map((_, i) => refs.current[i] ?? createRef())
  }, [headers])

  const mergeRefs = (...refs) => {
    // Assign the same HTML element to multiple refs.
    const mergedRef = element => {
      refs.forEach(ref => {
        if (ref) {
          if (typeof ref === 'function') {
            ref(element)
          } else {
            ref.current = element
          }
        }
      })
    }
    return mergedRef
  }

  const changeOrderBy = value => {
    dispatch(setDirectoryOrderBy(value))
  }

  const columns = headers.map((item, index) => ({
    text: item.name,
    value: item.value,
    onClick: item.value ? () => changeOrderBy(item.value) : undefined,
    ref: refs.current[index],
  }))

  const mouseDown = (index, e) => {
    setActiveIndex(index)
    setInitialClientX(e.clientX)
    setOriginalWidths(columns.map(col => col.ref.current.offsetWidth))
  }

  const mouseMove = useCallback(
    e => {
      if (activeIndex !== null) {
        const diffX = e.clientX - initialClientX

        // Calculate the new width of the current column and ensure it doesn't go below the minimum.
        let newWidth = Math.max(
          originalWidths[activeIndex] + diffX,
          minCellWidth
        )

        if (newWidth >= minCellWidth) {
          // Apply the adjusted widths to the state and the DOM.
          let newOriginalWidths = [...originalWidths]
          newOriginalWidths[activeIndex] = newWidth

          setOriginalWidths(newOriginalWidths)

          const gridColumns = newOriginalWidths
            .map((width, i) =>
              i === newOriginalWidths.length - 1 ? '1fr' : `${width}px`
            )
            .join(' ')
          tableElement.current.style.gridTemplateColumns = gridColumns

          setInitialClientX(e.clientX)
        }
        setIsResized(true)
      }
    },
    [activeIndex, initialClientX, minCellWidth, originalWidths]
  )

  useEffect(() => {
    const storedWidthsStr = localStorage.getItem(
      `columnWidths-${localStorageKeyName}`
    )
    const storedWidths = storedWidthsStr
      ? storedWidthsStr.split(',').map(Number)
      : null
    const frUnits = headers.map(() => 1) // 1fr for each column initially
    setOriginalWidths(frUnits)

    const gridColumns = frUnits.map(width => `${width}fr`).join(' ')
    tableElement.current.style.gridTemplateColumns = storedWidths
      ? storedWidths
      : gridColumns
  }, [])

  const resetColumnWidths = useCallback(() => {
    const frUnits = headers.map(() => 1) // Reset to 1fr each
    setOriginalWidths(frUnits)

    const gridColumns = frUnits.map(width => `${width}fr`).join(' ')
    tableElement.current.style.gridTemplateColumns = gridColumns

    setIsResized(false)
    localStorage.removeItem(`columnWidths-${localStorageKeyName}`)
  }, [headers])

  const removeListeners = useCallback(() => {
    window.removeEventListener('mousemove', mouseMove)
    window.removeEventListener('mouseup', removeListeners)
  }, [mouseMove])

  const mouseUp = useCallback(() => {
    setActiveIndex(null)
    removeListeners()
  }, [removeListeners])

  useEffect(() => {
    if (activeIndex !== null) {
      window.addEventListener('mousemove', mouseMove)
      window.addEventListener('mouseup', mouseUp)
    }
    return () => {
      removeListeners()
    }
  }, [activeIndex, mouseMove, mouseUp, removeListeners])

  useEffect(() => {
    // Retrieve the stored widths string and convert back to an array
    const storedWidthsStr = localStorage.getItem(
      `columnWidths-${localStorageKeyName}`
    )
    const storedWidths = storedWidthsStr
      ? storedWidthsStr.split(',').map(Number)
      : null
    if (
      tableElement.current &&
      storedWidths &&
      storedWidths.length === headers.length
    ) {
      const gridColumns = storedWidths
        .map((width, i) =>
          i === storedWidths.length - 1 ? 'auto' : `${width}px`
        )
        .join(' ')
      tableElement.current.style.gridTemplateColumns = gridColumns
      setOriginalWidths(storedWidths)
    }
  }, [])

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      if (entries[0].target === tableElement.current) {
        const newTableHeight = entries[0].contentRect.height
        setResizeHandleHeights(prevHeights =>
          prevHeights.map((_, i) =>
            i === activeIndex ? newTableHeight : '20px'
          )
        )
      }
    })
    if (tableElement.current) {
      resizeObserver.observe(tableElement.current)
    }
    return () => {
      resizeObserver.disconnect()
    }
  }, [activeIndex])

  useEffect(() => {
    if (isResized) {
      const widthsStr = originalWidths.join(',')
      localStorage.setItem(`columnWidths-${localStorageKeyName}`, widthsStr)
    }
  }, [originalWidths, isResized])

  const gridTemplatesColumns = headers.map((header, i) => {
    if (i === headers.length - 1 && !header.value) {
      return 'auto'
    } else {
      return '1fr'
    }
  })

  const tableStyle = {
    display: 'grid',
    gridTemplateColumns: gridTemplatesColumns,
    width: '100%',
  }

  const thStyle = {
    position: 'sticky',
    top: 0,
    zIndex: '1',
    userSelect: 'none',
    boxShadow: '4px 1px 4px rgb(0, 0, 0, 0.2)',
  }

  return (
    <Box width={'100%'}>
      <Table
        className={styles['resizable']}
        size='lg'
        style={tableStyle}
        ref={tableElement}
      >
        <Thead>
          <Tr>
            {columns
              .slice(0, columns.length - 1)
              .map(({ ref, text, onClick, value }, columnIndex) => (
                <Th
                  ref={mergeRefs(ref, headerElement)}
                  key={`th-${columnIndex}`}
                  className='header'
                  style={thStyle}
                  bg={headerBg}
                  color={headerTextColor}
                  alignContent='center'
                >
                  <Flex>
                    {text && (
                      <HStack
                        onClick={value && onClick}
                        cursor={value && 'pointer'}
                      >
                        <Text>{text || value}</Text>
                        {value && <OrderByIndicator value={value} />}
                      </HStack>
                    )}
                    <Spacer />
                    {columnIndex < columns.length - 1 &&
                      !(
                        columnIndex === columns.length - 2 &&
                        !headers[headers.length - 1].value
                      ) && (
                        <Box
                          style={{ height: resizeHandleHeights[columnIndex] }}
                          className='resizer-handle'
                          sx={{
                            display: 'block',
                            position: 'absolute',
                            cursor: 'col-resize',
                            width: '7px',
                            right: '0',
                            zIndex: '1',
                            borderRight: '1px solid',
                            borderColor: resizerHoverBorderColor,
                            _hover: {
                              minHeight: '30px',
                              '&::before': {
                                content: `''`,
                                position: 'absolute',
                                zIndex: '9999',
                              },
                            },
                          }}
                          onMouseDown={e => {
                            mouseDown(columnIndex, e)
                          }}
                        />
                      )}
                  </Flex>
                </Th>
              ))}
            <Th
              ref={mergeRefs(columns[columns.length - 1].ref, headerElement)}
              key={`th-${columns.length - 1}`}
              bg={headerBg}
              color={headerTextColor}
              className='header options-column'
              style={{
                ...thStyle,
                right: 0,
                padding: 0,
                textAlign: 'end',
                alignContent: 'center',
                paddingRight: '10px',
              }}
            >
              <Box style={{ position: 'relative' }}>
                <Tooltip
                  hasArrow
                  label='Reset column widths'
                  aria-label='Reset column widths'
                  placement='left'
                >
                  <IconButton
                    icon={<Icon as={ViewColumnsIcon} fontSize='25' />}
                    variant='ghost'
                    onClick={resetColumnWidths}
                  />
                </Tooltip>
              </Box>
            </Th>
          </Tr>
        </Thead>
        <Tbody>{tableContent}</Tbody>
      </Table>
    </Box>
  )
}

export default ResizableTable
