import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [],
  instance: null,
  loading: false,
  options: [],
  errors: null,
  next: null,
  count: 0,
}

export const constituentsSlice = createSlice({
  initialState,
  name: 'constituents',
  reducers: {
    loadConstituents(state) {
      state.loading = true
      state.data = []
      state.next = null
    },
    storeConstituents(state, action) {
      const { count, next, results } = action.payload
      state.data = [...state.data, ...results]
      state.next = next
      state.count = count
      state.loading = false
    },

    loadConstituentInstance(state) {
      state.loading = true
      state.instance = {}
      state.next = null
    },
    storeConstituentInstance(state, action) {
      state.instance = action.payload
      state.loading = false
      state.errors = null
    },

    startExportConstituents(state) {
      state.exporting = true
    },
    completeExportConstituents(state) {
      delete state.exporting
    },

    loadConstituentOptions(state) {
      state.options_loading = true
    },
    paginateConstituentOptions() {},
    storeConstituentOptions(state, action) {
      const { results, next } = action.payload

      state.options = [...state.options, ...results]
      state.options_next = next
      state.options_loading = false
    },

    loadConstituentTags(state) {
      state.tag_options = []
      state.tags_loading = true
    },
    paginateConstituentTags() {},
    storeConstituentTags(state, action) {
      const { results, next } = action.payload
      state.tag_options = [...(state.tag_options || []), ...results]
      state.tags_next = next
      state.tags_loading = false
    },
    clearConstituentTags(state) {
      state.tag_options = []
      delete state.tags_next
    },

    mergeConstituents() {},
    selectConstituent(state, action) {
      const index = action.payload
      if (!state.selected) {
        state.selected = [index]
      } else if (!state.selected.includes(index)) {
        state.selected = [...state.selected, index]
      }
    },
    deselectConstituent(state, action) {
      const index = action.payload
      if (state.selected?.length > 0) {
        state.selected = state.selected.filter(c => c !== index)
      }
    },
    clearConstituents(state) {
      state.data = []
      state.next = null
      state.count = 0
      delete state.selected
    },
    clearConstituentInstance(state) {
      state.instance = null
    },
    clearConstituentOptions(state) {
      state.options = []
      delete state.options_next
    },

    createConstituent() {},
    updateConstituent() {},
    deleteConstituent() {},

    paginate(state) {
      state.loading = true
    },
    cancelLoading(state) {
      state.loading = false
    },
    setErrors(state, action) {
      state.loading = false
      state.errors = action.payload
    },
  },
})

export default constituentsSlice.reducer

export const {
  loadConstituents,
  storeConstituents,

  loadConstituentInstance,
  storeConstituentInstance,

  startExportConstituents,
  completeExportConstituents,

  loadConstituentOptions,
  paginateConstituentOptions,
  storeConstituentOptions,

  loadConstituentTags,
  paginateConstituentTags,
  storeConstituentTags,
  clearConstituentTags,

  clearConstituents,
  clearConstituentInstance,
  clearConstituentOptions,

  createConstituent,
  updateConstituent,
  deleteConstituent,

  mergeConstituents,
  selectConstituent,
  deselectConstituent,

  paginate,
  cancelLoading,
  setErrors,
} = constituentsSlice.actions
