import React from 'react'

import { Box, Button, Text } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'

import GenerateReportForm from './GenerateReportForm'
import Report from './Report'
import { exportReport } from '../../redux/features/reportSlice'
import { ExportLoading } from '../ui'

const Reports = () => {
  const dispatch = useDispatch()

  const user = useSelector(state => state.auth.user)
  const loading = useSelector(state => state.reports.loading)
  const results = useSelector(state => state.reports.results)
  const exporting = useSelector(state => state.reports.exporting)
  if (!user?.permissions.includes('casework.view_reports'))
    return <Text>You do not have permission to view reports.</Text>

  return !user?.permissions.includes('casework.view_reports') ? (
    <Text>You do not have permission to view reports.</Text>
  ) : (
    <>
      <Box py={1}>
        <GenerateReportForm />
      </Box>

      <Box py={2}>
        <Report />
      </Box>
      {results && !loading && (
        <Box align='end'>
          <Button
            colorScheme='blue'
            variant='link'
            onClick={() => dispatch(exportReport())}
          >
            Export as Excel File
          </Button>
        </Box>
      )}
      <ExportLoading isLoading={exporting} />
    </>
  )
}

export default Reports
