import React from 'react'

import { VStack } from '@chakra-ui/react'

import { SearchSelectProvider } from './SearchSelectContext'
const SearchSelect = ({ children }) => {
  return (
    <SearchSelectProvider>
      <VStack gap={0}>{children}</VStack>
    </SearchSelectProvider>
  )
}

export default SearchSelect
