import React, { useCallback, useEffect, useRef } from 'react'

import { SmallCloseIcon } from '@chakra-ui/icons'
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  List,
  Popover,
  PopoverAnchor,
  PopoverContent,
  Skeleton,
  Tooltip,
  useOutsideClick,
} from '@chakra-ui/react'

import { useSearchSelect } from './SearchSelectContext'
import { throttle } from '../../utils'

const SearchOptions = ({
  children,
  inputProps,
  isLoading = false,
  hideOptions = false,
  enableClear = true,
  initialSelected,
  dropdownHeight = '30vh',
  loadMoreOptions = () => {},
  onSearch = () => {},
  onClear = () => {},
  onKeyDown = () => {},
}) => {
  const {
    searchTerm,
    setSearchTerm,
    isOpen,
    onOpen,
    onClose,
    selectedOptions,
    setSelectedOptions,
    clearSelectedOptions,
  } = useSearchSelect({
    searchLoading: isLoading,
  })

  const searchContext = useSearchSelect()

  const inputRef = useRef(null)
  const listRef = useRef(null)
  const containerRef = useRef(null)

  const dropdownWidth = inputRef.current?.getBoundingClientRect().width

  useEffect(() => {
    if (initialSelected) setSelectedOptions(initialSelected)
  }, [initialSelected])

  const handleOutsideClick = useCallback(
    e => {
      if (!isOpen || listRef.current?.contains(e.target)) return

      onClose()
    },
    [isOpen, listRef, onClose]
  )

  useOutsideClick({
    ref: inputRef,
    handler: handleOutsideClick,
  })

  const handleScroll = () => {
    const container = containerRef.current
    if (!container) return

    if (
      container.scrollTop + container.clientHeight >=
      container.scrollHeight - 30
    )
      loadMoreOptions()
  }

  const handleChange = e => {
    const value = e.target.value

    onSearch(e)
    setSearchTerm(value)
  }

  const handleKeyDown = e => {
    onKeyDown(e, searchContext)
    if (e.key === 'Enter') {
      e.preventDefault()
      onClose()
      setSearchTerm('')
    }
    if (!isOpen) onOpen()
  }

  useEffect(() => {
    const container = containerRef.current
    if (!container) return

    const throttledHandleScroll = throttle(handleScroll, 300)
    container.addEventListener('scroll', throttledHandleScroll, false)

    return () => {
      container.removeEventListener('scroll', throttledHandleScroll, false)
    }
  }, [containerRef, handleScroll])

  const handleClear = useCallback(() => {
    onClear(selectedOptions)
    clearSelectedOptions()
    setSearchTerm('')
  }, [clearSelectedOptions, selectedOptions, onClear, setSearchTerm])

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      placement='bottom-start'
      initialFocusRef={inputRef}
      isLazy
    >
      <PopoverAnchor>
        <InputGroup>
          <Input
            ref={inputRef}
            type='text'
            value={searchTerm}
            onFocus={onOpen}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            autoComplete='off'
            placeholder='Search for a value'
            {...inputProps}
          />
          {selectedOptions.length > 0 && enableClear && (
            <InputRightElement>
              <Tooltip label='Clear all selected'>
                <SmallCloseIcon
                  _hover={{ cursor: 'pointer' }}
                  onClick={handleClear}
                />
              </Tooltip>
            </InputRightElement>
          )}
        </InputGroup>
      </PopoverAnchor>
      {!hideOptions && (
        <PopoverContent w={dropdownWidth} maxH={dropdownHeight}>
          <Box overflowY='auto' ref={containerRef} borderRadius='md'>
            <Skeleton isLoaded={!isLoading ?? true}>
              <List ref={listRef}>
                {typeof children === 'function'
                  ? children(searchContext)
                  : children}
              </List>
            </Skeleton>
          </Box>
        </PopoverContent>
      )}
    </Popover>
  )
}

export default SearchOptions
