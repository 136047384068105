import * as Yup from 'yup'

export const passwordValidationSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Must enter your current password'),
  newPassword: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})/,
      'New password does not satisfy all conditions above.'
    )
    .required('Required'),
  confirmPassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
})
