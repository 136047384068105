import React from 'react'

import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'

import { setTabIndex } from '../../redux/features/toolsSlice'
import { Reports } from '../reports'
import { AddressLookupTool } from '../tools'

const Tools = () => {
  document.title = 'Tools | Council Connect'
  const dispatch = useDispatch()

  const tabIndex = useSelector(state => state.tools.tab_index)
  const handleChangeTab = index => {
    dispatch(setTabIndex(index))
  }
  return (
    <Box bg='blackAlpha.50' h='100%' w='100%'>
      <Box maxW='7xl' w='100%' mx='auto'>
        <Tabs onChange={handleChangeTab} index={tabIndex}>
          <TabList>
            <Tab> Address Lookup </Tab>
            <Tab> Reports </Tab>
            <Tab isDisabled> More to come...</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <AddressLookupTool />
            </TabPanel>
            <TabPanel>
              <Reports />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  )
}

export default Tools
