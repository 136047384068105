import React, { useCallback } from 'react'

import { Text, HStack, Icon, Spacer } from '@chakra-ui/react'
import { UserIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router'

const Constituent = ({ id, full_name, role }) => {
  const navigate = useNavigate()
  const handleClick = useCallback(
    e => {
      const url = `/directory/constituents/${id}`
      if (e.metaKey || e.ctrlKey) {
        window.open(url, '_blank')
      } else {
        navigate(url)
      }
    },
    [id]
  )

  return (
    <HStack
      onClick={handleClick}
      _hover={{
        cursor: 'pointer',
        bgColor: 'gray.100',
        color: 'gray.800',
      }}
      borderRadius={2}
      p={2}
    >
      <Icon as={UserIcon} />
      <Text cursor='text'> {full_name} </Text>
      <Spacer />
      <Text as='em' color='gray.600' cursor='text'>
        {role?.toUpperCase()}
      </Text>
    </HStack>
  )
}

export default Constituent
