import React, { useEffect } from 'react'

import {
  Box,
  Card,
  Tab,
  TabList,
  Tabs,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { StaffManager } from '../admin'
import { NotFound } from '../ui'

// const tabStyles = {
//   variant: 'unstyled',
//   h: 50,
//   bgColor: 'blue.400',
//   opacity: 0.6,
//   color: 'white',
//   fontSize: 'lg',
//   textTransform: 'uppercase',
//   w: '100%',
//   borderRadius: 'xl',
//   _hover: {
//     opacity: 1,
//     boxShadow: '0 0.5em 0.5em -0.4em',
//     transform: 'translateY(-0.1em)',
//   },
//   _selected: {
//     opacity: 1,
//   },
// }

const AdminPortal = () => {
  const navigate = useNavigate()

  const user = useSelector(state => state.auth.user)
  useEffect(() => {
    if (!user?.is_admin) {
      navigate('/')
    }
  }, [user, navigate])

  // const [tabIndex, setTabIndex] = useState(0)

  // Just a failsafe in case use effect doesn't happen
  if (!user?.is_admin) return <NotFound />

  document.title = 'Admin Portal | Council Connect'
  return (
    <Box mx='auto' maxW='7xl'>
      <Tabs size='lg' py={4} align='start'>
        <TabList>
          {/* <Tab> Topic Editor </Tab> */}
          <Tab> Staff Manager </Tab>
        </TabList>
        <TabPanels>
          {/* <TabPanel> */}
          {/* <Flex
              direction='column'
              align={{ base: 'stretch', lg: 'center' }}
              justify={{ base: 'start', lg: 'center' }}
              maxW='7xl'
              pt='0'
              p={6}
            > */}
          {/* <Card overflowY='auto' overflowX='hidden' w='100%'>
              <TopicEditor />
            </Card> */}
          {/* </Flex> */}
          {/* </TabPanel> */}
          <TabPanel>
            {/* <Flex
              direction='column'
              align={{ base: 'stretch', lg: 'center' }}
              justify={{ base: 'start', lg: 'center' }}
              maxW='7xl'
              pt='0'
              p={6}
            > */}
            <Card overflowY='auto' overflowX='hidden' w='100%'>
              <StaffManager />
            </Card>
            {/* </Flex> */}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default AdminPortal
