import React, { useCallback, useMemo } from 'react'

import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'

import StaffSelect from '../../../../ui/select/StaffSelect'

const AssignedStaffField = () => {
  const user = useSelector(state => state.auth.user)

  const { values, setFieldValue } = useFormikContext()

  const onSelect = useCallback(
    option =>
      setFieldValue('assigned_staff', [...values.assigned_staff, option]),
    [setFieldValue, values.assigned_staff]
  )

  const onRemove = useCallback(
    removeIndex =>
      setFieldValue(
        'assigned_staff',
        values.assigned_staff.toSpliced(removeIndex, 1)
      ),
    [setFieldValue, values.assigned_staff]
  )

  const onClear = useCallback(
    () => setFieldValue('assigned_staff', []),
    [setFieldValue]
  )

  const selectedStaff = useMemo(() =>
    values.assigned_staff.map(staff => ({
      label: staff.full_name,
      value: staff,
    }))
  )

  return (
    <StaffSelect
      onSelect={onSelect}
      onRemove={onRemove}
      onClear={onClear}
      districtOnly={true}
      initialSelected={selectedStaff}
      inputProps={{
        id: `assigned-staff-select`,
        placeholder: 'Search for staff',
        isDisabled: !user.permissions.includes('casework.assign_staff'),
      }}
    />
  )
}

export default AssignedStaffField
