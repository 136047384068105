import React from 'react'

import { Wrap, WrapItem, useColorModeValue } from '@chakra-ui/react'
import { useSelector } from 'react-redux'

import { File } from '../../ui'

const Attachments = ({
  addedAttachments,
  removedAttachments,
  allAttachments,
  isEditing,
  onCancelAdd,
  onRemoveAttachment,
  onOpenAttachment,
}) => {
  const uploadWaiting = useSelector(
    state => state.casework.attachment_uploading
  )
  const uploadComplete = useSelector(
    state => state.casework.attachment_upload_complete
  )
  const removeWaiting = useSelector(state => state.casework.attachment_removing)
  const removeComplete = useSelector(
    state => state.casework.attachment_remove_complete
  )

  const attachmentBgColor = useColorModeValue('blue.500', 'blue.200')
  const attachmentTextColor = useColorModeValue('gray.100', 'blue.800')

  return (
    <Wrap gap={2} my={2}>
      {addedAttachments?.map((file, index) => (
        <WrapItem key={index}>
          <File
            isEditing={isEditing}
            file={file}
            textColor='blue.800'
            textWeight='bold'
            bgColor='green.100'
            uploading={uploadWaiting === index}
            uploadComplete={uploadComplete?.includes(index)}
            onButtonClick={onCancelAdd && (() => onCancelAdd(index))}
          />
        </WrapItem>
      ))}
      {allAttachments?.map((file, index) => (
        <WrapItem key={index}>
          <File
            isEditing={isEditing}
            file={file}
            textColor={attachmentTextColor}
            bgColor={
              removedAttachments?.includes(file.id)
                ? 'red.100'
                : attachmentBgColor
            }
            removing={removeWaiting === file.id}
            removeComplete={removeComplete?.includes(file.id)}
            strikethrough={removedAttachments?.includes(file.id)}
            onClick={onOpenAttachment && (() => onOpenAttachment(file))}
            onButtonClick={
              onRemoveAttachment && (() => onRemoveAttachment(file))
            }
          />
        </WrapItem>
      ))}
    </Wrap>
  )
}

export default Attachments
