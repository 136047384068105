import React, { useCallback, useEffect } from 'react'

import {
  Button,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackDivider,
  useDisclosure,
} from '@chakra-ui/react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'

import PatchNotesSection from './PatchNotesSection'

const VERSION = '1.6.24'

const PatchNotesModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const removePreviousViewed = useCallback(() => {
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i)

      if (key.startsWith('patchNotes-') && key.endsWith('-viewed')) {
        localStorage.removeItem(key)
      }
    }
  }, [])

  const setViewed = useCallback(() => {
    removePreviousViewed()
    localStorage.setItem(`patchNotes-${VERSION}-viewed`, true)
    onClose()
  }, [])

  const getViewed = useCallback(() => {
    return localStorage.getItem(`patchNotes-${VERSION}-viewed`)
  }, [])

  useEffect(() => {
    if (!getViewed()) onOpen()
  }, [])

  // const previewStyles = {
  //   border: '1px solid rgba(0, 0, 0, 0.25)',
  //   shadow: 'md',
  //   p: 3,
  //   my: 1,
  // }
  return (
    <>
      <IconButton
        icon={<Icon as={InformationCircleIcon} />}
        fontSize='2xl'
        onClick={onOpen}
      >
        What&apos;s New
      </IconButton>
      <Modal isOpen={isOpen} size='2xl' scrollBehavior='inside'>
        <ModalOverlay />
        <ModalCloseButton />
        <ModalContent>
          <ModalHeader>What&apos;s New</ModalHeader>
          <ModalBody>
            <Stack divider={<StackDivider />}>
              <PatchNotesSection
                title='Exports changed'
                description='In compliance with OGC, exporting constituents is no longer available.'
              />
              <PatchNotesSection
                title='Personally Identifiable Information Disclaimer updated'
                description='In compliance with OGC, disclaimer for PII updated. Please note that updates are in development to enforce maintenance of PII in our system.'
              />

              <PatchNotesSection
                title='Developer Notes'
                description='Please continue to use the feedback form or email Yogi to report any issues with Council Connect! Thank you for your patience as the team strives to improve the system.'
              />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' onClick={setViewed}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default PatchNotesModal
