import React from 'react'

import { FilterInput, FilterMenuBase } from '../../ui'

const GroupFilterMenu = ({ isOpen, onClose, filterButtonRef }) => {
  return (
    <FilterMenuBase
      heading='Constituent Group Filters'
      store='directory'
      buttonRef={filterButtonRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <FilterInput
        label='Name'
        filterKey='name'
        multiSelect
        store='directory'
        placeholder='Search by group name'
      />
      <FilterInput
        label='Alias'
        filterKey='alias'
        multiSelect
        store='directory'
        placeholder='Search by group alias'
      />
    </FilterMenuBase>
  )
}

export default GroupFilterMenu
