import React from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Heading,
  HStack,
  Icon,
  useToast,
} from '@chakra-ui/react'
import { UserCircleIcon } from '@heroicons/react/24/outline'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'

import ConstituentForm from './form/ConstituentForm'
import { setErrors } from '../../../redux/features/groupsSlice'
import { calculateMS } from '../../../utils'

const CreateConstituentModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()
  const toast = useToast()
  const navigate = useNavigate()
  const handleCreate = id => {
    navigate(`/directory/constituents/${id}`)
    toast({
      title: 'Constituent added',
      status: 'success',
      isClosable: true,
      duration: calculateMS(2.5),
    })
  }

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={() => {
        onClose()
        dispatch(setErrors(null))
      }}
      size={{ base: 'full', sm: '4xl' }}
      scrollBehavior='inside'
      motionPreset={null}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader mt={2} mb={2}>
          <HStack>
            <Heading size='lg'>Create New Constituent</Heading>
            <Icon as={UserCircleIcon} boxSize={30} />
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody mb={3} py={0} px={{ base: 2, sm: 4 }}>
          <ConstituentForm handleFormSubmit={handleCreate} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CreateConstituentModal
