import React from 'react'

import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react'

import ConstituentGroupForm from './ConstituentGroupForm'

const CreateGroupModal = ({ onCreate, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />

      <ModalContent>
        <ModalBody py={5}>
          <ConstituentGroupForm onClose={onClose} onCreate={onCreate} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CreateGroupModal
