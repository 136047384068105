import React from 'react'

import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'

const SiteDisclaimer = () => {
  const validatePreviouslyAccepted = () => {
    const disclaimerPreviouslyAccepted =
      localStorage.getItem('disclaimerAccepted')

    if (!disclaimerPreviouslyAccepted) return false
    const dateLastAccepted = new Date(disclaimerPreviouslyAccepted)
    const daysPassed = (new Date() - dateLastAccepted) / (1000 * 60 * 60 * 24)

    return daysPassed < 30
  }

  const showDisclaimer = !validatePreviouslyAccepted()
  const { isOpen, onClose } = useDisclosure({
    defaultIsOpen: showDisclaimer,
  })

  const handleAccept = () => {
    let currentDate = new Date()

    localStorage.setItem('disclaimerAccepted', currentDate.toISOString())
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size='2xl'
    >
      <ModalOverlay backdropFilter='blur(5px)' />
      <ModalContent>
        <ModalHeader>
          Personally Identifiable Information Disclaimer
        </ModalHeader>
        <ModalBody>
          <Stack>
            <Text as='b'>
              If you have access to personally identifiable information, then
              you are responsible for protecting it.
            </Text>
            <Text>
              Only access or store personally identifiable information if you
              have a legitimate business need related to performing your duties.
              If you have access to personally identifiable information (PII)
              when you should not, immediately notify the Office of General
              Counsel.
            </Text>
            <Text>
              Any unauthorized use of any PII collected here is strictly
              prohibited by law and policy and unauthorized and/or unapproved
              disclosure of it could result in severe legal penalties. Council
              staffers should not permit any unauthorized viewing of records
              contained in a Council system of records.
            </Text>
            <Text>
              Any information: (i) that directly identifies an individual (e.g.,
              name, address, social security number or other identifying number
              or code, telephone number, email address, etc.) or (ii) by which
              an agency intends to identify specific individuals in conjunction
              with other data elements, i.e., indirect identification. Other
              data elements such as a financial account number, citizenship or
              immigration status, or medical information, in conjunction with
              the identity of an individual (directly or indirectly inferred),
              are also considered PII.
            </Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Flex w='100%' align='center'>
            <Text variant='subheading-gray'>Last Updated July 22, 2024</Text>
            <Spacer />
            <Button colorScheme='green' onClick={handleAccept}>
              Accept
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SiteDisclaimer
