import React, { useCallback, useMemo } from 'react'

import { AddIcon, EmailIcon } from '@chakra-ui/icons'
import {
  Box,
  Text,
  Button,
  Stack,
  Flex,
  Checkbox,
  Textarea,
} from '@chakra-ui/react'
import { FastField, FieldArray, useFormikContext } from 'formik'
import { useSelector } from 'react-redux'

import BaseArrayField from './fields/BaseArrayField'
import GroupField from './fields/GroupField'
import {
  AddressForm,
  ContactInfoForm,
  FormField,
  ShowHideFormField,
} from '../../../ui'
import { ConstituentTagSelect, LanguageSelect } from '../../../ui/select'
import { isAddressEmpty } from '../../../utils'

const ConstituentFormInputs = ({ field = '' }) => {
  const user = useSelector(state => state.auth.user)

  const { values: formikValues, setFieldValue } = useFormikContext()

  const values = useMemo(
    () => (field ? formikValues.newConstituent : formikValues) || {},
    [field, formikValues]
  )

  /** Privacy */
  const showDistrictLock = useMemo(
    () =>
      !values.district || values.district === user.district || user.is_admin,
    [values.district, user.district, user.is_admin]
  )

  const handleDistrictLock = useCallback(
    e => setFieldValue(field + 'visible_to_district_only', e.target.checked),
    [setFieldValue, field]
  )

  /** Addresses */
  const isHomeAddressPrimary = useMemo(
    () =>
      values.primary_address === 'home' && !isAddressEmpty(values.home_address),
    [values.primary_address, values.home_address]
  )

  const isBusinessAddressPrimary = useMemo(
    () =>
      values.primary_address === 'business' &&
      !isAddressEmpty(values.business_address),
    [values.primary_address, values.business_address]
  )

  const defaultShowBusinessAddress = useMemo(
    () => !isAddressEmpty(values.business_address),
    [values.business_address]
  )

  const handleSetPrimaryAddress = useCallback(
    value => setFieldValue(field + 'primary_address', value),
    [setFieldValue, field]
  )

  /** Contact Info */
  const handleAddContactInfo = useCallback(
    push =>
      push({
        contact_type: '',
        contact_data: '',
        description: '',
      }),
    []
  )

  const selectedLanguages = useMemo(
    () =>
      values.languages.map(language => ({ label: language, value: language })),
    [values.languages]
  )

  const selectedTags = useMemo(
    () => values.tags.map(tag => ({ label: tag.label, value: tag })),
    [values.tags]
  )

  return (
    <Stack gap={5} px={{ base: 0, sm: 2 }} overflowY='auto'>
      <Box>
        <Text as='b'>Privacy</Text>
        <Box>
          {showDistrictLock && (
            <Checkbox
              id={`constituent-district-lock-${field}`}
              isChecked={values.visible_to_district_only}
              onChange={handleDistrictLock}
            >
              Set visible to District {user.district} Only
            </Checkbox>
          )}
        </Box>
      </Box>

      {/* Constituent Name */}
      <Box>
        <Text as='b'>Personal Information</Text>
        <Stack mt={2}>
          <FormField
            field={field + 'title'}
            placeholder='Title'
            shouldOptimize
          />
          <Stack
            direction={{ base: 'column', md: 'row' }}
            gap={{ md: '4' }}
            alignItems='top'
          >
            <Stack as={Flex} direction='column' spacing={3} flex='2'>
              <FormField
                field={field + 'first_name'}
                placeholder='First Name *'
                isRequired
                shouldOptimize
              />
              <FormField
                field={field + 'middle_name'}
                placeholder='Middle Name'
                shouldOptimize
              />
              <FormField
                field={field + 'last_name'}
                placeholder='Last Name *'
                isRequired
                shouldOptimize
              />
            </Stack>
            <Stack
              direction={'column'}
              flex='1'
              pt={{ base: 3, md: 0 }}
              spacing={3}
            >
              <FormField
                field={field + 'preferred_name'}
                placeholder='Preferred Name'
                shouldOptimize
              />
              <FormField
                field={field + 'suffix'}
                placeholder='Suffix'
                shouldOptimize
              />
              <FormField
                field={field + 'pronouns'}
                placeholder='Pronouns'
                shouldOptimize
              />
            </Stack>
          </Stack>
        </Stack>
      </Box>

      {/* Constituent Contact Information */}
      <Box>
        <Text as='b'>Contact Information</Text>

        <Stack mt='2'>
          {/* PRIMARY EMAIL */}
          <FormField
            type='email'
            field={field + 'email'}
            placeholder='Primary Email'
            placeholderIcon={EmailIcon}
            shouldOptimize
          />

          {/* CONTACT INFO */}
          <FastField name={field + 'contact_info'}>
            {() => (
              <FieldArray name={field + 'contact_info'}>
                {({ push, ...arrayHelpers }) => (
                  <>
                    {values.contact_info?.map((contact, index) => (
                      <ContactInfoForm
                        field={field + 'contact_info'}
                        key={index}
                        index={index}
                        {...contact}
                        {...arrayHelpers}
                      />
                    ))}
                    {/* ADD CONTACT INFO BUTTON */}
                    <Button
                      variant='link'
                      onClick={() => handleAddContactInfo(push)}
                      leftIcon={<AddIcon />}
                      h='2rem'
                    >
                      Add phone number or email
                    </Button>
                  </>
                )}
              </FieldArray>
            )}
          </FastField>
        </Stack>
      </Box>

      {/* Constituent Address Information */}
      {/* HOME ADDRESS */}
      <Box>
        <Text as='b'> Home Address Information </Text>
        <AddressForm
          field={field + 'home_address'}
          isPrimary={isHomeAddressPrimary}
          setPrimary={() => handleSetPrimaryAddress('home')}
        />
      </Box>

      {/* BUSINESS ADDRESS */}
      <Box>
        <ShowHideFormField
          heading=<Text as='b'> Business Address Information </Text>
          buttonVariant='text'
          field='Business Address Fields'
          defaultShow={defaultShowBusinessAddress}
        >
          <AddressForm
            field={field + 'business_address'}
            isPrimary={isBusinessAddressPrimary}
            setPrimary={() => handleSetPrimaryAddress('business')}
          />
        </ShowHideFormField>
      </Box>

      {/* LANGUAGES */}
      <Box>
        <BaseArrayField label='Languages' field={field + 'languages'}>
          {({ onSelect, onRemove, onClear }) => (
            <LanguageSelect
              onSelect={onSelect}
              onRemove={onRemove}
              onClear={onClear}
              initialSelected={selectedLanguages}
              dropdownHeight={150}
              inputProps={{
                id: 'language-select',
              }}
            />
          )}
        </BaseArrayField>
      </Box>

      {/* Tags */}
      <Box>
        <BaseArrayField label='Tags' field={field + 'tags'}>
          {({ onSelect, onRemove, onClear }) => (
            <ConstituentTagSelect
              onSelect={onSelect}
              onRemove={onRemove}
              onClear={onClear}
              initialSelected={selectedTags}
              dropdownHeight={150}
              inputProps={{
                id: 'tag-select',
              }}
            />
          )}
        </BaseArrayField>
      </Box>

      {/* GROUPS */}
      <Box>
        <GroupField field={field + 'groups'} />
      </Box>

      {/* NOTES */}
      <Box>
        <ShowHideFormField
          heading=<Text as='b'>Notes</Text>
          buttonVariant='text'
          field='Notes'
          defaultShow={!!values.notes}
        >
          <FormField
            field={field + 'notes'}
            placeholder='Record any additional notes about constituent, if needed'
            inputType={Textarea}
            shouldOptimize
          />
        </ShowHideFormField>
      </Box>
    </Stack>
  )
}

export default ConstituentFormInputs
