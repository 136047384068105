import React from 'react'

import { Box, Divider, Text } from '@chakra-ui/react'

import { districtOptions } from '../../../constants'
import { setDirectoryFilters } from '../../../redux/features/directorySlice'
import { FilterInput, FilterMenuBase } from '../../ui'
import { AdvancedFilterInput } from '../../ui/filters'
import { ConstituentTagSelect, TopicSelect } from '../../ui/select'

const ConstituentFilterMenu = ({ isOpen, onClose, filterButtonRef }) => {
  return (
    <FilterMenuBase
      heading='Constituent Filters'
      store='directory'
      buttonRef={filterButtonRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <FilterInput
        label='Name'
        filterKey='name'
        multiSelect
        store='directory'
        placeholder='Filter by name'
      />
      <FilterInput
        label='Email'
        filterKey='email'
        multiSelect
        store='directory'
        placeholder='Filter by email'
      />
      <FilterInput
        label='Address'
        filterKey='address'
        store='directory'
        multiSelect
        placeholder='Filter by address (city, state, zipcode, etc)'
      />
      <Box>
        <Text fontSize='lg'>Associated Topics</Text>
        <AdvancedFilterInput
          setFilters={setDirectoryFilters}
          filterKey='associated_topics'
          labelKey='name'
          store='directory'
        >
          <TopicSelect
            inputProps={{
              id: 'associated-topics-filter',
              placeholder: 'Filter by associated topic(s)',
            }}
          ></TopicSelect>
        </AdvancedFilterInput>
      </Box>
      <Box>
        <Text fontSize='lg'>Tags</Text>
        <AdvancedFilterInput
          store='directory'
          setFilters={setDirectoryFilters}
          filterKey='tags'
          labelKey='label'
        >
          <ConstituentTagSelect
            inputProps={{
              id: 'tags-filter',
              placeholder: 'Filter by tags(s)',
            }}
            preventCreate
          />
        </AdvancedFilterInput>
      </Box>
      <FilterInput
        label='Council District'
        filterKey='district__in'
        store='directory'
        multiSelect
        placeholder='Filter by council district'
        options={districtOptions}
      />
      <Divider my={3} />
      <FilterInput
        placeholder='Hide CouncilSTAT constituents'
        filterKey='hide_cstat'
        store='directory'
        inputType='checkbox'
        hideTags
      />
    </FilterMenuBase>
  )
}

export default ConstituentFilterMenu
