import React, { useEffect } from 'react'

import {
  Avatar,
  Card,
  Center,
  Divider,
  Grid,
  GridItem,
  HStack,
  Heading,
  Icon,
  IconButton,
  Spacer,
  Text,
  VStack,
  Button,
  useBoolean,
  Box,
} from '@chakra-ui/react'
import {
  AtSymbolIcon,
  PencilSquareIcon,
  PhoneIcon,
} from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import {
  clearStaffInstance,
  loadStaffInstance,
} from '../../redux/features/staffSlice'
import { ChangePasswordModal } from '../auth'
import { EditStaffDrawer } from '../directory'
import { Loading, NoValue, NotFound } from '../ui'

const AvatarBox = () => (
  <GridItem rowSpan={1} colSpan={1} p={3}>
    <Avatar bg='blue.400' size='xl' />
  </GridItem>
)

const NameBox = ({
  name,
  dateJoined,
  pronouns,
  handleEditClick,
  userCanEdit,
}) => {
  const date = new Date(dateJoined)
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'America/New_York',
  }

  const formattedDate = date.toLocaleString('en-US', options) // Format the datetime with custom options
  return (
    <GridItem colSpan={{ base: 11, md: 5, lg: 6 }}>
      <Card p={5}>
        <HStack align='center'>
          <HStack align='baseline'>
            <Heading> {name} </Heading>
            {pronouns && <Text fontSize='2xl'> ({pronouns}) </Text>}
          </HStack>
          <Spacer />
          <IconButton
            icon={<PencilSquareIcon />}
            boxSize={10}
            variant='unstyled'
            onClick={handleEditClick}
            visibility={userCanEdit ? 'visible' : 'hidden'}
          />
        </HStack>
        <Text as='i'> Account created on {formattedDate} </Text>
      </Card>
    </GridItem>
  )
}

const ContactLabel = ({ category, value, icon }) => (
  <HStack align='top'>
    <Icon as={icon} boxSize={6} />
    <Text as='b'> {category}: </Text>
    <Text fontSize='lg'> {value || 'None'} </Text>
  </HStack>
)

const ContactBox = ({ email, role, district, work_phone }) => (
  <GridItem colSpan={{ base: 12, md: 3, lg: 7 }} rowSpan={3} p={5} pt={0}>
    <Divider mb={5} />
    <VStack align='left' fontSize='lg'>
      <Label name='City Council District' value={district} />
      <Label name='Role' value={role} />
      <ContactLabel category='Email' value={email} icon={AtSymbolIcon} />
      <ContactLabel category='Work Phone' value={work_phone} icon={PhoneIcon} />
    </VStack>
  </GridItem>
)

const Label = ({ name, value }) => (
  <HStack>
    <Text as='b' fontSize='lg'>
      {name}:
    </Text>
    <Text fontSize='xl'> {value || <NoValue />} </Text>
  </HStack>
)

const ProfileInformationBox = ({
  user,
  handleChangePasswordClick,
  userCanChangePassword,
}) => (
  <GridItem colSpan={{ base: 12, md: 3 }} rowSpan={{ base: 2, lg: 4 }}>
    <Card p={4}>
      <Heading fontSize='2xl' mb={4}>
        Profile Information
      </Heading>
      <VStack align='left'>
        <Label name='First Name' value={user?.first_name} />
        <Label name='Middle Name' value={user?.middle_name} />
        <Label name='Last Name' value={user?.last_name} />
        <Label name='Pronouns' value={user?.pronouns} />
        <Label name='Preferred Name' value={user?.preferred_name} />
        <Label name='Suffix' value={user?.suffix} />
      </VStack>
    </Card>
    {userCanChangePassword && (
      <Box align='end'>
        <Button m={2} onClick={handleChangePasswordClick} variant='ghost'>
          Change Password
        </Button>
      </Box>
    )}
  </GridItem>
)

const StaffProfile = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const loading = useSelector(state => state.staff.loading)
  const staff = useSelector(state => state.staff.instance)
  const user = useSelector(state => state.auth.user)
  const [editModalOpen, setEditModalOpen] = useBoolean()
  const [changePasswordOpen, setChangePasswordOpen] = useBoolean()

  useEffect(() => {
    dispatch(loadStaffInstance({ id }))
    return () => {
      dispatch(clearStaffInstance())
    }
  }, [dispatch, id])

  if (loading) {
    return <Loading />
  }

  if (!staff) {
    return (
      <NotFound message='This staff does not exist or has been deactivated.' />
    )
  }

  const userCanEdit = user?.id === staff?.user?.id || user?.is_admin
  const userCanChangePassword = user?.id === staff?.user?.id

  document.title = `${staff?.user?.full_name} - Staff | Council Connect`
  return (
    <Center>
      <Grid
        templateRows={{ base: 'repeat(1, 1fr)', lg: 'repeat(4, 1fr)' }}
        templateColumns={{
          base: 'repeat(1, 0fr)',
          md: 'repeat(6, 1fr)',
          lg: 'repeat(10, 1fr)',
        }}
        gap={3}
        maxW='7xl'
        m={6}
      >
        <AvatarBox />
        <NameBox
          name={staff?.user?.full_name}
          dateJoined={staff?.user?.date_joined}
          pronouns={staff?.user?.pronouns}
          userCanEdit={userCanEdit}
          handleEditClick={setEditModalOpen.on}
        />
        <ProfileInformationBox
          user={staff?.user}
          handleChangePasswordClick={setChangePasswordOpen.on}
          userCanChangePassword={userCanChangePassword}
        />
        <ContactBox
          email={staff?.user?.email}
          role={staff?.role}
          district={staff?.district}
          work_phone={staff?.work_phone}
        />
        {userCanChangePassword && (
          <ChangePasswordModal
            isOpen={changePasswordOpen && userCanChangePassword}
            onClose={setChangePasswordOpen.off}
          />
        )}
        <EditStaffDrawer
          isOpen={editModalOpen && userCanEdit}
          onClose={setEditModalOpen.off}
        />
      </Grid>
    </Center>
  )
}

export default StaffProfile
