import React, { useCallback, useMemo } from 'react'

import { useFormikContext } from 'formik'

import { StaffSelect } from '../../../../ui/select'

const ReferralStaffField = () => {
  const { values, setFieldValue } = useFormikContext()

  const onSelect = useCallback(
    option => setFieldValue('referral_staff', option),
    [setFieldValue, values.referral_staff]
  )

  const onClear = useCallback(
    () => setFieldValue('referral_staff', null),
    [setFieldValue]
  )

  const initialSelected = useMemo(
    () =>
      values.referral_staff
        ? [
            {
              label: values.referral_staff.full_name,
              value: values.referral_staff,
            },
          ]
        : [],
    [values.referral_staff]
  )

  return (
    <StaffSelect
      onSelect={onSelect}
      onRemove={onClear}
      onClear={onClear}
      field='referral_staff'
      initialSelected={initialSelected}
      inputProps={{
        id: 'referral-staff-select',
        placeholder: 'Select staff who referred constituent to you',
      }}
    />
  )
}
export default ReferralStaffField
