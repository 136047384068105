import React, { useCallback, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import TagSelect from './TagSelect'
import {
  clearConstituentTags,
  loadConstituentTags,
  paginateConstituentTags,
} from '../../../redux/features/constituentsSlice'

const ConstituentTagSelect = props => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(clearConstituentTags())
    dispatch(loadConstituentTags())
  }, [dispatch])

  const loadMoreTags = useCallback(() => {
    dispatch(paginateConstituentTags())
  }, [])

  const tagOptions = useSelector(state => state.constituents.tag_options) || []

  return (
    <TagSelect
      tagOptions={tagOptions}
      loadMoreOptions={loadMoreTags}
      {...props}
    />
  )
}

export default ConstituentTagSelect
