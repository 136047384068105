import React from 'react'

import { Avatar, Stack, Text, Tooltip } from '@chakra-ui/react'

const Person = ({ link, name, hideAvatar }) => (
  <Tooltip
    label='Click to open profile in new tab'
    placement='bottom-start'
    openDelay={200}
  >
    <Stack
      direction='row'
      align='center'
      sx={{ userSelect: 'none' }}
      _hover={{
        textShadow: '0px 0px 1px currentColor',
        cursor: 'pointer',
      }}
      onClick={() => window.open(link, '_blank')}
    >
      {!hideAvatar && <Avatar name={name} size='sm' />}
      <Text align='center'> {name} </Text>
    </Stack>
  </Tooltip>
)
export default Person
