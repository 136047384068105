import React from 'react'

import {
  Table,
  Tbody,
  Tr,
  Td,
  Tag,
  Text,
  Wrap,
  Tooltip,
  HStack,
  Icon,
  TagLabel,
  Box,
  WrapItem,
} from '@chakra-ui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { useSelector } from 'react-redux'

import Attachments from './Attachments'
import { priorityColorSchemes, statusColors } from '../../../constants/statuses'
import { downloadFile } from '../../../utils'
import { AgencyButton, ContactInfoBox } from '../../directory'
import {
  AddressText,
  FormattedDate,
  NoValue,
  Person,
  ShowHideFormField,
} from '../../ui'
import { renderTextLines } from '../../utils'

const TableRow = ({ heading, children, verticalAlign = 'center', pt }) => (
  <Tr>
    <Td w='15%' px={0} verticalAlign={verticalAlign} pt={pt}>
      <Text as='b'> {heading.toUpperCase()} </Text>
    </Td>
    <Td verticalAlign={verticalAlign} px={2} my='auto'>
      {children || <NoValue />}
    </Td>
  </Tr>
)

const CaseworkDetailTable = ({
  tableVariant = 'simple',
  status,
  constituent,
  agencies,
  address,
  district,
  topics,
  tags,
  details,
  priority,
  last_updated,
  created_at,
  opened_at,
  closed_at,
  comments,
  intake_method,
  referral_staff,
  referral_category,
  reference_numbers,
  attachments,
  detailView = false, // show for detail page only
  hideConstituent,
  filtered = false, //show certain values if filter is active
}) => {
  const user = useSelector(state => state.auth.user)
  const filters = useSelector(state => state.casework.filters)

  status = status.toUpperCase()

  return (
    <Table variant={tableVariant}>
      <Tbody>
        <TableRow heading='Status'>
          <Tag bgColor={statusColors[status]} color='offWhite'>
            {status}
          </Tag>
        </TableRow>
        {!hideConstituent && (
          <TableRow heading='Constituent' verticalAlign='top' pt={6}>
            {constituent && (
              <>
                <ShowHideFormField
                  heading={
                    <Person
                      link={`/directory/constituents/${constituent.id}`}
                      name={constituent.full_name}
                    />
                  }
                >
                  <ContactInfoBox {...constituent} />
                </ShowHideFormField>
              </>
            )}
          </TableRow>
        )}
        {user?.role === 'Admin' && (
          <TableRow heading='District'>{district}</TableRow>
        )}
        <TableRow heading='Topic'>
          {topics?.length > 0 ? (
            <Wrap>
              {topics.map((topic, index) => (
                <Tooltip key={index} label={topic.issue}>
                  <Tag variant='outline' p={2}>
                    {topic.name}
                  </Tag>
                </Tooltip>
              ))}
            </Wrap>
          ) : (
            <HStack>
              <Icon as={ExclamationTriangleIcon} color='yellow.500' />
              <Text color='yellow.500'>Unassigned</Text>
            </HStack>
          )}
        </TableRow>
        <TableRow heading='Details' verticalAlign='top'>
          <Box maxH={350} overflowY='auto' pr={2}>
            {renderTextLines(details)}
          </Box>
        </TableRow>

        {(detailView || comments) && (
          <TableRow heading='Constituent Comments' verticalAlign='top'>
            {comments ? (
              <Box maxH={200} overflowY='auto' pr={2}>
                {renderTextLines(comments)}
              </Box>
            ) : (
              <NoValue />
            )}
          </TableRow>
        )}

        {detailView && (
          <>
            {
              <TableRow heading='Priority'>
                <Tag
                  colorScheme={priorityColorSchemes[priority]}
                  borderRadius='md'
                  textAlign='center'
                  textTransform='uppercase'
                >
                  {priority}
                </Tag>
              </TableRow>
            }
            <TableRow heading='Report Address'>
              <AddressText address={address} />
            </TableRow>
            <TableRow heading='Last Updated'>
              <FormattedDate date={last_updated} />
            </TableRow>
            {status === 'REQUESTED' && (
              <TableRow heading='Submitted'>
                <FormattedDate date={created_at} />
              </TableRow>
            )}
            <TableRow heading='Opened'>
              <FormattedDate date={opened_at} />
            </TableRow>
            <TableRow heading='Closed'>
              <FormattedDate date={closed_at} />
            </TableRow>
          </>
        )}
        {(detailView || (filtered && filters['agencies'])) && (
          <TableRow heading='City Agencies'>
            {agencies.length > 0 ? (
              <Wrap>
                {agencies.map((agency, index) => (
                  <WrapItem key={index}>
                    <AgencyButton agencyName={agency} />
                  </WrapItem>
                ))}
              </Wrap>
            ) : (
              <NoValue />
            )}
          </TableRow>
        )}
        {(detailView || (filtered && filters['tags'])) && (
          <TableRow heading='Tags'>
            <Wrap>
              {tags.length > 0 ? (
                tags.map((tag, index) => (
                  <Tag key={index}>
                    <TagLabel>{tag.label}</TagLabel>
                  </Tag>
                ))
              ) : (
                <NoValue />
              )}
            </Wrap>
          </TableRow>
        )}
        {detailView && (
          <TableRow heading='Intake Method'>{intake_method}</TableRow>
        )}
        {detailView && (
          <TableRow heading='Referral Staff'>
            {referral_staff ? (
              <Person
                link={`/directory/staff/${referral_staff.id}`}
                name={referral_staff.full_name}
              />
            ) : (
              <NoValue />
            )}
          </TableRow>
        )}
        {(detailView || (filtered && filters['referral_category'])) && (
          <TableRow heading='Referral Category'>{referral_category}</TableRow>
        )}
        {detailView && (
          <TableRow heading='Reference Numbers' verticalAlign='top'>
            {reference_numbers ? (
              <Box maxH={200} overflowY='auto'>
                {renderTextLines(reference_numbers)}
              </Box>
            ) : (
              <NoValue />
            )}
          </TableRow>
        )}
        {detailView && (
          <TableRow heading='Attachments'>
            {attachments?.length > 0 ? (
              <Attachments
                allAttachments={attachments}
                onOpenAttachment={file => {
                  downloadFile(file.file_url, file.file_name)
                }}
              />
            ) : (
              <NoValue />
            )}
          </TableRow>
        )}
      </Tbody>
    </Table>
  )
}

export default CaseworkDetailTable
