import { call, put, select, takeLatest } from 'redux-saga/effects'

import { getFilterQueryString } from '../../utils'
import backendAPI from '../axiosConfig'
import {
  cancelLoading,
  createGroup,
  deleteGroup,
  loadGroupInstance,
  loadGroupOptions,
  loadGroups,
  paginate,
  paginateGroupOptions,
  setErrors,
  storeGroupInstance,
  storeGroupOptions,
  storeGroups,
  updateGroup,
} from '../features/groupsSlice'

const GROUPS_ENDPOINT = '/api/constituents/groups/'
function* loadGroupsSaga() {
  try {
    const filters = yield select(state => state.directory.filters)

    const response = yield call(() => {
      return backendAPI.get(
        `${GROUPS_ENDPOINT}?${getFilterQueryString(filters)}`
      )
    })

    const { count, next, results } = response.data
    yield put(storeGroups({ count, next, results }))
  } catch (error) {
    console.error('Loading groups failed', error)
    yield put(setErrors('Loading groups failed', error))
  }
}

function* paginateSaga() {
  try {
    const paginateUrl = yield select(state => state.groups.next)
    if (!paginateUrl) {
      yield put(cancelLoading())
      return
    }
    const response = yield call(() => {
      return backendAPI.get(paginateUrl)
    })
    const { count, next, results } = response.data
    yield put(storeGroups({ count, next, results }))
  } catch (error) {
    console.error('Error paginating groups', error)
    yield put(setErrors('Error paginating groups', error))
  }
}

function* loadGroupInstanceSaga(action) {
  try {
    const { id } = action.payload
    const response = yield call(() => {
      return backendAPI.get(`/api/constituents/groups/${id}/`)
    })

    yield put(storeGroupInstance(response.data))
  } catch (error) {
    console.error('Loading group instance failed', error)
    yield put(setErrors('Loading group instance failed', error))
  }
}

function* createGroupSaga(action) {
  const { values, callbackSuccess } = action.payload
  try {
    const response = yield call(() => {
      return backendAPI.post(GROUPS_ENDPOINT, values)
    })
    if (callbackSuccess) yield call(callbackSuccess, response.data)
  } catch (error) {
    console.error('Create group failed', error)
    yield put(setErrors('Create group failed', error))
  }
}

function* updateGroupSaga(action) {
  const { values, id, callbackSuccess } = action.payload
  try {
    yield call(() => {
      return backendAPI.put(GROUPS_ENDPOINT + `${id}/`, values)
    })

    if (callbackSuccess) yield call(callbackSuccess)
  } catch (error) {
    console.error('Update group failed', error)
    yield put(setErrors('Update group failed', error))
  }
}

function* deleteGroupSaga(action) {
  const { id, callbackSuccess, callbackFailure } = action.payload
  try {
    yield call(() => {
      return backendAPI.delete(GROUPS_ENDPOINT + `${id}/`)
    })
    if (callbackSuccess) yield call(callbackSuccess)
  } catch (error) {
    console.error('Delete group failed', error)
    yield put(setErrors('Update group failed', error))
    if (callbackFailure) yield call(callbackFailure)
  }
}

function* loadGroupOptionsSaga(action) {
  try {
    const { value: query } = action.payload

    const response = yield call(() => {
      if (query) {
        return backendAPI.get(GROUPS_ENDPOINT + `select/?name=${query}`)
      }
      return backendAPI.get(GROUPS_ENDPOINT + `select/`)
    })

    const { results, next } = response.data
    yield put(storeGroupOptions({ results, next }))
  } catch (error) {
    console.error('Loading group options failed', error)
    yield put(setErrors('Loading group options failed', error))
  }
}

function* paginateGroupOptionsSaga() {
  const nextUrl = yield select(state => state.groups.options_next)
  if (!nextUrl) return

  try {
    const response = yield call(() => {
      return backendAPI.get(nextUrl)
    })
    const { results, next } = response.data
    yield put(storeGroupOptions({ results, next }))
  } catch (error) {
    console.error(error)
  }
}

function* groupsAPI() {
  yield takeLatest(loadGroups, loadGroupsSaga)
  yield takeLatest(paginate, paginateSaga)
  yield takeLatest(loadGroupInstance, loadGroupInstanceSaga)
  yield takeLatest(createGroup, createGroupSaga)
  yield takeLatest(updateGroup, updateGroupSaga)
  yield takeLatest(deleteGroup, deleteGroupSaga)
  yield takeLatest(loadGroupOptions, loadGroupOptionsSaga)
  yield takeLatest(paginateGroupOptions, paginateGroupOptionsSaga)
}

export default groupsAPI
