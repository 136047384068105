import React from 'react'

import { Text } from '@chakra-ui/react'

import { ConfirmActionDialog } from '../../ui'

const ConfirmDeleteGroupDialog = ({ group, isOpen, onConfirm, onClose }) => {
  if (!group) return <></>
  return (
    <ConfirmActionDialog
      isOpen={isOpen}
      onConfirm={onConfirm}
      onClose={onClose}
      scheme='delete'
    >
      <Text as='b'> Group &quot;{group.name}&quot; will be deleted.</Text>
      <Text>
        There are currently <b>{group.member_count} constituents</b> in this
        group. Are you sure you want to proceed?
      </Text>
    </ConfirmActionDialog>
  )
}

export default ConfirmDeleteGroupDialog
