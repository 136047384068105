import * as Yup from 'yup'

export const languages = [
  'Amharic, Somali, or other Afro-Asiatic languages',
  'Arabic',
  'Armenian',
  'Bengali',
  'Chinese (Cantonese)',
  'Chinese (Mandarin)',
  'Chinese (Other)',
  'French',
  'Cajun',
  'German',
  'Greek',
  'Gujarati',
  'Haitian',
  'Hebrew',
  'Hindi',
  'Japanese',
  'Korean',
  'Malayalam, Kannada, or other Dravidian languages',
  'Nepali, Marathi, or other Indic languages',
  'Persian (incl. Farsi, Dari)',
  'Polish',
  'Portuguese',
  'Russian',
  'Other Slavic languages',
  'Serbo-Croatian',
  'Spanish',
  'Swahili or other languages of Central, Eastern, and Southern Africa',
  'Tagalog (incl. Filipino)',
  'Tamil',
  'Telugu',
  'Thai, Lao, or other Tai-Kadai languages',
  'Ukrainian or other Slavic languages',
  'Urdu',
  'Vietnamese',
  'Yiddish, Pennsylvania Dutch or other West Germanic languages',
  'Yoruba, Twi, Igbo, or other languages of Western Africa',
]

export const intakeMethodOptions = [
  'Called in',
  'Emailed',
  'Event',
  'Letter',
  'Mail',
  'Social Media',
  'Text Message',
  'Walk in',
]

export const referralCategoryOptions = ['Council Member', 'Elected Official']

export const statusOptions = [
  'Pending',
  'In Progress',
  'Closed',
  'Transferred',
  'New Transfer',
]

export const priorityOptions = ['Low', 'Medium', 'High']

export const districtOptions = Array.from({ length: 51 }, (_, i) =>
  String(i + 1)
).concat('Central Staff')

export const contactTypeOptions = [
  'Email',
  'Cell Phone',
  'Work Phone',
  'Home Phone',
  'Other',
]
export const initialAddressValues = {
  label: '',
  line1: '',
  line2: '',
  po_box: '',
  city: '',
  state: 'NY',
  zipcode: '',
  verified: false,
}

export const initialConstituentValues = {
  title: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  email: '',
  preferred_name: '',
  suffix: '',
  pronouns: '',
  home_address: initialAddressValues,
  business_address: initialAddressValues,
  primary_address: 'home',
  contact_info: [],
  languages: [],
  groups: [],
  tags: [],
  notes: '',
  visible_to_district_only: true,
}

// const phoneNumberRegex = /^$|^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/

export const addressValidationSchema = Yup.object().shape({
  label: Yup.string(),
  line1: Yup.string(),
  line2: Yup.string(),
  po_box: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  zipcode: Yup.string().matches(/^\d{5}$/, 'Invalid 5 digit zipcode'),
})

const constituentShape = {
  title: Yup.string(),
  first_name: Yup.string(),
  middle_name: Yup.string(),
  last_name: Yup.string(),
  email: Yup.string().email('Invalid email address'),
  preferred_name: Yup.string(),
  suffix: Yup.string(),
  pronouns: Yup.string(),
  home_address: addressValidationSchema,
  business_address: addressValidationSchema,
  primary_address: Yup.string(),
  contact_info: Yup.array().of(
    Yup.object().shape({
      contact_type: Yup.string(),
      contact_data: Yup.string(),
      description: Yup.string(),
    })
  ),
  languages: Yup.array().of(Yup.string()),
  tags: Yup.array().of(
    Yup.object().shape({
      label: Yup.string(),
      district: Yup.string(),
    })
  ),
  notes: Yup.string(),
  visible_to_district_only: Yup.bool(),
}

export const caseworkNewConstituentValidationSchema = Yup.object().shape({
  ...constituentShape,
  first_name: Yup.string(),
  last_name: Yup.string(),
})

export const constituentValidationSchema = Yup.object().shape(constituentShape)

export const groupValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  alias: Yup.string(),
  description: Yup.string(),
  role: Yup.string(),
  constituents: Yup.array().of(Yup.object()),
})

export const editStaffValidationSchema = Yup.object().shape({
  user: Yup.object().shape({
    email: Yup.string().email(),
    first_name: Yup.string().required('First name is required'),
    middle_name: Yup.string(),
    last_name: Yup.string().required('Last name is required'),
    preferred_name: Yup.string(),
    suffix: Yup.string(),
    pronouns: Yup.string(),
  }),
  role: Yup.string(),
  district: Yup.string(),
})
