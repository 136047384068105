import React from 'react'

import {
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Tag,
  Text,
} from '@chakra-ui/react'
import {
  ArrowUturnLeftIcon,
  MinusIcon,
  UserIcon,
  UserMinusIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline'

import { FormField } from '../../../ui'

const colorStyles = {
  new: {
    bg: 'green.100',
    _dark: { bg: 'green.800' },
  },
  removed: {
    bg: 'red.200',
    _dark: { bg: 'red.800' },
  },
  undefined: {
    bg: 'gray.100',
    _dark: { bg: 'gray.800' },
  },
}

const icon = {
  new: UserPlusIcon,
  removed: UserMinusIcon,
  undefined: UserIcon,
}

const Constituent = ({ index, full_name, status, onClick = () => {} }) => (
  <Grid
    as={Tag}
    {...colorStyles[status]}
    templateAreas={{
      base: `"name actions"
               "role role"`,
      sm: `"name role actions"`,
    }}
    templateRows={{
      base: status === 'removed' ? '1fr' : 'repeat(2, 1fr)',
      sm: '1fr',
    }}
    templateColumns={{
      base: '2fr 0.25fr',
      sm: 'repeat(2, 1fr) 40px',
    }}
    minH={{ base: '80px', sm: '48px' }}
    gap={2}
    p={status === 'removed' ? 2 : 1}
  >
    <GridItem area='name'>
      <HStack pl={1}>
        <Icon as={icon[status]} />
        <Text as={status === 'removed' ? 's' : undefined} whiteSpace='nowrap'>
          {full_name}
        </Text>
      </HStack>
    </GridItem>
    <GridItem area='role' px={{ base: 4, sm: 2 }}>
      {status !== 'removed' && (
        <FormField
          field={`constituents.${index}.role`}
          placeholder='Role (Optional)'
          otherProps={{
            variant: 'flushed',
            borderColor: 'gray.500',
            size: { base: 'sm', sm: 'md' },
            maxLength: 128,
          }}
        />
      )}
    </GridItem>
    <GridItem area='actions'>
      <IconButton
        variant='unstyled'
        icon={status === 'removed' ? <ArrowUturnLeftIcon /> : <MinusIcon />}
        onClick={onClick}
        size='xs'
        color={status !== 'removed' ? 'red.400' : undefined}
      />
    </GridItem>
  </Grid>
)

export default Constituent
