import React from 'react'

import { HStack, Portal, Spinner, Stack, Text } from '@chakra-ui/react'

const ExportLoading = ({ isLoading }) => {
  if (!isLoading) return <></>

  return (
    <Portal>
      <div
        style={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          padding: 10,
          backgroundColor: '#f0f0f0',
          border: '1px solid #ccc',
          borderRadius: 5,
          align: 'center',
        }}
      >
        <Stack>
          <HStack>
            <Spinner />
            <Text as='b'>Exporting...</Text>
          </HStack>
          <Text>
            <b>Warning:</b> Leaving this page will cancel the export!
          </Text>
        </Stack>
      </div>
    </Portal>
  )
}

export default ExportLoading
