import React from 'react'

import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons'
import {
  Box,
  List,
  ListItem,
  ListIcon,
  useColorModeValue,
} from '@chakra-ui/react'

const ValidatedIcon = ({ condition }) => {
  return (
    <ListIcon
      as={condition ? CheckCircleIcon : WarningIcon}
      color={condition ? 'green.500' : 'yellow.500'}
    />
  )
}

const PasswordCriteria = ({ password, confirmPassword }) => {
  const bgColor = useColorModeValue('blue.100', 'blue.700')
  return (
    <Box p={4} backgroundColor={bgColor} borderRadius={10}>
      New passwords:
      <List>
        <ListItem>
          <ValidatedIcon condition={password.length >= 10} />
          Must have at least 10 characters.
        </ListItem>
        <ListItem>
          <ValidatedIcon condition={/[A-Z]/.test(password)} />
          Must have at least one uppercase letter.
        </ListItem>
        <ListItem>
          <ValidatedIcon condition={/[a-z]/.test(password)} />
          Must have at least one lowercase letter.
        </ListItem>
        <ListItem>
          <ValidatedIcon condition={/\d/.test(password)} />
          Must have at least one number.
        </ListItem>
        <ListItem>
          <ValidatedIcon condition={/[@_!#$%^&*()<>?/|}{~:]/.test(password)} />
          Must have at least one special character.
        </ListItem>
        <ListItem>
          <ValidatedIcon
            condition={
              confirmPassword === password &&
              password.length > 0 &&
              confirmPassword.length > 0
            }
          />
          Must match the password confirmation
        </ListItem>
        <ListItem>- Cannot contain your username.</ListItem>
        <ListItem>- Cannot be any previously used passwords</ListItem>
      </List>
    </Box>
  )
}

export default PasswordCriteria
