import Cookies from 'js-cookie'
import { call, put, takeLatest } from 'redux-saga/effects'

import backendAPI from '../axiosConfig'
import {
  login,
  loginWithToken,
  logout,
  authFailure,
  waitForAuth,
  loginSuccess,
  waitForOTP,
  invalidOTP,
  expiredOTP,
  passwordExpired,
  forgotPassword,
  validateResetToken,
  resetPassword,
  passwordValidationErrors,
  changePassword,
  setErrors,
  clearLoading,
  clearErrors,
} from '../features/authSlice'

export function* clearHeaders() {
  yield delete backendAPI.defaults.headers.common['Authorization']
}

function* getProfile() {
  try {
    const response = yield call(() => {
      return backendAPI.get('/api/profile/')
    })
    const user = response.data[0]
    yield put(loginSuccess(user))
  } catch (error) {
    console.error(error)
    yield clearHeaders()
    yield put(authFailure())
  }
}

function* loginSaga(action) {
  try {
    yield put(waitForAuth())
    const { values } = action.payload

    yield delete backendAPI.defaults.headers.common['Authorization']

    const response = yield call(() => {
      return backendAPI.post('/api/login/', values, {
        withCredentials: true,
      })
    })

    if (response.data?.two_factor_auth_status === 'pending') {
      yield put(waitForOTP())
    } else if (response.data?.password_expired === true) {
      yield put(passwordExpired())
    } else {
      const { token } = response.data
      yield (backendAPI.defaults.headers.common[
        'Authorization'
      ] = `Token ${token}`)
      yield Cookies.set('api_token', token, { sameSite: 'Lax' })
      yield getProfile()
    }
  } catch (error) {
    console.error(error)
    yield clearHeaders()
    if (error.response?.data?.two_factor_auth_status === 'invalid') {
      yield put(invalidOTP())
    } else if (error.response?.data?.two_factor_auth_status === 'expired') {
      yield put(expiredOTP())
    } else {
      yield put(authFailure())
    }
  }
}

function* loginWithTokenSaga(action) {
  try {
    yield put(waitForAuth())
    const { token } = action.payload
    yield (backendAPI.defaults.headers.common[
      'Authorization'
    ] = `Token ${token}`)
    yield getProfile()
  } catch (error) {
    console.error(error)
  }
}

function* logoutSaga(action) {
  try {
    const { callback } = action.payload
    yield Cookies.remove('api_token')
    yield call(callback)
    yield call(() => {
      return backendAPI.post('/api/logout/')
    })
    yield clearHeaders()
    yield put(clearErrors())
  } catch (error) {
    console.error(error)
  }
}

function* forgotPasswordSaga(action) {
  try {
    yield call(() => {
      return backendAPI.post('/api/reset_password/', action.payload)
    })
  } catch (error) {
    console.error(error)
  }
}

function* validateResetTokenSaga(action) {
  const { token, callbackFailure } = action.payload
  try {
    yield call(() => {
      return backendAPI.post('/api/reset_password/validate_token/', { token })
    })
  } catch (error) {
    console.error(error)
    yield call(callbackFailure)
  }
}

function* resetPasswordSaga(action) {
  const { values, callbackSuccess, callbackFailure } = action.payload
  try {
    yield call(() => {
      return backendAPI.post('/api/reset_password/confirm/', values)
    })
    if (callbackSuccess) yield call(callbackSuccess)
  } catch (error) {
    console.error(error)
    if (error.response?.data?.password) {
      yield put(passwordValidationErrors(error.response.data.password))
    }
    if (callbackFailure) yield call(callbackFailure, error.response)
  }
}
function* changePasswordSaga(action) {
  const { values, callbackSuccess, callbackFailure } = action.payload
  try {
    const passwords = {
      old_password: values.oldPassword,
      new_password: values.newPassword,
      confirm_password: values.confirmPassword,
    }

    yield call(() => {
      return backendAPI.put(`api/change_password/${values.id}/`, passwords)
    })

    yield call(callbackSuccess)
    yield Cookies.remove('api_token')
    yield Cookies.remove('tfa_token')
    yield call(clearHeaders)
    yield put(clearErrors())
    yield put(clearLoading())
  } catch (error) {
    yield put(setErrors(error.message))
    yield call(callbackFailure, error)
    console.error(error)
  }
}

function* authAPI() {
  yield takeLatest(login, loginSaga)
  yield takeLatest(loginWithToken, loginWithTokenSaga)
  yield takeLatest(logout, logoutSaga)
  yield takeLatest(forgotPassword, forgotPasswordSaga)
  yield takeLatest(validateResetToken, validateResetTokenSaga)
  yield takeLatest(resetPassword, resetPasswordSaga)
  yield takeLatest(changePassword, changePasswordSaga)
}

export default authAPI
