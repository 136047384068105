import axios from 'axios'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import convert from 'xml-js'

import {
  DISTRICT_ERROR,
  STAFF_TAB,
  GROUPS_TAB,
  CONSTITUENTS_TAB,
} from '../../constants'
import { getFilterQueryString } from '../../utils'
import backendAPI from '../axiosConfig'
import {
  clearConstituents,
  storeConstituents,
} from '../features/constituentsSlice'
import {
  setErrors,
  storeValidatedAddress,
  setDirectoryOrderBy,
  setDirectorySearch,
  clearDirectorySearch,
  clearDirectoryFilters,
  applyDirectoryFilters,
  addConstituentsToGroup,
  removeConstituentsFromGroup,
  setAddressStatus,
  validateAddress,
} from '../features/directorySlice'
import { clearGroups, storeGroups } from '../features/groupsSlice'
import { clearStaff, storeStaff } from '../features/staffSlice'

function* handleAddressSaga(address, callbackFailure) {
  try {
    // const getAddressStatus = state => state.directory.address_status
    // let addressStatus = yield select(getAddressStatus)

    // /** No validation or generation done for new address */
    // if (!addressStatus) {
    //   yield put(setAddressStatus(VALIDATING_ADDRESS))
    // }

    // /** Validate address */
    // addressStatus = yield select(getAddressStatus)
    // if (addressStatus === VALIDATING_ADDRESS) {
    //   yield validateAddressSaga(address)
    // }

    // /** If the address was validated, move to wait for user confirmation */
    // const validatedAddress = yield select(
    //   state => state.directory.validated_address
    // )

    // /**If address is not confirmed, waiting for user confirmation */
    // addressStatus = yield select(getAddressStatus)
    // if (validatedAddress && addressStatus !== ADDRESS_CONFIRMED) {
    //   const keys = ['line1', 'line2', 'city', 'state', 'zipcode']
    //   let addressDiff = false
    //   keys.forEach(key => {
    //     if (address[key] !== validatedAddress[key]) {
    //       addressDiff = true
    //     }
    //   })

    //   if (addressDiff) {
    //     yield put(setAddressStatus(WAITING_ADDRESS_CONF))
    //     yield cancel()
    //   }
    // }

    /** If USPS validation failed, move to generate districts with original address provided. */
    // yield put(setAddressStatus(GENERATING_DISTRICTS))
    const completeAddress = yield generateDistrictsSaga(address)

    // addressStatus = yield select(getAddressStatus)
    // if (addressStatus === DISTRICT_ERROR) {
    //   yield call(callbackFailure, GENERATE_FAIL_ERR_MSG)
    //   yield cancel()
    // }

    // yield put(setAddressStatus(ADDRESS_VERIFIED))
    return completeAddress
  } catch (error) {
    console.error('Error on validating address', error)
    if (callbackFailure) call(callbackFailure)
  }
}

function* validateAddressSaga(address) {
  try {
    const requestPayload = {
      AddressValidateRequest: {
        _attributes: {
          USERID: process.env.REACT_APP_USPS_USERID,
        },
        Revision: {
          _text: '1',
        },
        Address: {
          _attributes: {
            ID: '0',
          },
          Address1: {
            _text: address.line2,
          },
          Address2: {
            _text: address.line1,
          },
          City: {
            _text: address.city,
          },
          State: {
            _text: address.state,
          },
          Zip5: {
            _text: address.zipcode,
          },
          Zip4: {},
        },
      },
    }
    const xmlRequestPayload = convert.js2xml(requestPayload, { compact: true })

    const config = {
      headers: {
        'Content-Type': 'application/xml',
      },
    }

    const response = yield call(() => {
      return axios.post(
        `https://secure.shippingapis.com/ShippingAPI.dll`,
        `API=Verify&XML=${encodeURIComponent(xmlRequestPayload)}`,
        config
      )
    })
    const { Address1, Address2, City, State, Zip5, Error } = convert.xml2js(
      response.data,
      { compact: true }
    ).AddressValidateResponse.Address
    if (Error) {
      yield put(setErrors('USPS failed to validate address', address))
      return
    }

    const validatedAddress = {
      line1: Address2._text,
      line2: Address1?._text || '',
      city: City._text,
      state: State._text,
      zipcode: Zip5._text,
    }

    yield put(storeValidatedAddress(validatedAddress))
  } catch (error) {
    console.error(error)
    yield put(setErrors('Validate address with USPS API failed', error))
  }
}

function generateAddressString(address) {
  let addressParts = []

  const keys = ['line1', 'city', 'state', 'zipcode']
  keys.forEach(key => {
    if (address[key] !== undefined) {
      if (key === 'line1') {
        addressParts.push(address[key].split(',')[0])
      } else {
        addressParts.push(address[key])
      }
    }
  })

  return addressParts.join(' ')
}

function* generateDistrictsSaga(address) {
  yield put(setErrors(null)) // Clear any existing errors
  const addressString = generateAddressString(address)

  const baseUrl = 'https://maps.nyc.gov/geoclient/v2/search.json'
  const appId = process.env.REACT_APP_GEOCLIENT_APP_ID
  const appKey = process.env.REACT_APP_GEOCLIENT_APP_KEY

  try {
    const response = yield call(() => {
      return axios.get(
        baseUrl +
          `?app_key=${appKey}` +
          `&app_id=${appId}` +
          `&input=${encodeURIComponent(addressString)}`
      )
    })
    const { results, status } = response.data
    if (status === 'REJECTED') {
      yield put(setAddressStatus(DISTRICT_ERROR))
      yield put(setErrors('Generate districts failed'))
      console.error('Geoclient failed to generate districts for', addressString)
      return address
    }

    const districts = results[0].response
    let completeAddress = { ...address }
    completeAddress['boro_block_lot'] = districts.bbl
    completeAddress['state_senate_district'] = districts.stateSenatorialDistrict
    completeAddress['congressional_district'] = districts.congressionalDistrict
    completeAddress['state_assembly_district'] = districts.assemblyDistrict
    completeAddress['community_board_district'] = districts.communityDistrict
    completeAddress['election_district'] = districts.electionDistrict
    completeAddress['school_district'] = districts.communitySchoolDistrict
    completeAddress['police_precinct'] = districts.policePrecinct
    completeAddress['longitude'] = districts.longitude
    completeAddress['latitude'] = districts.latitude
    completeAddress['council_district'] = districts.cityCouncilDistrict
    completeAddress['borough'] = districts.firstBoroughName

    completeAddress['normalized_zipcode'] = districts.zipCode
    completeAddress['normalized_city'] = districts.uspsPreferredCityName
    completeAddress['normalized_line1'] =
      districts.houseNumber + ' ' + districts.firstStreetNameNormalized
    completeAddress['verified'] = true
    return completeAddress
  } catch (error) {
    console.error(error)
    yield put(setErrors('Generate districts failed', error))
    return address
  }
}

function* filterSaga(action) {
  try {
    const filtersChanged = yield select(
      state => state.directory.filters_changed
    )
    const tabIndex = yield select(state => state.directory.tab_index)
    if (!filtersChanged) return

    const filters = yield select(state => state.directory.filters)

    const response = yield call(() => {
      return backendAPI.get(
        tabIndex === STAFF_TAB
          ? `/api/staff/?${getFilterQueryString(filters)}`
          : `/api/constituents/${
              tabIndex === GROUPS_TAB ? `groups/` : ''
            }?${getFilterQueryString(filters)}`
      )
    })

    const { count, next, results } = response.data
    if (tabIndex === CONSTITUENTS_TAB) {
      yield put(clearConstituents())
      yield put(storeConstituents({ count, next, results }))
    } else if (tabIndex === GROUPS_TAB) {
      yield put(clearGroups())
      yield put(storeGroups({ count, next, results }))
    } else if (tabIndex === STAFF_TAB) {
      yield put(clearStaff())
      yield put(storeStaff({ count, next, results }))
    }
    if (action.payload) {
      const { callback } = action.payload
      if (callback) yield call(callback)
    }
  } catch (error) {
    console.error(error)
    yield put(setErrors('Filter failed'))
  }
}

function* addConstituentsToGroupSaga(action) {
  try {
    const { id, constituents } = action.payload
    for (let c of constituents) {
      yield call(() => {
        return backendAPI.post(`api/constituents/groups/${id}/add/`, c)
      })
    }
  } catch (error) {
    console.error(error)
    yield put(setErrors('Adding constituent to group failed'))
  }
}

function* removeConstituentsFromGroupSaga(action) {
  try {
    const { id, constituents } = action.payload
    for (let c of constituents) {
      yield call(() => {
        return backendAPI.post(`api/constituents/groups/${id}/remove/`, c)
      })
    }
  } catch (error) {
    console.error(error)
    yield put(setErrors('Removing constituent from group failed'))
  }
}

function* directoryAPI() {
  yield takeLatest(setDirectoryOrderBy, filterSaga)
  yield takeLatest(setDirectorySearch, filterSaga)
  yield takeLatest(clearDirectorySearch, filterSaga)
  yield takeLatest(applyDirectoryFilters, filterSaga)
  yield takeLatest(clearDirectoryFilters, filterSaga)
  yield takeLatest(validateAddress, validateAddressSaga)
  yield takeLatest(addConstituentsToGroup, addConstituentsToGroupSaga)
  yield takeLatest(removeConstituentsFromGroup, removeConstituentsFromGroupSaga)
}

export default directoryAPI

export { handleAddressSaga, generateDistrictsSaga }
