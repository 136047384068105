const inputBorderColor = 'gray.400'

const activeInputBorderColorLight = 'gray.500'
const activeInputBorderColorDark = 'gray.200'

export const inputTheme = {
  variants: {
    outline: ({ value }) => ({
      field: {
        borderColor: value ? activeInputBorderColorLight : inputBorderColor,
        _hover: { borderColor: activeInputBorderColorLight },
        _dark: {
          borderColor: value ? activeInputBorderColorDark : inputBorderColor,
          _hover: { borderColor: activeInputBorderColorDark },
        },
      },
    }),
  },
  defaultProps: {
    size: 'lg',
    variant: 'outline',
  },
}

export const textareaTheme = {
  variants: {
    outline: ({ value }) => ({
      borderColor: value ? activeInputBorderColorLight : inputBorderColor,
      _hover: { borderColor: activeInputBorderColorLight },
      _dark: {
        borderColor: value ? activeInputBorderColorDark : inputBorderColor,
        _hover: { borderColor: activeInputBorderColorDark },
      },
    }),
  },
  defaultProps: {
    size: 'lg',
    variant: 'outline',
  },
}

export const selectTheme = inputTheme
