import React from 'react'

import { Icon } from '@chakra-ui/react'
import {
  ChevronUpDownIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/outline'
import { useSelector } from 'react-redux'

const OrderByIndicator = ({ value, onClick }) => {
  const orderBy = useSelector(state => state.directory.filters.order_by) || ''

  if (!value) return null

  let icon
  if (orderBy.includes(value)) {
    if (orderBy[0] === '-') icon = ChevronDownIcon
    else icon = ChevronUpIcon
  }

  return (
    <Icon
      as={icon || ChevronUpDownIcon}
      onClick={onClick}
      cursor='pointer'
      fontSize='xl'
      bg={icon && 'blue.200'}
      _dark={{
        bg: icon && 'gray.500',
      }}
      h='25px'
      w='25px'
      p={1}
      borderRadius='full'
    />
  )
}

export default OrderByIndicator
